import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoggedInNav from './LoggedIn.js';
import LoggedOutNav from './LoggedOut.js';
import { setWindowWidth } from './actions';
import { setLoggedInUser } from '../../containers/AuthForm/actions';

let windowWidth;



class NavBar extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      authState: typeof sessionStorage !== 'undefined'
        ?
        sessionStorage.getItem('ha-user') ? { user: JSON.parse(sessionStorage.getItem('ha-user')) } : null
        : null,
    }
  }

  componentWillMount() {
    this.hideOrShowNavBar(this.props.navBarDisplay);

    if (this.props.staticContext && this.props.staticContext.user) {
      console.log('Static user', this.props.staticContext.user);
      this.props.setAuthUser(this.props.staticContext.user);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.setWindowWidth.bind(this));
    this.setWindowWidth();
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    this.hideOrShowNavBar(nextProps.navBarDisplay);
    if (nextProps.authState && nextProps.authState['completed'] && nextProps.authState['user'].uid) {
      this.setState({
        authState: nextProps.authState
      });
    }
    this.setState({
      authState: nextProps.authState
    });
  }

  hideOrShowNavBar(navBarDisplay) {
    if (!navBarDisplay) {
      this.setState({ toggleClassName: 'hide' });
    }
    else {
      this.setState({ toggleClassName: '' });
    }
  }


  setWindowWidth() {
    if (window.innerWidth < window.outerWidth) {
      windowWidth = window.innerWidth;
    }
    else {
      windowWidth = window.outerWidth;
    }
    this.props.setWindowWidth(windowWidth);
  }


  render() {
    const { authState } = this.props;
    const { user } = authState;
    const authUser = (this.props.staticContext && this.props.staticContext.user) || user;
    const navBarStyle = {
      boxShadow: '0 4px 4px -4px #ababab',
      position: 'fixed',
      width: '100%',
      background: 'white',
      zIndex: 10
    }

    const renderNav = () => {
      if (authUser && authUser.uid) {
        return (
          <LoggedInNav />
        );
      }
      else {
        return (
          <LoggedOutNav />
        );
      }
    }

    return (
      <div className={`${this.state.toggleClassName} bg-circles height-60 flex-row-align-center`} style={navBarStyle}>
        {renderNav()}
      </div>
    );
  }
}

const mapStateToProps = ({ authState, navBarDisplay, windowWidth }) => ({
  authState,
  navBarDisplay,
  windowWidth
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthUser: (user) => dispatch(setLoggedInUser(user)),
    setWindowWidth: (width) => dispatch(setWindowWidth(width)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));