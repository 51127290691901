import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import UploadImage from '@material-ui/icons/PhotoCamera';
import UploadVideo from '@material-ui/icons/Videocam';
import QueryString from 'query-string';
import ImageList from '../ImageList';
import './styles.scss';
let videoUploaderRef;


class AssetUploader extends Component {
  constructor(props, context) {
    super(props, context);
    this._handleDeleteImage = this._handleDeleteImage.bind(this);
    this._handleInputKeyPress = this._handleInputKeyPress.bind(this);
    this._handleVideoFileDialogCancel = this._handleVideoFileDialogCancel.bind(this);
    this._setImageTitle = this._setImageTitle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.openVideo && nextProps.openVideo) {
      videoUploaderRef.open();
    }
  }

  _clearFieldError(type) {
    let field = `${type}Error`;

    if (this.state[field]) {
      let fieldErrorText = `${type}FieldErrorText`;
      this.setState({
        [field]: false,
        [fieldErrorText]: ''
      });
    }
  }

  _handleDeleteImage(uid, index, e) {
    e.preventDefault();
    this.props.deleteImage(uid, index);
  }

  _handleInputKeyPress(_, e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  _handleDrop(type, files) {
    //this._clearFieldError(type);
    this.props.onDrop(type, files);
  }

  _handleVideoFileDialogCancel() {
    this.props.onVideoFileDialogCancel();
  }

  _setImageTitle(uid, index, e) {
    e.preventDefault();
    this.props.setImageTitle(uid, index, e);
  }

  render() {
    const acceptStyle = {
      border: '1px solid #05CD75'
    }
    const activeDragStyle = {
      backgroundColor: 'rgba(208, 211, 220, 0.96)'
    }
    const errorStyle = {
      color: 'red'
    }
    const rejectStyle = {
      border: '1px solid red',
      color: 'red'
    }
    const { images, location, video } = this.props;
    const mode = QueryString.parse(location.search)['selectedView'];
    let self = this;
    const moreThanOneImage = images && Object.keys(images).length > 0;

    return (
      <div className={'bg-white flex-column'}>
        <p className='form-subheader ta-center margin-t16 fw-600'>{mode === 'add' ? 'Add' : 'Edit'} Images and Video</p>
        <aside className={'apartment-images'}>
          <ImageList
            images={images}
            onDelete={this._handleDeleteImage}
            onInputKeyPress={this._handleInputKeyPress}
            setImageTitle={this._setImageTitle}
          />
        </aside>
        <div className={`content-container width-p100 margin-t16 margin-b16`}>
          <Dropzone accept="image/jpeg, image/png" tabIndex={0}
            className={'upload-image-btn-root'}
            activeClassName={'upload-btn-dragover'}
            onDrop={this._handleDrop.bind(this, 'images')}
            acceptStyle={acceptStyle}
            style={this.props.imageError ? rejectStyle : {}}
            rejectStyle={rejectStyle}>
            {
              ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                if (isDragActive & !isDragReject) {
                  return (
                    <p className='paragraph-xs fw-400'>
                      {`Drop files here`}
                    </p>
                  );
                }
                if (isDragReject) {
                  return (
                    <p className='paragraph-xs fw-400 error'>
                      {`Some file(s) are invalid`}
                    </p>
                  );
                }
                return (
                  <div className='flex-column-center'>
                    <UploadImage className={'upload-media-btn'}
                      aria-label='Upload image of apartment' />
                    <label className='paragraph-xs fw-400'
                      style={this.props.imageError ? errorStyle : {}}>
                      {
                        this.props.imageError ?
                          this.props.imageErrorText ?
                            this.props.imageErrorText :
                            `Error adding image(s)` :
                          moreThanOneImage ?
                            'Click to add more images' :
                            'Add at least 5 images'
                      }
                    </label>
                  </div>
                )
              }
            }
          </Dropzone>
          {
            video && Object.keys(video).length > 0 ?
              <div className={'upload-video-btn-root relative'}>
                < button className={'delete-img-btn'}
                  onClick={self.props.deleteVideo}>
                  x
                </button>
                <video className={'upload-video-btn-root'} style={{ width: '100%' }} controls>
                  <source src={video[Object.keys(video)[0]].data.preview} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
              </div>
              :
              <Dropzone ref={(node) => { videoUploaderRef = node; }} tabIndex={0}
                accept="video/mp3, video/mp4, video/avi, video/mov, video/quicktime"
                className={'upload-video-btn-root'}
                activeStyle={activeDragStyle}
                acceptStyle={acceptStyle}
                rejectStyle={rejectStyle}
                style={this.props.videoError ? rejectStyle : {}}
                onFileDialogCancel={this._handleVideoFileDialogCancel}
                onDrop={this._handleDrop.bind(this, 'video')}>
                {
                  ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                    if (isDragActive & !isDragReject) {
                      return (
                        <p className='paragraph-xs fw-400'>
                          {`Drop video here`}
                        </p>
                      );
                    }
                    if (isDragReject) {
                      return (
                        <p className='paragraph-xs fw-400 error'>
                          {`Some file(s) are invalid`}
                        </p>
                      );
                    }
                    return (
                      <div className='flex-column-center'>
                        <UploadVideo className={'upload-media-btn'}
                          aria-label='Upload video of apartment' />
                        <label className='paragraph-xs fw-400'
                          style={this.props.videoError ? errorStyle : {}}>
                          {
                            this.props.videoError ?
                              this.props.videoErrorText ?
                                this.props.videoErrorText :
                                `Error adding the attached file` :
                              'Add video to earn more'
                          }
                        </label>
                      </div>
                    )
                  }
                }
              </Dropzone>
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = store => {
  return {
    searchPrefState: store.searchPrefState,
    windowWidth: store.windowWidth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(AssetUploader)
);
