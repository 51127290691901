import React from 'react';
import Calendar from '@material-ui/icons/Today';
import Listing from '@material-ui/icons/Business';
import Location from '@material-ui/icons/LocationOn';
import Money from '@material-ui/icons/Money';
import Payout from '@material-ui/icons/AttachMoney';
import People from '@material-ui/icons/People';
import Trend from '@material-ui/icons/TrendingUp';
import Viewed from '@material-ui/icons/RemoveRedEye';
import MaxPay from '@material-ui/icons/DynamicFeed';
import './styles.scss';


const InfoNugget = ({ item, hover, theme }) => {
	const icon = () => {
		const iconStyle = { color: 'white' };

		if (item.type === 'listing') {
			return <Listing style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'search') {
			return <Trend style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'location') {
			return <Location style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'calendar') {
			return <Calendar style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'payout') {
			return <Payout style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'people') {
			return <People style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'maxpay') {
			return <MaxPay style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'total') {
			return <Money style={iconStyle} className={'info-nugget-icon'} />
		}
		if (item.type === 'viewed') {
			return <Viewed style={iconStyle} className={'info-nugget-icon'} />
		}
		return <Listing style={{ fontSize: 64, color: 'white' }} />
	}

	return (
		<div className={`info-nugget-col ${hover ? 'info-nugget-hover' : ''}`}
			style={hover ? { cursor: 'pointer' } : {}}
			onClick={item.onClick}>
			<div className={`info-nugget-icon-${theme}`}>
				{icon()}
			</div>
			<div className={'info-nugget-text'}>
				<div className='fw-500 ta-center'>{item.label}</div>
				<p className={'info-nugget-value'}>{item.value}</p>
			</div>
		</div>
	);
};


InfoNugget.defaultProps = {
	theme: 'accent',
	label: 'Loading...'
}

export default InfoNugget;
