import React from 'react';
import './Button.scss';
import FacebookIcon from '../../assets/social/facebook_logo.png';

const FacebookBtn = props => {
  const facebookBtnStyle = {
    backgroundColor: '#4267B2',
    color: '#FFF'
  }
  const facebookLogo = <img className='facebook__btn_image' src={FacebookIcon} alt='facebook-login-logo' />
  return (
    <div style={facebookBtnStyle} className={'all__btn_container margin-t16'} onClick={props.onClick}>
      <div style={{ position: 'absolute' }} className='flex'>
        {facebookLogo}
      </div>
      <div className='ta-center width-p100'>
        <p className='all__btn_text'>{props.label}</p>
      </div>
    </div>
  )
}

export default FacebookBtn;