import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';


class HAModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open });
  }

  _handleClose() {
    this.props.onClose();
  };

  render() {
    const { display } = this.props;
    const closeBtnStyle = this.props.closeBtnStyle || {
      position: 'absolute',
      color: 'white',
      cursor: 'pointer',
      visibility: 'visible',
      right: display === 'map' ? 24 : 12,
      top: display === 'map' ? 24 : 8,
      zIndex: 1122
    }
    const centerStyle = {
      display: 'flex',
      justifyContent: 'center',
      outline: 0,
      width: 'auto'
    }
    return (
      <Modal
        aria-labelledby="le-modal"
        aria-describedby="list-earn-modal"
        open={this.props.open}
        style={
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 16px',
            visibility: 'visible',
            overflowY: 'scroll'
          }
        }
        onClose={this._handleClose.bind(this)}>
        <div tabIndex={0} style={centerStyle} className='relative width-p100 margin-auto'>
          <div role='button' style={closeBtnStyle}
            onClick={this._handleClose.bind(this)}><b>X</b></div>
          {this.props.children}
        </div>
      </Modal>
    )
  }
}

export default HAModal;
