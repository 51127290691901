import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Calendar from '@material-ui/icons/Today';
import Edit from '@material-ui/icons/Edit';
import LocateOnMap from '@material-ui/icons/LocationOn';
import Update from '@material-ui/icons/Update';
import FavoriteFill from '@material-ui/icons/Favorite';
import Viewed from '@material-ui/icons/RemoveRedEye';
import Verified from '@material-ui/icons/VerifiedUser';
import Unverified from '@material-ui/icons/LiveHelp';
import PosterImage from '../../assets/imgs/selfcon.jpg';
import HADropdown from '../Dropdown/main';

import { setCurrentListing, editListing } from '../../pages/listings/actions';
import { AGENT_LISTING_STATUS, getStatusStyle, APARTMENT_TYPES_STR } from '../../Constants';
import './styles.scss';


class ListItem extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      editStatus: false,
      status: props.listing.status
    }
    this._handleClick = this._handleClick.bind(this);
  }

  _editListing(e) {
    const { onEdit, setListing, listing } = this.props;
    e.stopPropagation();
    setListing(listing);
    onEdit(listing);
  }

  _handleClick(e) {
    const { _id, city, typeEncoded, addressEncoded, unapproved, zone } = this.props;
    if (unapproved !== false) {
      window.open(`/apartment/ak/${city || 'uyo'}/${zone.toLowerCase()}/${typeEncoded}/${addressEncoded}/${_id}`, `_blank`)
    }
  }

  _handleDeleteListing(e) {
    const { onDelete, listing } = this.props;
    e.stopPropagation();
    onDelete(listing);
  }

  _handleMenuOpen(type) {

  }

  _handleCalendarClick(e) {
    const { onCalendarClick, listing } = this.props;
    e.stopPropagation();
    onCalendarClick(listing);
  }

  _handleSelectType(type) {
    const { updateListing, listing } = this.props;
    updateListing({ status: type.value }, listing._id);
    this.setState({
      status: type.value,
      editStatus: false
    })
  }

  _updateStatus(e) {
    e.stopPropagation();
    const { editStatus } = this.state;
    this.setState({ editStatus: !editStatus });
  }

  render() {
    const { appointmentCreatedFor, /*address, axis, city,*/ fromAdmin, _id, listing } = this.props;
    const { dateOfShowing, description, earning, fee, likes, location, posterImage, rent, shortcode, status, views } = listing;
    const canBeUpdated = status !== 'FLAGGED' && status !== 'UNVERIFIED' && status !== 'RENTED';
    const isVerified = status === 'VERIFIED';
    const notDormant = status !== 'DELETED' && status !== 'UNVERIFIED';

    const apartmentImage = () => (
      <div className='relative'>
        <div className='poster-img-root'>
          <img src={posterImage || PosterImage} alt={description} className='poster-img' />
        </div>
        {/* <div className='flex-row-center'>
          <button className='view-listing-btn br-4 padding-8'
            onClick={this._handleClick}>
            View as Renter
          </button>
        </div> */}
      </div>
    );

    const apartmentTitle = () => (
      <div className='flex-row-spacebetween'>
        <div className='relative'>
          <div className='flex-row relative padding-lr8'>
            <p className='color-text fw-700'>{`${APARTMENT_TYPES_STR[shortcode]} Apartment`}</p>
            <div title={canBeUpdated ? 'Verified' : 'Not verified'}>
              {
                canBeUpdated
                  ? <div className='flex-row-align-center display-mobile-none'>
                    <Verified className={'verified-icon'} />
                    {/* <div className='label verified fw-300'>
                      Verified
                      </div> */}
                  </div>
                  : <div className='flex-row-align-center display-mobile-none'>
                    <Unverified className={'unverified-icon'} />
                    {/* <div className='label unverified fw-300'>
                      Unverified
                      </div> */}
                  </div>
              }
            </div>
          </div>

          <div className='padding-lr8 flex-row padding-b8' style={{ marginTop: -8 }}>
            <div style={{ marginLeft: -2, marginRight: 2, marginTop: 4 }}>
              <LocateOnMap style={{ fontSize: 12, color: '#828282' }} />
            </div>
            <p style={{ fontSize: 14, marginTop: 2, color: '#828282' }}>
              {location || 'No address provided'}
            </p>
          </div>
        </div>
      </div>
    )

    const statusButtons = () => (
      <div className='flex-column width-p100 status-btns-root margin-t32'>
        <div className='flex-row-spacebetween width-p100'>
          <button className={`btn-status ${isVerified ? 'disabled' : ''}`}
            onClick={this._editListing.bind(this)}
            aria-label={isVerified ? 'Verified listings cannot be edited' : 'Edit Listing'}
            title={isVerified ? 'Verified listings cannot be edited' : 'Edit Listing'}>
            <Edit />
          </button>
          <button className='btn-status'
            onClick={this._handleCalendarClick.bind(this)}
            aria-label='Manage showing appointment'
            title='Manage appointment'>
            <Calendar />
          </button>
          <button className={`btn-status ${!canBeUpdated ? 'disabled' : ''}`}
            value='delete'
            onClick={this._updateStatus.bind(this)}
            title='Update Status' aria-label='Update Status'>
            <Update />
          </button>
        </div>
      </div>
    );

    const apartmentDetails = () => (
      <div className='width-p100 color-text'>
        <div className='flex-row-spacebetween'>
          {apartmentTitle()}
          <div className='btn-delete'
            role='button'
            onClick={this._handleDeleteListing.bind(this)}
            aria-label='Delete Listing'>
            X</div>
        </div>
        <div className='apt-body'>
          <div className='apt-details'>
            <div className='color-text fw-400 padding-lr8 paragraph-xs ta-left'>
              <span className='fw-400'>Rent:</span> {`N${rent || '120,000'}.00`}
            </div>

            <div className='padding-lr8 flex-row margin-t16 fw-400 paragraph-xs'>
              <span className='fw-400'>Fee: &nbsp;</span>{fee || 10}%
            </div>


            <div className='padding-lr8 flex-row margin-t16 fw-400 paragraph-xs'>
              <span className='fw-400'>Earning: &nbsp;</span> {earning ? `N${earning}` : 'N/A'}
            </div>

          </div>
          <div className='apt-status'>
            {
              this.state.editStatus ?
                <div className='width-p100'>
                  <HADropdown
                    placeholder={'Set status'}
                    options={
                      fromAdmin
                        ? AGENT_LISTING_STATUS.concat({ value: 'FLAGGED', label: 'FLAGGED' })
                        : AGENT_LISTING_STATUS
                    }
                    onMenuOpen={this._handleMenuOpen.bind(this, 'type')}
                    onChange={this._handleSelectType.bind(this)} />
                </div>
                :
                <div style={getStatusStyle(this.state.status)}>
                  {this.state.status}
                </div>
            }
          </div>
        </div>
        {statusButtons()}
      </div>
    );

    const metaData = () => (
      <div className='flex-row-spacebetween color-text padding-tb16'
        style={{ borderTop: '1px solid #E6E6E6' }}>
        <div title='Date of Showing appointment' className='flex-center' style={{ fontSize: 13 }}>
          {moment(dateOfShowing, 'MMM Do YYYY, h:mm A').format("DD/MM/YYYY") || `10/12/2018`}
        </div>
        <div className='flex-center' title='Number of views'>
          <Viewed style={{ color: 'gray', marginRight: 4, width: 14 }} />
          <span style={{ fontSize: 12 }}>{views}</span>
        </div>
        <div className='flex-center' title='Number of likes'>
          <FavoriteFill style={{ fontSize: 12, fill: 'red' }} />&nbsp;<span style={{ fontSize: 12 }}>{likes}</span>
        </div>
      </div>
    );

    return (
      <div className={`listing-root width-p100 margin-t16 ${_id === appointmentCreatedFor && 'fade'}`}
        onClick={this._handleClick.bind(this)}>
        <article className='bg-white br-4 padding-lr16'>
          <div className='listing-list-root' style={{ borderBottom: '1px solid rgb(230, 230, 230)' }}>
            {apartmentImage()}
            {apartmentDetails()}
          </div>
          <div>
            {metaData()}
          </div>
        </article>
      </div>
    );
  }
}


const mapStateToProps = store => {
  return {
    navBarDisplay: store.navBarDisplay,
    windowWidth: store.windowWidth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setListing: (listing) => dispatch(setCurrentListing(listing)),
    updateListing: (options, listingId) => dispatch(editListing(options, listingId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)((ListItem));