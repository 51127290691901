import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';
import 'firebase/auth';
import Helmet from 'react-helmet';
import QueryString from 'query-string';
import AuthForm from '../../containers/AuthForm/';
import { toggleNavBar } from '../../containers/NavBar/actions';
import { apiClient } from '../../services/ApiClient';


const USER_PROFILE_SERVICE_ENDPOINT = process.env.REACT_APP_USER_PROFILE_SERVICE_ENDPOINT;
const ACTION_MODE_TITLES = {
	'resetPassword': {
		header: 'Please enter your new password',
		title: 'Update Your Password',
	},
	'login': {
		header: 'Your email has been verified. Please login to continue',
		title: 'Login to continue',
	},
	'default': {
		header: '',
		title: 'Authentication Form',
	}
}

class ActionPage extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			emailVerified: null
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const { location } = this.props;

		this.actionCode = QueryString.parse(location.search)['oobCode'];
		this.mode = QueryString.parse(location.search)['mode'] || 'default';
		console.log('This mode', this.mode, 'actionCode', this.actionCode)

		if (this.mode === 'verifyEmail') {
			firebase.auth().applyActionCode(this.actionCode).then(() => {
				const user = firebase.auth().currentUser;
				console.log('Firebase user', user.emailVerified);
				this.setState({ emailVerified: true });

				apiClient(USER_PROFILE_SERVICE_ENDPOINT)
					.post('/auth/email-verify', { uid: user.uid })
					.then((res) => {
						console.log('Email verified endpoint', res.data);
					})
					.catch((error) => {
						console.log('Error updating email verification in db', error);
					});
			}).catch((error) => {
				const user = firebase.auth().currentUser;
				console.log('Firebase afsdasddsuser', user.emailVerified);
				if (user.emailVerified) {
					this.setState({
						emailVerified: true,
						emailVerifiedMessage: 'This email address has already been verified'
					});
				}
				else {
					this.setState({ emailVerified: false });
				}
				console.log('Error with email verification link', error);
			});
		}
	}

	componentWillMount() {
		this.props.toggleNavBar(false);
	}

	render() {
		const { location } = this.props;
		const { emailVerified, emailVerifiedMessage } = this.state;
		const headerFromLocation = location.state && location.state.headerText;
		this.mode = QueryString.parse(location.search)['mode'] || 'default';
		this.actionCode = QueryString.parse(location.search)['oobCode'];

		if (this.mode === 'verifyEmail') {
			this.mode = 'login'
		}
		const header = ACTION_MODE_TITLES[this.mode].header || headerFromLocation;
		const title = ACTION_MODE_TITLES[this.mode].title;

		return (
			<div>
				<Helmet title={`${title} | Home Attlas`} />
				<AuthForm
					headerText={header}
					actionCode={this.actionCode}
					type={this.mode}
					emailVerified={emailVerified}
					emailVerifiedMessage={emailVerifiedMessage}
					embed={false} />
			</div>
		);
	}
}


const mapStateToProps = ({ authState, navBarDisplay }) => {
	return {
		authState,
		navBarDisplay
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toggleNavBar: (status) => dispatch(toggleNavBar(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPage);
