

export const GET_AUTH_USER__REQUEST = 'GET_AUTH_USER__REQUEST';
export const GET_AUTH_USER__SUCCESS = 'GET_AUTH_USER__SUCCESS';
export const GET_AUTH_USER__FAILURE = 'GET_AUTH_USER__FAILURE';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_LOGGEDIN__USER = 'SET_LOGGEDIN__USER';

export const LOGOUT_USER__REQUEST = 'LOGOUT_USER__REQUEST';
export const LOGOUT_USER__SUCCESS = 'LOGOUT_USER__SUCCESS';
export const LOGOUT_USER__FAILURE = 'LOGOUT_USER__FAILURE';

export const getAuthenticatedUser = (payload) => {
  return {
    type: GET_AUTH_USER__REQUEST,
    ...(payload && { payload })
  }
};

export const setAuthenticatedUser = (payload) => {
  return {
    type: SET_AUTH_USER,
    payload
  }
};

export const setLoggedInUser = (payload) => {
  return {
    type: SET_LOGGEDIN__USER,
    payload
  }
};

export const logoutUser = (payload) => {
  return {
    type: LOGOUT_USER__REQUEST,
    payload,
  }
};
