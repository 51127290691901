import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles.scss'


const ImageList = ({ images, onDelete, onInputKeyPress, setImageTitle }) => {
	if (images && Object.keys(images).length > 0) {
		return (
			<div className='width-p100'>
				<div className='asset-images-list' style={{ display: '-webkit-box', overflow: 'scroll hidden' }}>
					{
						Object.keys(images).map((uuid, index) =>
							<div key={images[uuid].id} className={'apartment-image-item'}>
								<button className={'delete-img-btn'}
									onClick={onDelete.bind(this, images[uuid].id, index)}>
									x
								</button>
								<img className='apartment-image'
									src={images[uuid].url} aria-label={images[uuid].name} alt={images[uuid].name}>
								</img>
								<input className='apartment-image-title' type='text'
									value={images[uuid].updated || ''}
									onKeyPress={onInputKeyPress.bind(this, images[uuid].url)}
									placeholder={`Describe image here`} onChange={setImageTitle.bind(this, images[uuid].id, index)} />
							</div>
						)
					}
				</div>
			</div >
		)
	}
	else {
		return (
			<div className='width-p100' />
		)
	}
};

const mapStateToProps = ({ windowWidth }) => {
	return { windowWidth }
}

export default connect(mapStateToProps, {})(
	withRouter(ImageList)
);

