import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_RENTER_TO_APPOINTMENT__FAILURE,
  ADD_RENTER_TO_APPOINTMENT__LOADING,
  ADD_RENTER_TO_APPOINTMENT__REQUEST,
  ADD_RENTER_TO_APPOINTMENT__SUCCESS,
  CREATE_APPOINTMENT__FAILURE,
  CREATE_APPOINTMENT__LOADING,
  CREATE_APPOINTMENT__REQUEST,
  CREATE_APPOINTMENT__SUCCESS,
  DELETE_APPOINTMENT__LOADING,
  DELETE_APPOINTMENT__FAILURE,
  DELETE_APPOINTMENT__REQUEST,
  DELETE_APPOINTMENT__SUCCESS,
  EDIT_APPOINTMENT__FAILURE,
  EDIT_APPOINTMENT__LOADING,
  EDIT_APPOINTMENT__REQUEST,
  EDIT_APPOINTMENT__SUCCESS,
  GET_APPOINTMENTS__FAILURE,
  GET_APPOINTMENTS__LOADING,
  GET_APPOINTMENTS__REQUEST,
  GET_APPOINTMENTS__SUCCESS,
  GET_DASHBOARD_DATA__FAILURE,
  GET_DASHBOARD_DATA__LOADING,
  GET_DASHBOARD_DATA__REQUEST,
  GET_DASHBOARD_DATA__SUCCESS,
} from './actions';
import {
  createAppointment,
  deleteAppointment,
  fetchAppointments,
  fetchDashboardData,
  updateAppointment,
  updateAppointmentAttendees,
} from '../../services/ApiClient';



/** Appointment Related Sagas */
export function* addRenterToAppointment(action) {
  yield put({
    type: ADD_RENTER_TO_APPOINTMENT__LOADING
  });

  const { payload } = action;
  try {
    const req = {
      attendee: {
        uid: payload.uid,
        name: payload.name
      },
      appointmentId: payload.appointmentId
    }
    const res = yield call(updateAppointmentAttendees, req);
    console.log(res);
    yield put({
      type: ADD_RENTER_TO_APPOINTMENT__SUCCESS,
      payload: {
        data: res.data,
      }
    });
  } catch (e) {
    console.error('Error adding to apppointment', e)
    yield put({
      type: ADD_RENTER_TO_APPOINTMENT__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* getAppointments(action) {
  yield put({
    type: GET_APPOINTMENTS__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const appointments = yield call(fetchAppointments, action.payload);
    yield put({
      type: GET_APPOINTMENTS__SUCCESS,
      payload: {
        data: appointments.data
      },
    });
  } catch (error) {
    yield put({
      type: GET_APPOINTMENTS__FAILURE,
      payload: error,
    });
  }
}

export function* modifyAppointment(action) {
  yield put({
    type: EDIT_APPOINTMENT__LOADING,
    payload: {
      loading: true
    }
  });

  const { appointmentId, payload } = action;

  try {
    const res = yield call(updateAppointment, { ...payload, ...{ appointmentId } });
    yield put({
      type: EDIT_APPOINTMENT__SUCCESS,
      payload: {
        data: res.data,
      }
    });
  } catch (e) {
    console.error('Error editing new apppointment', e)
    yield put({
      type: EDIT_APPOINTMENT__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* removeAppointment(action) {
  yield put({
    type: DELETE_APPOINTMENT__LOADING,
    payload: {
      loading: true
    }
  });

  const { payload } = action;
  try {
    const res = yield call(deleteAppointment, payload._id);
    yield put({
      type: DELETE_APPOINTMENT__SUCCESS,
      payload: {
        data: res.data,
      }
    });
  } catch (e) {
    console.error('Error deleting new apppointment', e)
    yield put({
      type: DELETE_APPOINTMENT__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* scheduleAppointment(action) {
  yield put({
    type: CREATE_APPOINTMENT__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const res = yield call(createAppointment, action.payload);
    yield put({
      type: CREATE_APPOINTMENT__SUCCESS,
      payload: {
        data: res.data,
      }
    });
  } catch (e) {
    console.error('Error creating new apppointment', e)
    yield put({
      type: CREATE_APPOINTMENT__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* addRenterToAppointmentSaga() {
  yield takeEvery(ADD_RENTER_TO_APPOINTMENT__REQUEST, addRenterToAppointment);
}

export function* createAppointmentSaga() {
  yield takeEvery(CREATE_APPOINTMENT__REQUEST, scheduleAppointment);
}

export function* modifyAppointmentSaga() {
  yield takeEvery(EDIT_APPOINTMENT__REQUEST, modifyAppointment);
}

export function* deleteAppointmentSaga() {
  yield takeEvery(DELETE_APPOINTMENT__REQUEST, removeAppointment);
}

export function* getAppointmentsSaga() {
  yield takeEvery(GET_APPOINTMENTS__REQUEST, getAppointments);
}

/** Dashboard related sagas */
export function* getDashboardData(action) {
  yield put({
    type: GET_DASHBOARD_DATA__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const dashboardData = yield call(fetchDashboardData, action.payload);
    yield put({
      type: GET_DASHBOARD_DATA__SUCCESS,
      payload: dashboardData.data
    });
  } catch (error) {
    yield put({
      type: GET_DASHBOARD_DATA__FAILURE,
      payload: error,
    });
  }
}

export function* getDashboardDataSaga() {
  yield takeEvery(GET_DASHBOARD_DATA__REQUEST, getDashboardData);
}