import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Add from '@material-ui/icons/AddCircle';
import LELogo from '../../assets/brand/LELogo.png';
import LELogoMobile from '../../assets/brand/LEIcon.png';
import UserMenu from '../../components/UserMenu/index';
import { logoutUser } from '../../containers/AuthForm/actions';


class LoggedInNav extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      authState: typeof sessionStorage !== 'undefined'
        ?
        sessionStorage.getItem('ha-user') ? { user: JSON.parse(sessionStorage.getItem('ha-user')) } : props.authState
        : props.authState,
      showUserMenu: false
    }
    this._toggleUserMenu = this._toggleUserMenu.bind(this);
  }

  _initAddListing() {
    const { history } = this.props;
    history.push(
      `/home/agent?selectedView=add`
    );
  }

  _handleLogOut() {
    this.props.logoutEmailUser();
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.removeItem('ha-user');
      this.setState({
        authState: null
      });
    }
  }

  _toggleUserMenu() {
    this.setState({ showUserMenu: !this.state.showUserMenu })
  }

  render() {
    const { authState, showUserMenu } = this.state;
    const { windowWidth } = this.props;

    let displayCTAButton = () => {
      if (true /* && authState && authState.user && authState.user.userType === 'agent'*/) {
        if (windowWidth > 480) {
          return (
            <div className={`button btn-cta`}
              style={{ marginTop: 'unset' }}
              role='button' title='Add Listing' tabIndex={0}
              onClick={this._initAddListing.bind(this)}
              onKeyPress={(e) => { if (e.key === 'Enter') { this._initAddListing(); } }}>
              <Add title='add icon' style={{ fontSize: 16, marginRight: 8, fill: 'white' }} />
              Create Listing
            </div>
          );
        }
        else {
          return (
            <Add style={{ fontSize: 32, marginRight: 8, fill: '#8CD018', cursor: 'pointer' }}
              onClick={this._initAddListing.bind(this)} />
          );
        }
      }
      return (
        <div></div>
      )
    }


    return (
      <nav className='nav-header height-60 flex-row space-between align-center width-p100 mw-1280 margin-auto'>
        <Link className='flex-row-align-center' tabIndex={0}
          to={authState && authState.user && authState.user.uid ? `/home/${authState.user.userType}?selectedView=dashboard` : `/`}>
          {
            windowWidth > 660 ?
              <img src={LELogo} height={40} alt='list-and-earn-logo' aria-label='List and Earn Logo' />
              :
              <img src={LELogoMobile} height={44} alt='list-and-earn-logo' aria-label='List and Earn Logo' />
          }
        </Link>
        <div className='flex-row-align-center'>
          {displayCTAButton()}
          <UserMenu notificationCount={99}
            handleClick={this._toggleUserMenu}
            showMenu={showUserMenu} />
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ authState, navBarDisplay, windowWidth }) => {
  return {
    authState,
    navBarDisplay,
    windowWidth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutEmailUser: () => dispatch(logoutUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoggedInNav));
