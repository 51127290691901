import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styleSheet = {
	label: {
		fontSize: 16
	},
	spinner: {
		color: 'rgb(154, 208, 57) !important',
		marginTop: 16
	},
};

const HALoader = ({ classes, label }) => {
	return (
		<div className='width-p100 vh-80 flex-column-center'>
			<p className={classes.label}>{label}</p>
			<CircularProgress size={60}
				className={classes.spinner}
			/>
		</div>
	);
};

HALoader.propTypes = {
	classes: PropTypes.object.isRequired,
};

HALoader.defaultProps = {
	theme: 'accent',
	label: 'Loading...'
}

export default withStyles(styleSheet)(HALoader);
