import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import Helmet from 'react-helmet';
import createSagaMiddleware from 'redux-saga';
import firebase from 'firebase/app';

import configureStore from './store';
import sagas from './sagas';
import Routes from './Routes';
import { getRandomInt } from './Utils'
import Navbar from './containers/NavBar';
import Footer from './components/Footer';
import './App.scss';

const firebaseConfig = {
  apiKey: "AIzaSyAdo8eIXTERzqU-fNEqxx--Ye5N22VAkqI",
  authDomain: "listandearn.firebaseapp.com",
  databaseURL: "https://listandearn.firebaseio.com",
  projectId: "listandearn",
  storageBucket: "listandearn.appspot.com",
  messagingSenderId: "10719481122",
  appId: "1:10719481122:web:958f755c02f014afcea97b"
};
firebase.initializeApp(firebaseConfig);
export const FIRESTORE_DB = firebase.firestore();

try {
  const messaging = firebase.messaging();
  messaging.requestPermission()
    .then(() => {
      return messaging.getToken();
    })
    .then((currentToken) => {
      if (currentToken) {
        console.log('Current token', currentToken);
      } else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.');
        // updateUIForPushPermissionRequired();
        // setTokenSentToServer(false);
      }
    })
    .catch((err) => {
      //console.log('An error occurred while retrieving token. ', err);
    })
}
catch (e) { }

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const store = configureStore(history, sagaMiddleware);
sagaMiddleware.run(sagas);


function App() {
  let route = getRandomInt(2);
  let bucket = localStorage.getItem('ha__waitlist_bkt');
  if (!bucket) {
    bucket = route === 0 ? 'A' : 'B'
    //localStorage.setItem('ha__waitlist_bkt', bucket);
  }
  else {
    route = bucket === 'A' ? 0 : 1;
  }

  route = 0;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router>
          <div className="App">
            <Helmet
              htmlAttributes={{ lang: 'en', amp: undefined }} // amp takes no value
              titleAttributes={{ itemprop: 'name', lang: 'en' }}
              meta={[
                { name: 'description', content: 'Home Attlas | Apartment Rentals & Listing' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
              ]}
            >
              <title>List and Earn&trade; - Why pay to list when you can be paid to list? </title>
            </Helmet>
            {/* <Header version={bucket} /> */}
            <Navbar />
            <Switch>
              <Route exact path="/hello" component={Routes.HomeA} />
              <Route exact path="/holla" component={Routes.HomeB} />
              <Route exact path="/early-access" component={Routes.Waitlisted} />
              <Route exact path="/post-signup/:id" component={Routes.Survey} />
              <Route exact path="/login" component={Routes.Login} />
              <Route exact path="/signup" component={Routes.Signup} />
              <Route exact path="/new" component={Routes.AgentHome} />
              <Route exact path="/home" component={Routes.AgentHome} />
              <Route component={route === 0 ? Routes.HomeA : Routes.HomeB} />
            </Switch>
            <Footer version={bucket} />
          </div>
        </Router>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
