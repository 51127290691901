import axios from 'axios';
import { generateAccessToken } from '../Utils';

const APPOINTMENT_SERVICE_ENDPOINT = process.env.REACT_APP_APPOINTMENT_SERVICE_ENDPOINT;
const LISTING_SERVICE_ENDPOINT = process.env.REACT_APP_LISTING_SERVICE_ENDPOINT;
const USER_PROFILE_SERVICE_ENDPOINT = process.env.REACT_APP_USER_PROFILE_SERVICE_ENDPOINT;

export const apiClient = (endpoint, token, userToken) => {
  let accessToken = token || generateAccessToken();
  return axios.create({
    baseURL: endpoint,
    headers: {
      Authorization: `Bearer : ${accessToken}`,
      ...(userToken && { 'X-HA-USER-TOKEN': userToken })
    }
  });
};


/** Appointment Related API calls */
export function createAppointment(apt) {
  return apiClient(APPOINTMENT_SERVICE_ENDPOINT)
    .post('/appointments/listing/create', apt);
}

export const deleteAppointment = (appointmentId, token, userToken) => {
  return apiClient(APPOINTMENT_SERVICE_ENDPOINT, token, userToken)
    .delete(`/appointments/${appointmentId}`);
}

export function fetchAppointments(options = {}) {
  const { userId } = options;
  if (options.userId) {
    return apiClient(APPOINTMENT_SERVICE_ENDPOINT)
      .get(`/appointments/user/${userId}`);
  }
  return apiClient(APPOINTMENT_SERVICE_ENDPOINT)
    .get('/appointments/');
}

export function updateAppointment(appointmentData) {
  const { appointmentId } = appointmentData;
  delete appointmentData.appointmentId;
  return apiClient(APPOINTMENT_SERVICE_ENDPOINT)
    .put(`/appointments/${appointmentId}`, appointmentData);
}

export function updateAppointmentAttendees(req) {
  return apiClient(APPOINTMENT_SERVICE_ENDPOINT)
    .put(`/appointments/${req.appointmentId}`, req.attendee);
}


/** Dashboard Related API calls */
export function fetchDashboardData(options = {}) {
  return apiClient(USER_PROFILE_SERVICE_ENDPOINT)
    .get('/auth/dashboard');
}


/** Listing Related API calls */
export function createListing(listing) {
  return apiClient(LISTING_SERVICE_ENDPOINT)
    .post('/authenticated/listing', listing);
}

export function deleteListing(listingId) {
  //return updateListing({ status: 'DELETED' }, listingId);
  return apiClient(LISTING_SERVICE_ENDPOINT)
    .delete(`/authenticated/listing/${listingId}`);
}

export function fetchListing(listingId) {
  return apiClient(LISTING_SERVICE_ENDPOINT)
    .get(`/unauthenticated/listing/${listingId}`);
}

export function fetchListings({ userId }) {
  return apiClient(LISTING_SERVICE_ENDPOINT)
    .post('/unauthenticated/listings', { creatorId: userId });
}

export function fetchListingsByCreator(options = {}) {
  return apiClient(LISTING_SERVICE_ENDPOINT)
    .post(`/unauthenticated/listings/${options.userId}`, options);
}

export function fetchUnverifiedListings(options = {}) {
  let fetchOpts = {
    ...options,
    ...{ verified: false }
  }
  return apiClient(LISTING_SERVICE_ENDPOINT)
    .post('/authenticated/listings/verified', fetchOpts);
}

export function updateListing(updateParams) {
  const { listingId, options } = updateParams;
  return apiClient(LISTING_SERVICE_ENDPOINT)
    .put(`/authenticated/listing/${listingId}`, options);
}