import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import QueryString from 'query-string';

import { GoogleBtn, FacebookBtn } from '../../components/Button/index';
import Dial from '../../components/DialPad/index';
import Snackbar from '../../components/Snackbar';
import LELogo from '../../assets/brand/LELogo.png';
import Loader from '../../components/Loader';
import { constructAuthURL, parseAuthErrors, HA_USER_TOKEN } from './utils';
import { setCookie } from '../../Utils';
import { setAuthenticatedUser, getAuthenticatedUser } from './actions';
import { apiClient } from '../../services/ApiClient';

const USER_PROFILE_SERVICE_ENDPOINT = process.env.REACT_APP_USER_PROFILE_SERVICE_ENDPOINT;


const styles = () => ({
  root: {
    color: '#354052',
  },
  underlineLogin: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '1px solid #923B96 !important',
    },
    '&:after': {
      borderBottom: '2px solid #923B96',
    },
  },
  underlineSignup: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '1px solid #F7BF23 !important',
    },
    '&:after': {
      borderBottom: '2px solid #F7BF23',
    },
  },
  disabled: {},
  focused: {},
  error: {},
});


class AuthForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      btnDisabled: false,
      loading: false,
      emailFieldText: QueryString.parse(props.location.search)['agent_email'] || '',
      emailFieldError: false,
      emailFieldErrorText: '', //'Oopsie! That email doesn\'t look correct',
      nameFieldText: '',
      nameFieldError: false,
      nameFieldErrorText: '', //'Nice name but sorry we can\'t use numbers'
      numberOfProps: '',
      passwordFieldText: '',
      passwordConfirmFieldText: '',
      passwordFieldError: false,
      passwordFieldErrorText: '', //'Oopsie! Looks like you forgotten our secret',
      phoneAuthErrorText: '',
      userType: QueryString.parse(props.location.search)['user_type'] || 'renter'
    };
    this._handleResendEmailVerification = this._handleResendEmailVerification.bind(this);
    this._handleOnChangePhoneNumber = this._handleOnChangePhoneNumber.bind(this);
    this._handleOnChangePhoneVerify = this._handleOnChangePhoneVerify.bind(this);
    this._initPasswordRequest = this._initPasswordResetRequest.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.staticContext && this.props.staticContext.user) {
      this.props.setAuthUser(this.props.staticContext.user);
    }

    firebase.auth().getRedirectResult().then((result) => {
      if (result.credential) {
        var token = result.credential.accessToken;
        if (token) {
          setCookie(HA_USER_TOKEN, token, 365);
        }
      }
      this._onAuthSuccess(
        result.additionalUserInfo.isNewUser,
        result.additionalUserInfo.providerId === 'google.com' ? 'GOOGLE' : 'FACEBOOK',
        result.user
      );
    }).catch((_) => {
      //const { code, message, email, credential } = error;
    });

  }

  componentDidMount() {
    const { actionCode, authType, type } = this.props;
    if (authType === 'phone') {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-phone');
    }
    window.scrollTo(0, 0);

    if (type === 'resetPassword') {
      firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
        this.setState({
          emailFieldText: email
        })
      }).catch((error) => {
        this.setState({
          authFailure: true,
          authStatusMessage: parseAuthErrors(error.code),
          headerText: 'Please enter your email to reset your password',
          passwordResetExpired: true
        });
        console.log('Error verifying password reset code', error);
      });
    }
  }

  _authUserWithEmail() {
    this._validateUserInput();
  }

  _authUserWithSocial(type) {
    let provider = new firebase.auth[
      type === 'google'
        ? 'GoogleAuthProvider'
        : 'FacebookAuthProvider'
    ]();
    firebase.auth().signInWithRedirect(provider);
  }

  _authUserWithPhone() {
    let phoneNumber = `+1${this.phoneNumber}`;
    let appVerifier = window.recaptchaVerifier;
    let self = this;
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        console.log('the confirmation result ', confirmationResult);
        window.confirmationResult = confirmationResult;
        this._handlePhoneVerify();
      }).catch((error) => {
        self.setState({
          btnDisabled: false,
          loading: false,
          phoneAuthErrorText: parseAuthErrors(error.code)
        });
        console.log(`Some error,`, error)
      });
  }

  _onAuthFailure(code) {
    this.setState({
      authFailure: true,
      authStatusMessage: parseAuthErrors(code)
    })
    this._resetForm();
  }

  _onAuthSuccess(isNewUser, authProvider, authUser) {
    let user = authUser || firebase.auth().currentUser;
    const { displayName, email, uid, phoneNumber } = user;
    let { nameFieldText, userType } = this.state;

    let userObject = {
      name: nameFieldText || displayName || `Anonymous`,
      authProvider,
      ...(email && { email }),
      ...(phoneNumber && { phoneNumber }),
      type: userType.toUpperCase(),
      uid
    }

    if (isNewUser && user) {
      if (authProvider === 'EMAIL') {
        userObject.emailVerified = false;
        userObject.status = 'ACTIVE';
      }
      this._setNewUserDetails(userObject);
    }
    else {
      this._resetForm();
      this.props.setAuthUser(userObject);
      this._redirectOnAuthSuccess(userType, false);
    }
  }

  _setNewUserDetails(userObject) {
    let user = firebase.auth().currentUser;
    let date = new Date().toISOString();

    if (user) {
      user.updateProfile({
        displayName: userObject.name
      }).then(() => {
        userObject.createdAt = date;
        userObject.modifiedAt = date;
        this._saveUserToDB(userObject, user);
      }).catch((error) => {
        console.log('Error updating profile', error);
      });
    }
    else {
      userObject.createdAt = date;
      userObject.modifiedAt = date;
      this._saveUserToDB(userObject, user);
    }
  }

  //TODO: Consider moving this logic to user profile service
  _saveUserToDB(userObject) {
    let FIRESTORE = firebase.firestore();

    console.log('Gets here', userObject);

    FIRESTORE.collection("users").doc(userObject.uid).set(userObject)
      .then((docRef) => {
        console.log('Document written with ref ', docRef);
        if (userObject.authProvider === 'EMAIL') {
          this._sendEmailVerification(userObject);
        }
        else {
          this._showStatusMessage(true);
          this._resetForm();
          this._redirectOnAuthSuccess(userObject.type, true);
        }
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  _sendEmailVerification(user, message) {
    const { emailFieldText } = this.state;
    const email = emailFieldText || user.email;

    apiClient(USER_PROFILE_SERVICE_ENDPOINT)
      .post('/auth/verify-email', { email })
      .then((res) => {
        console.log('Verification email sent', res);
        this._showStatusMessage(true, message);
        this._resetForm();
        this.props.setAuthUser(user);
        this._redirectOnAuthSuccess(user.type, true);
      })
      .catch((error) => {
        console.log(`Error sending verification email, `, error);
      });
  }

  _redirectOnAuthSuccess(userType, isNewUser) {
    const { embed, history, location } = this.props;
    const { search } = location;

    const rerouteToListing = QueryString.parse(search)['save_listing'];
    const redirectUrl = QueryString.parse(search)['redirect_url'];

    if (userType.toLowerCase() === 'agent' && !embed) {
      setTimeout(() => {
        //const view = location.search.substring(55);
        history.replace({
          pathname: `/home/agent`,
          search: '?selectedView=add',
          state: { newUser: isNewUser },
        });
      }, 2500);
    }
    else if (userType === 'renter') {
      if (rerouteToListing) {
        setTimeout(() => {
          history.replace({
            pathname: redirectUrl,
            state: { newUser: isNewUser },
          });
        }, 2500);
      }
      else {
        setTimeout(() => {
          history.replace(`/apartments/ak/uyo`);
        }, 2500);
      }
    }
  }

  _handleResendEmailVerification() {
    this._sendEmailVerification(this.props.authState.user,
      'A new link has been sent for you to verify your account');
  }

  _initPasswordResetRequest() {
    const { emailFieldText } = this.state;

    if (emailFieldText.length === 0) {
      this.setState({
        emailFieldError: true,
        emailFieldErrorText: 'Oops! You forget to type in an email',
      });
    }
    else {
      // firebase.auth().sendPasswordResetEmail(this.state.emailFieldText)
      //   .then(() => {
      //     this.setState({
      //       authSuccess: true,
      //       authStatusMessage: 'A password reset link has been sent to your email account',
      //     });
      //   }).catch((error) => {
      //     console.log(`Error sending password reset email`, error);
      //     this.setState({
      //       authFailure: true,
      //       authStatusMessage: parseAuthErrors(error.code),
      //     });
      //   });
      apiClient(USER_PROFILE_SERVICE_ENDPOINT)
        .post('/auth/reset-password', { email: emailFieldText })
        .then((res) => {
          this.setState({
            authSuccess: true,
            authStatusMessage: 'A password reset link has been sent to your email account',
            emailFieldText: ''
          });
        })
        .catch((error) => {
          this.setState({
            authFailure: true,
            authStatusMessage: error.response.data.message
          });
        });
    }
  }

  _handleAuthButtonClick(type) {
    switch (type) {
      case 'forgot':
        this._initPasswordResetRequest();
        break;
      case 'resetPassword':
        this._handlePasswordReset();
        break;
      default:
        this._authUserWithEmail(type);
        break;
    }
  }

  _handleOnChangePhoneNumber(value) {
    if (this.state.phoneAuthError) {
      this.setState({
        phoneAuthError: false,
        phoneAuthErrorText: ''
      })
    }
    this.phoneNumber = value;
  }

  _handleEmailAuth() {
    const { emailFieldText, passwordFieldText } = this.state;
    const { type } = this.props;

    firebase.auth()[
      type === 'signup'
        ? 'createUserWithEmailAndPassword'
        : 'signInWithEmailAndPassword'
    ](emailFieldText, passwordFieldText)
      .then((res) => {
        console.log('==2==: Fires second');
        this._onAuthSuccess(type === 'signup', 'EMAIL', res.user);
      })
      .catch((error) => {
        const { code, message } = error;
        console.log('Error', code, message);
        this._onAuthFailure(code);
      });
  }

  _handlePhoneAuth() {
    if (this.phoneNumber) {
      this.setState({
        btnDisabled: true,
        loading: true
      })
      this._authUserWithPhone();
    }
    else {
      this.setState({
        phoneAuthError: true,
        phoneAuthErrorText: `Please enter a valid phone number`
      });
    }
  }

  _handleOnChangePhoneVerify(value) {
    this.phoneVerifyCode = value;
  }

  _handlePhoneVerify() {
    const { history, location, type } = this.props;
    history.replace(constructAuthURL(type, location, `phone-verify`));
  }

  _handlePhoneVerifyClick() {
    if (this.phoneVerifyCode) {
      this.setState({
        btnDisabled: true,
        loading: true
      })
      this._verifyPhoneCode(this.phoneVerifyCode);
    }
    else {
      this.setState({
        phoneAuthError: true,
        phoneAuthErrorText: `Please enter the code sent to your phone`
      });
    }
  }

  _verifyPhoneCode(code) {
    if (window.confirmationResult) {
      window.confirmationResult.confirm(code).then((result) => {
        var user = result.user;
        console.log('Is a new user', result.additionalUserInfo.isNewUser);
        this._onAuthSuccess(result.additionalUserInfo.isNewUser, 'PHONE', user);
      }).catch((error) => {
        console.log(error);
        this.setState({
          phoneAuthErrorText: parseAuthErrors(error.code) || `Oopsie! We cannot verify the code you provided`
        });
      });
    }
  }

  _handlePhoneOptionClick() {
    const { history, location, type } = this.props;
    history.push(constructAuthURL(type, location, `phone`, this.state.userType));
  }

  _handlePasswordReset() {
    const { actionCode, history } = this.props;
    const password = this.state.passwordFieldText;
    const auth = firebase.auth();
    auth.confirmPasswordReset(actionCode, password).then((resp) => {
      console.log('Success response', resp);
      this.setState({
        authSuccess: true,
        authStatusMessage: 'Password updated successfully. Please login with your new password',
        emailFieldText: ''
      }, () => {
        setTimeout(() => {
          history.replace('/login');
        }, 5000);
      });
    }).catch((error) => {
      const { code, message } = error;
      console.log(code, message);
      this._onAuthFailure(code);
    });
  }

  _clearAllFormFieldErrors() {
    this.setState({
      btnDisabled: true,
      loading: true,
      nameFieldError: false,
      nameFieldErrorText: '',
      emailFieldError: false,
      emailFieldErrorText: '',
      passwordFieldError: false,
      passwordFieldErrorText: '',
    }, () => {
      this._handleEmailAuth();
    });
  }

  _handleFieldChange(field, e) {
    let error = `${field}FieldError`;
    let errorLabel = `${error}Text`;
    let fieldText = `${field}FieldText`;

    if (this.state[error]) {
      this.setState({
        [error]: false,
        [errorLabel]: ''
      });
    }
    this.setState({
      [fieldText]: e.target.value
    });
  }

  _resetAuthState() {
    this.setState({
      authFailure: false,
      authSuccess: false,
      authStatusMessage: ``,
    });
  }

  _resetForm() {
    this.setState({
      btnDisabled: false,
      loading: false,
      nameFieldText: '',
      emailFieldText: '',
      numberOfProps: '',
      passwordConfirmFieldText: '',
      passwordFieldText: '',
      propertyCompanyField: '',
    });
  }

  _showStatusMessage(status, successMessage) {
    if (status) {
      this.setState({
        authSuccess: true,
        authStatusMessage: successMessage || 'Account signup successful. A link has been sent for you to verify your account',
      });
    }
  }

  _setUserAuthType() {
    if (this.state.userType === 'agent') {
      this.setState({
        authFailure: false,
        authSuccess: false,
        userType: 'renter'
      });
    }
    else {
      this.setState({
        authFailure: false,
        authSuccess: false,
        userType: 'agent'
      });
    }
  }

  _validateUserInput() {
    if (this.props.type === 'signup' && this.state.nameFieldText.length === 0) {
      this.setState({
        nameFieldError: true,
        nameFieldErrorText: 'Anonymous? C\'mon, we know you have a name',
      });
    }
    else if (this.state.emailFieldText.length === 0) {
      this.setState({
        emailFieldError: true,
        emailFieldErrorText: 'Oops! You forget to type in an email',
      });
    }
    else if (this.state.passwordFieldText.length === 0) {
      this.setState({
        passwordFieldError: true,
        passwordFieldErrorText: `Don't be scared, your secret is safe with us`,
      });
    }
    else {
      this._clearAllFormFieldErrors();
    }
  }

  _redirectLoggedInUser() {
    const { authState } = this.props;
    console.log('Authstate user', authState.user);

    // if (authState && !authState.loading && authState.user && authState.user.uid) {
    //   let redirectUrl = QueryString.parse(location.search)['redirect_url'];
    //   if (!redirectUrl) {
    //     if (authState.user.type === 'RENTER') {
    //       redirectUrl = '/apartments/ak/uyo'
    //     }
    //     else if (authState.user.type === 'AGENT') {
    //       redirectUrl = `/home/agent`
    //     }
    //     else {
    //       redirectUrl = `/`
    //     }
    //   }
    //   return <Redirect to={redirectUrl} />
    // }
  }

  render() {
    const { classes, type, embed, emailVerified, emailVerifiedMessage, headerText, location } = this.props;
    const { passwordResetExpired } = this.state;
    const buttonClassName = (type === 'signup') ? 'btn-cta-main signup' : 'btn-regular login';
    const hide = { display: 'none' }, show = { display: 'block' };
    let agentEmail = QueryString.parse(location.search)['agent_email'];

    /** Styles */
    const getSpinnerStyle = (loading) => {
      if (loading || this.state.loading) {
        if (type === 'signup') {
          return {
            ...show,
            color: '#F7BF23'
          }
        }
        else {
          return {
            ...show,
            color: 'white'
          }
        }
      }
      else {
        return hide;
      }
    }
    const getLoadingStyle = (type) => {
      if (this.state.loading) {
        if (type === 'signup') {
          return {
            background: '#FCEBBD'
          }
        }
        else {
          return {
            background: 'rgb(203, 153, 206)'
          }
        }
      }
      else {
        return {};
      }
    }

    const displayAuthStatus = () => {
      if (this.state.authSuccess) {
        return (
          <Snackbar type={type}
            duration='indefinite'
            message={this.state.authStatusMessage} />
        );
      }
      else if (this.state.authFailure) {
        return (
          <Snackbar type='error' duration='indefinite'
            message={this.state.authStatusMessage} />
        );
      }
      else if (emailVerified) {
        return (
          <Snackbar type={type}
            duration='indefinite'
            message={emailVerifiedMessage || 'Email verified successfully. Please login to continue'} />
        );
      }
      else if (emailVerified === false) {
        return (
          <Snackbar
            buttonText={'Resend Link'}
            type='error'
            duration='indefinite'
            message={'Oops! This email verification link is invalid. It might have expired or has been used already.'}
            withButton={true}
            handleBtnClick={this._handleResendEmailVerification}
          />
        );
      }
    };

    const displayHeader = () => {
      if (this.state.headerText) {
        return (
          <h3 className={`margin-tb48 ta-center fw-700`}>
            {this.state.headerText}
          </h3>
        );
      }
      else if (agentEmail) {
        return (
          <h3 className={`margin-tb48 ta-center fw-700`}>
            {
              `Almost there. Please signup to complete your upload`
            }
          </h3>
        );
      }
      else if (type === 'forgot') {
        return (
          <h3 className='margin-tb48 ta-center fw-700' style={{ fontSize: '1.15 rem' }}>
            Please enter your email to reset your password
          </h3>
        );
      }
      else if (type === 'signup') {
        console.log('Blaaa', headerText)
        return (
          <h3 className='margin-tb32 ta-center'>
            {
              this.state.userType === 'agent'
                ?
                location.search.indexOf('selectedView=add') > -1
                  ?
                  'Please create an account to save the listing to your profile'
                  :
                  <div><span className='form-header fw-700'>Earn with your listings.</span><br /><p>Create an account to get started</p></div>
                :
                headerText
                  ?
                  headerText
                  :
                  <div><span className='form-header fw-700'>Earn more today.</span><p>Create an account to get started</p></div>
            }
          </h3>
        );
      }
      else if (type === 'login') {
        return (
          <h3 className='margin-tb32 ta-center'>
            {
              headerText ?
                'Please login to continue. We don\'t want you to miss this.'
                :
                <div><span className='form-header fw-700'>We're glad you're back.</span><br /><p>Please login to continue</p></div>
            }
          </h3>
        );
      }
      else {
        return (
          <h3 className='margin-tb16 ta-center form-header'>
            {
              headerText || <div><span className='form-header'>We're glad you're back.</span><br /><p>Please login to continue</p></div>
            }
          </h3>
        );
      }
    };

    const displayNameField = () => {
      if (type === 'signup') {
        return (
          <div className='width-p100 ta-center'>
            <TextField
              id='we-know-you-by-this'
              label='Full Name'
              type='text'
              placeholder='We know you got a fine name'
              style={{ marginBottom: 12, borderBottomColor: 'green' }}
              error={this.state.nameFieldError}
              value={this.state.nameFieldText}
              aria-describedby='error-name-field'
              onChange={this._handleFieldChange.bind(this, 'name')}
              fullWidth
              required
              InputProps={{
                classes: {
                  root: classes.root,
                  input: type === 'signup' ? classes.inputSignup : classes.inputLogin,
                  underline: type === 'signup' ? classes.underlineSignup : classes.underlineLogin,
                },
              }}
            />
            <span id='error-name-field' role='alert' className='error'>{this.state.nameFieldErrorText}</span>
          </div>
        );
      }
      else {
        return (
          <div></div>
        );
      }
    };

    const displayEmailField = () => {
      if (type !== 'resetPassword' || passwordResetExpired) {
        return (
          <div className='width-p100 ta-center'>
            <TextField
              id='your-uniqueness'
              label='Email Address'
              type='email'
              placeholder='Email pretty please'
              style={{ marginBottom: 12 }}
              error={this.state.emailFieldError}
              value={this.state.emailFieldText}
              aria-describedby='error-email-field'
              onChange={this._handleFieldChange.bind(this, 'email')}
              fullWidth
              required
              InputProps={{
                classes: {
                  root: classes.root,
                  input: type === 'signup' ? classes.inputSignup : classes.inputLogin,
                  underline: type === 'signup' ? classes.underlineSignup : classes.underlineLogin,
                },
              }}
            />
            <span id='error-email-field' role='alert' className='error'>{this.state.emailFieldErrorText}</span>
          </div>
        );
      }
      else {
        return (
          <div></div>
        );
      }
    };

    const displayPasswordField = () => {
      if (!passwordResetExpired && type !== 'forgot') {
        return (
          <div className='width-p100 ta-center'>
            <TextField
              id="your-encodedness"
              label="Password"
              type="password"
              placeholder="Our little secret :)"
              style={{ marginBottom: 12 }}
              error={this.state.passwordFieldError}
              value={this.state.passwordFieldText}
              aria-describedby='error-password-field'
              onChange={this._handleFieldChange.bind(this, 'password')}
              fullWidth
              required
              InputProps={{
                classes: {
                  root: classes.root,
                  input: type === 'signup' ? classes.inputSignup : classes.inputLogin,
                  underline: type === 'signup' ? classes.underlineSignup : classes.underlineLogin,
                },
              }}
            />
            <span id='error-password-field' role='alert' className='error'>{this.state.passwordFieldErrorText}</span>
          </div>
        );
      }
      else {
        return (
          <div></div>
        );
      }
    };

    const displaySocialLogin = () => {
      return (
        (this.props.type !== 'admin' && this.props.type !== 'forgot' && this.props.type !== 'resetPassword')
        &&
        <div>
          <p className='ta-center margin-tb16'>OR</p>

          <GoogleBtn onClick={this._authUserWithSocial.bind(this, 'google')}
            label={type === 'signup' ? 'Signup with Google' : 'Continue with Google'} />
          <FacebookBtn onClick={this._authUserWithSocial.bind(this, 'facebook')}
            label={type === 'signup' ? 'Signup with Facebook' : 'Continue with Facebook'} />

          {/* <p className='ta-center margin-tb32'>OR</p>

          <button type='button' className={`btn-universal login btn btn-m br-3 margin-t16 flex-column-center`}
            onClick={this._handlePhoneOptionClick.bind(this, type)}
            disabled={this.state.btnDisabled}>
            <span style={this.state.loading ? { color: 'gray' } : {}}>
              {type === 'signup'
                ?
                `Signup with Phone Number`
                :
                `Login with Phone Number`
              }</span>
          </button> */}
        </div>
      )
    }

    const displayFooterLinks = () => {
      if (type === 'admin' || type === 'forgot' || type === 'resetPassword') {
        return (
          <div>
          </div>
        );
      }
      else if (type === 'signup') {
        return (
          <div className='flex-row-spacebetween width-p100 margin-tb48'>
            <Link to='#' className='color-text' onClick={this._setUserAuthType.bind(this)}>
              {this.state.userType === 'agent' ? 'Renter Signup' : 'Agent Signup'}
            </Link>
            <Link to={constructAuthURL('login', location, this.props.authType === 'phone' && 'phone')} className='color-text'>Login</Link>
            <Link to='#' className='color-text'>Help</Link>
          </div>
        );
      }
      else {
        return (
          <div className='flex-row-spacebetween width-p100 margin-tb48'>
            <Link to='/auth/forgot-password' className='color-text'>Forgot Password</Link>
            <Link to={constructAuthURL('signup', location, this.props.authType === 'phone' && 'phone')} className='color-text'>Signup</Link>
            <Link to='#' className='color-text'>Help</Link>
          </div>
        );
      }
    };

    const displayAuthFormContent = () => {
      return this.props.authType === 'phone'
        ?
        <div className='flex-column-center form-container'>
          {!this.props.embed && <Link to='/'><img src={LELogo} height={50} alt='list-and-earn-logo' aria-label='List and Earn Logo' /></Link>}
          {displayAuthStatus()}
          <h3 className='margin-tb32 ta-center fw-700'>
            {
              this.props.verify
                ? `Please enter the verification code sent to your phone`
                : `Please enter your phone number to continue`
            }
          </h3>
          <p id='error-name-phone' role='alert' className='error ta-center'>{this.state.phoneAuthErrorText}</p>
          {
            this.props.verify
              ?
              <Dial
                onPadPress={this._handleOnChangePhoneVerify}
                type={type}
                error={this.state.phoneAuthError}
              />
              :
              <Dial
                onPadPress={this._handleOnChangePhoneNumber}
                type={type}
                error={this.state.phoneAuthError}
              />
          }
          <div id="recaptcha-phone" className='margin-t16'></div>
          {
            this.props.verify
              ?
              <button type='button'
                className={`${buttonClassName} btn btn-m br-3 margin-t48 flex-column-center`}
                onClick={this._handlePhoneVerifyClick.bind(this, type)}
                style={getLoadingStyle(type)}
                disabled={this.state.btnDisabled}>
                <CircularProgress size={32}
                  style={getSpinnerStyle()}
                />
                <span style={this.state.loading ? hide : show}>Verify Code</span>
              </button>
              :
              <button type='button' id='phone-signin-btn'
                className={`${buttonClassName} btn btn-m br-3 margin-t48 flex-column-center`}
                onClick={this._handlePhoneAuth.bind(this, type)}
                style={getLoadingStyle(type)}
                disabled={this.state.btnDisabled}>
                <CircularProgress size={32}
                  style={getSpinnerStyle()}
                />
                <span style={this.state.loading ? hide : show}>{type === 'signup' ? `Signup` : 'Login'}</span>
              </button>
          }

          {!this.props.verify && displayFooterLinks()}
        </div>
        :
        <div className='flex-column-center form-container'>
          {!this.props.embed && <Link to='/'><img src={LELogo} height={50} alt='list-and-earn-logo' aria-label='List and Earn Logo' /></Link>}
          <div style={embed ? { display: 'none' } : {}} className='ta-center'>
            {displayHeader()}
          </div>
          <div className='margin-t8 margin-b32 width-p100'>
            {displayAuthStatus()}
          </div>

          {displayNameField()}
          {displayEmailField()}
          {displayPasswordField()}

          <div className='width-p100'>
            {
              passwordResetExpired
                ?
                <button type='button' className={`${buttonClassName} btn btn-m br-3 margin-t32 flex-column-center`}
                  onClick={this._initPasswordResetRequest.bind(this)}
                  style={getLoadingStyle(type)}>
                  <CircularProgress size={32}
                    style={getSpinnerStyle()}
                  />
                  <span style={this.state.loading ? hide : show}>
                    Reset Password
                  </span>
                </button>
                :
                <button type='button' className={`${buttonClassName} btn btn-m br-3 margin-t32 flex-column-center`}
                  onClick={this._handleAuthButtonClick.bind(this, type)}
                  style={getLoadingStyle(type)}
                  disabled={this.state.btnDisabled}>
                  <CircularProgress size={32}
                    style={getSpinnerStyle()}
                  />
                  <span style={this.state.loading ? hide : show}>
                    {type === 'signup'
                      ? `Signup as ${this.state.nameFieldText}`
                      : type === 'forgot'
                        ? 'Reset Password'
                        : type === 'resetPassword'
                          ? 'Update Password'
                          : 'Login'
                    }
                  </span>
                </button>
            }

            {displaySocialLogin()}

            <div className='flex-column-center form-container'>
              <p className='margin-t48 ta-center color-gray-m fw-300' style={type === 'signup' ? { fontSize: 14, display: 'visible' } : { visibility: 'hidden' }}>
                By signing up, you are agreeing to our <b>Terms of Service</b> and <b>Privacy Policy</b>
              </p>
              {displayFooterLinks()}
            </div>
          </div>
        </div>
    }
    const displayLoadingState = () => {
      return (
        <Loader />
      )
    }

    return (
      <div className='bg-houses padding-tb16 flex-column-center' style={{ backgroundPosition: '40% 70%' }}>
        {this._redirectLoggedInUser()}
        {
          this.props.authState.loading
            ?
            displayLoadingState()
            :
            displayAuthFormContent()
        }
      </div>
    );
  }
}

AuthForm.propTypes = {
  type: PropTypes.string
};

AuthForm.defaultProps = {
  type: 'signup',
  userType: 'renter'
};

const mapStateToProps = ({ authState, windowWidth }) => ({
  authState,
  windowWidth
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAuthUser: (user) => dispatch(getAuthenticatedUser()),
    setAuthUser: (user) => dispatch(setAuthenticatedUser(user)),
    // toggleNavBar: (status) => dispatch(toggleNavBar(status)),
    // toggleSignupBtn: (status) => dispatch(toggleSignupBtn(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)
  (withRouter(withStyles(styles)(AuthForm)));
