import HomeA from './pages/landing/HomeA';
import HomeB from './pages/landing/HomeB';
import Survey from './pages/survey';
import Waitlisted from './pages/waitlist';

import AgentHome from './pages/home/Agent';
import AuthAction from './pages/auth/Action';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import PasswordReset from './pages/auth/PasswordReset';
import PhoneAuth from './pages/auth/PhoneAuth';
import PhoneVerify from './pages/auth/PhoneVerify';


const Routes = {
	AgentHome,
	AuthAction,
	HomeA,
	HomeB,
	Login,
	Signup,
	PasswordReset,
	PhoneAuth,
	PhoneVerify,
	Survey,
	Waitlisted
}

export default Routes;