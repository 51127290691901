import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Add from '@material-ui/icons/AddCircle';
import Calendar from '@material-ui/icons/Today';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dashboard from '@material-ui/icons/Dashboard';
import Draft from '@material-ui/icons/RateReview';
import Edit from '@material-ui/icons/Edit';
import Empty from '@material-ui/icons/Landscape';
import Error from '@material-ui/icons/Error';
import Helmet from 'react-helmet';
//import Help from '@material-ui/icons/Help';
import Likes from '@material-ui/icons/Favorite';
import Listings from '@material-ui/icons/ViewList';
import Pay from '@material-ui/icons/AttachMoney';
import QueryString from 'query-string';
import Message from '@material-ui/icons/Mail';
import Settings from '@material-ui/icons/Settings';
import UploadVideo from '@material-ui/icons/Videocam';
import Viewed from '@material-ui/icons/RemoveRedEye';
//import WhatsHot from '@material-ui/icons/TrendingUp';

import AdminListItem from '../../components/Admin/ListItem';
import AppointmentScheduler from '../../components/AppointmentScheduler';
import AuthForm from '../../containers/AuthForm';
import Modal from '../../components/Modal';
import Snackbar from '../../components/Snackbar';
import InfoNugget from '../../components/InfoNugget';
//import LineChart from '../../components/Charts/Line';
import { AppointmentListItem, ListingListItem } from '../../components/ListItem';
import ListingUploadForm from '../../containers/UploadForm'
import WhatsHotView from './WhatsHotView';
import {
  AVERAGE_EARNING_PER_LISTING_STR,
  AVERAGE_MONTHLY_EARNING,
  AVERAGE_EARNING_PER_LISTING,
  MAXIMUM_EARNED_PER_LISTING,
  YOUR_CURRENT_EARNING,
  YOUR_NEXT_EARNING,
  TEST_USER,
  TOTAL_NUM_AGENTS
} from '../../Constants';
import { toggleNavBar, toggleSignupBtn } from '../../containers/NavBar/actions';
import {
  createListing,
  deleteListing,
  getListings,
  editListing
} from '../listings/actions';
import {
  createAppointment,
  deleteAppointment,
  editAppointment,
  getAppointments,
  endApartmentEdit,
  getDashboardData,
  saveApartmentDraft,
} from './actions';
import {
  deleteAssets,
  uploadAssets
} from '../../services/AssetManager';
import {
  getAssetsDiff,
  geoCodeAddress,
  getDayFromISOString,
  getMonthFromISOString,
  getLocationQueryParamValue,
  getSelectedView,
  getYearFromISOString,
  objectifyImageList,
  ScriptLoader,
} from '../../Utils';
//import AnalyticsOne from '../../assets/imgs/analytics1.png';
//import AnalyticsTwo from '../../assets/imgs/analytics2.png';
//import NoListings from '../../assets/imgs/empty_state.png';
import './HomePage.scss';


const TITLE = {
  'appointments': 'My Appointments',
  'dashboard': 'Dashboard',
  'listings': 'My Listings',
  'earnings': 'My Earnings',
  'messages': 'Messages',
  'add': 'Add Listing',
  'edit': 'Edit Listing',
  'settings': 'Settings',
  'whatshot': 'Trending Searches'
};


class AgentHomePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      appointmentEditMode: false,
      currentListing: {
        _id: 'new',
        images: {},
        video: []
      },
      earningsCounter: 0,
      hasVisitedEarningsSection: false,
      openStatusModal: false,
      statusModalType: '',
    };
    this._cancelDeleteAppointment = this._cancelDeleteAppointment.bind(this);
    this._cancelDiscardDraft = this._cancelDiscardDraft.bind(this);
    this._cancelVideoUploadSuggestion = this._cancelVideoUploadSuggestion.bind(this);
    this.handleAutoSave = this.handleAutoSave.bind(this);
    this._handleCalendarClick = this._handleCalendarClick.bind(this);
    this._handleCloseModal = this._handleCloseModal.bind(this);
    this._onConfirmDeleteAppointment = this._onConfirmDeleteAppointment.bind(this);
    this._onConfirmDeleteListing = this._onConfirmDeleteListing.bind(this);
    this._onDeleteAppointment = this._onDeleteAppointment.bind(this);
    this._onDeleteListing = this._onDeleteListing.bind(this);
    this._onDiscardDraft = this._onDiscardDraft.bind(this);
    this._onDiscardDraftToAllowEdit = this._onDiscardDraftToAllowEdit.bind(this);
    this._onEditAppointment = this._onEditAppointment.bind(this);
    this._onEditListing = this._onEditListing.bind(this);
    this._handleLocationBlur = this._handleLocationBlur.bind(this);
    this._handleOpenVideoModal = this.openVideoModalBeforeUpload.bind(this);
    this._handleScheduleAppointment = this._handleScheduleAppointment.bind(this);
    this._onSelectAxis = this._onSelectAxis.bind(this);
    this._onSelectType = this._onSelectType.bind(this);
    this._handleTryUploadAgain = this._handleTryUploadAgain.bind(this);
    this._onVideoFileDialogCancel = this._onVideoFileDialogCancel.bind(this);
    this._handleViewAppointmentsForListing = this._handleViewAppointmentsForListing.bind(this);
    this._initAddListing = this._initAddListing.bind(this);
    this.openVideoDialog = this.openVideoDialog.bind(this);
    this._setImageTitle = this._setImageTitle.bind(this);
  }

  componentWillMount() {
    const { authState, location, getAppointments, getListings, getDashboardData } = this.props;
    this.props.toggleNavBar(true);
    this.props.toggleSignupBtn(false);

    //TODO: Remove this after auth is hooked up
    //getDashboardData({ userId: '12344' });
    getAppointments({ userId: 'someAgentId' });
    getListings({ userId: 'someAgentId' });

    if (authState && authState.user && authState.user.uid) {
      const userId = authState.user.uid;
      getListings({ userId });
      getAppointments({ userId });
    }

    const selectedView = QueryString.parse(location.search)['selectedView'];
    if (TITLE[selectedView]) {
      this.setState({
        selectedView
      });
    }
    else {
      this.setState({
        selectedView: 'dashboard'
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getListings } = this.props;
    const { appointments, authState, listings } = nextProps;
    const currentAppointmentsLoadState = this.props.appointments.loading || this.state.statusModalType === 'loading';
    const currentListingLoadState = this.props.listings.loading;

    if ((!currentListingLoadState && listings.loading) ||
      (!currentAppointmentsLoadState && appointments.loading)) {
      this._toggleStatusModal('loading', true);
    }

    if ((currentListingLoadState && !listings.loading && !listings.source) ||
      (currentAppointmentsLoadState && !appointments.loading && !appointments.source)) {
      this._toggleStatusModal('loading', false);
    }

    // console.log('in comoProps', currentListingLoadState, listings);
    // console.log('This is state', this.state);

    if (/*currentListingLoadState && */!listings['loading'] && listings['completed']) {
      if (listings['success']) {
        if (listings.source === 'create') {
          this.setState({ statusModalType: 'listingCreateSuccess' });
        }
        else if (listings.source === 'delete') {
          this.setState({ statusModalType: 'listingDeleteSuccess' });
        }
        this._scrollToTop();
      }
      else {
        if (listings.source === 'create') {
          this.setState({ statusModalType: 'listingCreateFailure' });
        }
      }
    }

    if (currentAppointmentsLoadState && !appointments['loading'] && appointments['completed']) {
      if (appointments['success']) {
        this.setState({
          openStatusModal: true, //false
          statusModalType: `${appointments.source}Success`,
          appointmentCreatedFor: appointments.listingId
        });
      }
      else {
        this.setState({
          openStatusModal: true, //false
          statusModalType: `${appointments.source}Failure`,
          appointmentCreatedFor: appointments.listingId
        });
      }
    }

    if (!this.props.authState.user && authState.user && authState.user.uid) {
      const userId = authState.user.uid;
      if (userId) {
        getListings({ userId });
      }
    }
  }

  componentDidMount() {
    const { authState = { uid: 'test-user' }, aptDraftState, createListing, location } = this.props;
    const selectedView = getSelectedView(location);
    this._scrollToTop();
    if (selectedView === 'earnings') {
      this._runEarningsCounter();
    }

    if (typeof google === 'undefined') {
      ScriptLoader(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyDx8NXRQxn9zRAwXHlO0o21_I4fXR6ufhs&v=3.exp&libraries=geometry,drawing,places&callback=initMap'
      )
    }

    if (this.$ref && location.hash.includes('#my-data')) {
      this.$ref.scrollIntoView({
        behaviour: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }

    if (aptDraftState && authState.user && authState.user.uid) {
      //Show loading modal after user successfully signs up
      let aptObjData = {
        ...aptDraftState,
        ...{ agentId: authState.user.uid },
        ...{ isNewUser: location.state && location.state.newUser }
      }
      this._toggleStatusModal('Loading');
      createListing(aptObjData);
    }
  }

  _cancelDeleteAppointment = () => {
    this._toggleStatusModal('deleteAppointmentConfirm', false);
  }

  _cancelDeleteListing = () => {
    this.orphanedListing = null;
    this._toggleStatusModal('deleteListingConfirm', false);
  }

  _cancelDiscardDraft = () => {
    this._toggleStatusModal('pendingDraft', false);
  }

  _cancelVideoUploadSuggestion = () => {
    const authState = { user: TEST_USER } // = this.props;
    const { location } = this.props;

    if (QueryString.parse(location.search)['signup'] === 'true' || !authState || !authState.user || !authState.user.uid) {
      //this.props.saveApartmentDraft(this.listing);
      //this._routeToSignupPage('add');
      this._processListingForUpload(this.listing, authState.user)
    }
    else if (authState && authState.user && authState.user.uid) {
      this._toggleStatusModal('loading', true);
      this._processListingForUpload(this.listing, authState.user)
    }
  }

  async _geoCodeListingAddress(address) {
    const latlng = await geoCodeAddress(`${address}, Uyo`);
    this.setState({ latlng });
  }

  handleAutoSave = (currentListing) => {
    this.setState({ currentListing });
  }

  _handleClickInfoNugget(view, e) {
    this._selectView(view || 'whatshot');
  }

  _handleCloseModal = () => {
    this.setState({ openStatusModal: false, statusModalType: '' });
  }

  _handleCalendarClick = (listing) => {
    this.setState({
      selectedListing: listing,
      openStatusModal: true,
      statusModalType: 'appointmentManage'
    });
  }

  _handleLocationBlur(e) {
    const { location, latlng } = this.state;
    if ((location === e.target.value && !latlng)
      || location !== e.target.value) {
      //this._geoCodeListingAddress(e.target.value);
    }
  }

  _handleMenuOpen(type, e) {
    this._clearFieldError(type);
  }

  _handleKeyPress(e) {
    console.log(e);
  }

  _handlePieClick() {
    this._selectView('whatshot');
  }

  _handleScheduleAppointment = (date, time, appointment) => {
    const { authState, createAppointment, editAppointment } = this.props;
    const { selectedListing } = this.state;
    let appointmentData = {
      //creatorId: 'someAgentId',// authState.user.id,
      date,
      isoDateTime: new Date(`${date} ${time}`).toISOString(),
      time
    };

    if (appointment._id) {
      appointmentData = {
        ...appointmentData,
        ...{ listingId: appointment.listingId }
      }
      editAppointment(appointmentData, appointment._id);
    }
    else {
      appointmentData = {
        ...appointmentData,
        ...{ listingId: selectedListing._id }
      }
      createAppointment(appointmentData)
    }

    // if (authState && authState.user && authState.user.uid) {
    //  createAppointment({
    //     isoDateTime: new Date(`${date} ${time}`).toISOString(),
    //     listingId: this.state.currentListingId,
    //     creatorId: authState.user.id
    //   });
    // }
  }

  _handleTryUploadAgain = () => {
    const authState = { user: TEST_USER }
    this._processListingForUpload(this.listing, authState.user);
  }

  _handleViewAppointmentsForListing = () => {
    this.setState({
      openStatusModal: false,
      statusModalType: ''
    });
  }

  _initAddListing = () => {
    this._selectView('add');
  }

  _onAssetUploadFailure = () => {
    this.setState({
      openStatusModal: false,
      statusModalType: 'listingCreateFailure'
    });
  }

  _onConfirmDeleteAppointment = () => {
    const { selectedAppointment } = this.state;
    const { deleteAppointment } = this.props;
    if (selectedAppointment) {
      this.setState({
        statusModalType: 'loading'
      }, () => {
        deleteAppointment(selectedAppointment);
      });
    }
  }

  _onConfirmDeleteListing = () => {
    if (this.orphanedListing) {
      const { _id, images } = this.orphanedListing;
      this.setState({
        statusDelete: false,
        statusLoading: true
      }, () => {
        this.props.deleteListing(_id);
        Promise.all(deleteAssets(images, this.orphanedListing.agentId))
          .then((res) => { console.log('Deleted files successfully', res) })
          .catch((err) => console.log('Error deleting files', err));
      });
    }
  }

  _onDeleteAppointment = (appointment) => {
    this.setState({
      openStatusModal: true,
      statusModalType: 'deleteAppointmentConfirm',
      selectedAppointment: appointment
    });
  }

  _onDeleteListing = (listing) => {
    this.orphanedListing = listing;
    this._toggleStatusModal('deleteListingConfirm', true);
  }

  _onDiscardDraft = () => {
    const { history } = this.props;
    this.draftURL = undefined;
    this._toggleStatusModal('PendingDraft', false);
    this.setState({
      currentListing: {
        _id: 'new',
        images: {},
        video: []
      }
    });//TODO: Add confirmation modal for discarding draft
    history.push(`/home?selectedView=add`);
  }

  _onDiscardDraftToAllowEdit = () => {
    const { listingToBeEdited, props } = this;
    const { history } = props;
    const { _id, images } = listingToBeEdited;
    history.push(`/home?selectedView=edit&listingId=${_id}`);

    this.setState({
      currentListing: {
        ...listingToBeEdited,
        ...{ images: objectifyImageList(images) }
      }
    });
    this._toggleStatusModal('PendingDraft', false);
  }

  _onEditAppointment = (appointment) => {
    this.setState({
      appointmentEditMode: true,
      openStatusModal: true,
      statusModalType: 'appointmentManage',
      selectedAppointment: appointment
    });
  }

  _onEditAppointmentSuccess = () => {
    this._handleCloseModal();
  }

  _onEditListing = (listing) => {
    const { history } = this.props;
    const { currentListing } = this.state;
    const { draftURL } = this;

    if (draftURL) {
      //Use whatever draft URL was there before
      //could be ?selectedView='add' or ?selectedView=edit&listingId=<someListingId>
      history.push(draftURL);

      if (
        (currentListing && currentListing._id !== listing._id) ||
        (currentListing && currentListing.id === 'new' && (Object.keys(currentListing.images).length > 0 || Object.keys(currentListing.video).length > 0))
      ) {
        //Only show dialog if they are different listing ids and if there's an existing draft
        this.listingToBeEdited = listing;
        this._onShowPendingDraftDialog();
      }
    }
    else {
      const { _id, images: listingImages } = listing;
      this.setState({
        currentListing: {
          ...listing,
          ...{ images: objectifyImageList(listingImages) }
        }
      });
      history.push(`/home?selectedView=edit&listingId=${_id}`);
    }
  }

  _onSelectAxis = (axis) => {
    this.setState({ axis: axis.value });
  }

  _onSelectType = (type) => {
    this.setState({ type });
  }

  _onShowPendingDraftDialog = () => {
    this.setState({
      openStatusModal: true,
      statusModalType: 'pendingDraft'
    });
  }

  _onVideoFileDialogCancel = () => {
    this.setState({ openVideo: false });
  }

  _onViewDraft = () => {
    const { history } = this.props;
    history.push(this.draftURL);
  }

  openVideoDialog = () => {
    this.setState({
      activeStep: 0,
      openStatusModal: false,
      statusVideo: false
    }, () => {
      this.setState({ openVideo: true })
    });
  }

  _openModal() {
    this.setState({ openStatusModal: true });
  }

  _openVideoStatusModal = (type) => {
    this.setState({
      openStatusModal: true,
      statusModalType: 'uploadVideoPrompt'
    });
  }

  openVideoModalBeforeUpload = (listing, agent) => {
    this.listing = listing;    //Temporarily save listing to a local variable
    this.agent = agent;
    this._openVideoStatusModal();
  }

  _processListingForUpload(listing, user) {
    const { location } = this.props;
    const selectedView = getSelectedView(location);
    if (user.new) {
      listing.firstTimeUser = true;
      listing.isFirstListing = true;
    }
    listing.agentId = user.uid;
    listing.creatorId = user.uid;
    this._toggleStatusModal('Loading', true);
    if (selectedView === 'edit') {
      this._uploadModifiedListing(listing, user);
    }
    else {
      this._uploadListing(listing);
    }
  }

  _routeToSignupPage(page) {
    const { history } = this.props;
    history.push(
      `/signup?sign_up=true&redirect_url=/home/agent?selectedView=${page}`
    );
  }

  _routeToMyAnalytics() {
    const { history } = this.props;
    history.push(
      `/home/agent?selectedView=dashboard#my-data`
    );
    this.$ref.scrollIntoView({
      behaviour: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }

  _routeToListingPage() {
    const { history, location } = this.props;
    const fromSignUp = QueryString.parse(location.search)['signup'] === "true";
    history.push(`/${fromSignUp ? 'new' : 'home'}?selectedView=listings${fromSignUp && '&signup=true'}`);
  }

  _routeToWhatsHotPage() {
    this._selectView('whatshot');
  }

  _routeToMessagesPage() {
    this._selectView('messages');
  }

  _runEarningsCounter() {
    const self = this;
    setInterval(() => {
      let { earningsCounter } = self.state;
      if (earningsCounter < AVERAGE_MONTHLY_EARNING) {
        self.setState({
          earningsCounter: earningsCounter + 521
        });
      }
      else {
        self.setState({
          earningsCounter: AVERAGE_MONTHLY_EARNING
        });
      }
    }, 30);
  }

  _scrollToTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  _selectView(selectedView) {
    const { authState, getListings, history, location } = this.props;
    const { currentListing, hasVisitedEarningsSection } = this.state;
    const { images, video } = currentListing;
    const fromSignUp = location.pathname.indexOf('new') !== -1;

    const currentlySelectedView = getSelectedView(location);

    if (selectedView === 'add') {
      this.setState({
        currentListing: {
          _id: 'new',
          description: '',
          fee: 0,
          images: {},
          location: '',
          lightCheck: false,
          roadCheck: false,
          video: [],
          waterCheck: false,
        }
      });
    }

    // Get draft url to go back to
    if (images && (Object.keys(images).length > 0 || Object.keys(video).length > 0) && (currentlySelectedView === 'edit' || currentlySelectedView === 'add')) {
      this.draftURL = `/${fromSignUp ? 'new' : 'home'}${location.search}`;
    }

    this._scrollToTop();

    //Run earnings counter only on first visit
    if (selectedView === 'earnings') {
      if (!hasVisitedEarningsSection) {
        this._runEarningsCounter();
        this.setState({ hasVisitedEarningsSection: true });
      } else {

      }
    } else {
      if (!hasVisitedEarningsSection) {
        this.setState({ earningsCounter: 0 });
      }
    }

    if (selectedView === 'listings' && authState && authState.user && authState.user.uid) {
      const userId = authState.user.uid;
      //Refresh listings
      getListings({ userId });
    }

    let url = `/${fromSignUp ? 'new' : 'home'}?selectedView=${selectedView}`;

    //What des this do
    if (TITLE[selectedView]) {
      this.props.endApartmentEdit();
      history.push(url);
    }
  }

  _setImageTitle(uid, _, e) {
    let name = e.target.value;
    let state = { ...this.state.images };
    state[uid].name = name;
    this.setState({ images: state });
  }

  _toggleStatusModal(modalType, status) {
    this.setState({
      openStatusModal: status,
      statusModalType: status ? modalType : ''
    });
  }

  _uploadAssets = (assets, listing, successCB, failureCB) => {
    Promise.all(uploadAssets(assets, listing.agent_id, new Date().valueOf()))
      .then(successCB)
      .catch(failureCB);
  }

  _uploadModifiedListing = (listing) => {
    const { currentListing, updateListing } = this.props;
    const { _id, images } = currentListing.data;

    if (_id) {
      const existingImages = objectifyImageList(images);
      const modifiedImages = { ...listing.images };
      const changedAssets = getAssetsDiff(existingImages, modifiedImages);
      const { toAdd: assetsToAdd, toDelete, doNothing: unmodifiedImages } = changedAssets;

      listing.images = Object.values(unmodifiedImages);

      //TODO: Find properties that need to be updated, no need for all
      this._uploadAssets(
        assetsToAdd,
        listing,
        (res) => {
          listing.images = listing.images.concat(res);
          listing.posterImage = listing.images[0] && listing.images[0].url;
          //listing.video = res.filter((asset) => asset.type === 'video'); //TODO: Tweak FOR VIDEO
          updateListing(listing, _id);
        },
        (e) => {
          console.log('Error uploading images', e);
          this._onAssetUploadFailure();
        });

      Promise.all(deleteAssets(toDelete, listing.agentId))
        .then((res) => { console.log('Deleted files successfully', res) })
        .catch((err) => console.log('Error deleting files', err));
    }
  }

  _uploadListing = (listing) => {
    const { images, video } = listing;
    const assetsList = Object.keys(images).map((uid) => images[uid])
    if (Object.keys(video).length > 0) {
      assetsList.push(video[Object.keys(video)[0]]);
    }
    this.uploadId = new Date().valueOf();
    this._uploadAssets(
      assetsList,
      listing,
      (res) => {
        listing.images = res;
        listing.posterImage = res[0] && res[0].url; //TODO: Work on this
        listing.video = res.filter((asset) => asset.type === 'video');;
        this.props.createListing(listing);
      },
      (e) => {
        console.log('Error uploading images', e);
        this._onAssetUploadFailure();
      });
  }

  _viewPage(page) {
    this.setState({ openStatusModal: false });
    this._selectView(page);
  }

  render() {
    const { appointments, dashboard, listings, location, windowWidth } = this.props;
    const {
      appointmentCreatedFor,
      appointmentEditMode,
      authState,
      currentListing,
      earningsCounter,
      images,
      openStatusModal,
      selectedView,
      selectedAppointment,
      selectedListing,
      statusModalType,
      statusVideo,
      video
    } = this.state;

    /** Styles **/
    const backgroundStyle = {
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain',
      height: '100%'
    };
    const closeModalBtnStyle = {
      position: 'absolute',
      color: 'lightgray',// : '#354052',
      cursor: 'pointer',
      visibility: 'visible',
      right: 16,
      top: 8,
      zIndex: 11222
    }
    const iconStyle = {
      fill: '#F2F2F2',
    }
    const activeIconStyle = {
      ...iconStyle,
      fill: '#F7BF23'
    }
    const modalBackgroundStyle = {
      background: statusModalType === 'loading' ? 'transparent' : 'white',
      maxWidth: 480,
    }
    const getIconStyle = (viewType) => {
      return getView === viewType ? activeIconStyle : iconStyle;
    };
    const getLabelStyle = viewType => {
      return getView === viewType ? { color: '#F7BF23' } : {};
    }
    const addIconStyle = (type) => {
      let plusIconStyle = { fontSize: 16, fontWeight: 700, marginRight: 8 };
      if (type === 'cta') {
        plusIconStyle = {
          ...plusIconStyle,
          fill: 'black'
        }
      }
      else {
        plusIconStyle = { ...plusIconStyle, fill: 'white' }
      }
      return plusIconStyle;
    }
    const modalBtnStyle = { fontSize: 18, height: 50, marginTop: '2rem' };

    const fromSignUp = location.pathname.indexOf('new') !== -1;
    let getView = getSelectedView(location) || selectedView;
    let isNewUser = false;
    let self = this;

    if (location.state)
      isNewUser = location.state && location.state.newUser;
    if (!TITLE[getView]) {
      getView = 'dashboard'
    }

    const displayInfoNuggets = (array, hover) => {
      return array.map((item, index) =>
        <InfoNugget item={item} key={index} hover={hover} theme={item.theme}></InfoNugget>
      )
    };

    /******************
     * DATA SELECTORS * 
    *******************/

    /** Appointment Selectors */
    const getAppointmentsForListing = (listingId) => {
      const appointmentsForListing = (appointments.data && appointments.data.length > 0) ? appointments.data.filter((appointment) => listingId === appointment.listingId) : [];
      return appointmentsForListing;
    }

    const getLatestAppointmentForListing = (listing) => {
      const appointmentsForListing = getAppointmentsForListing(listing._id);
      return appointmentsForListing.length > 0 ? { ...appointmentsForListing[0], ...{ listingStatus: listing.status } } : null;
    }

    const nextAppointmentToString = () => {
      if (appointments.data && appointments.data.length > 0) {
        const appointment = appointments.data[0];
        return `${getDayFromISOString(appointment.isoDateTime)} ${getMonthFromISOString(appointment.isoDateTime)},
        ${getYearFromISOString(appointment.isoDateTime)}`;
      }
      return 'N/A';
    }

    /** Listing Selectors */
    const selectedListingId = getLocationQueryParamValue(location, 'listingId');
    let hasListings = listings.data && listings.data.length > 0;
    const listingById = (id) => {
      if (hasListings && id) {
        const x = listings.data.filter((listing) => listing._id === id)
        return x[0]
      }
      return null;
    }
    const isDraft = Object.keys(currentListing.images).length > 0;
    const { appointment, earning, listing } = dashboard.data;

    /****************************
     * THE DIFFERENT PANEL/VIEWS  
     ****************************/
    const dashboardView = () => {
      const userName = authState && authState.user && authState.user.name;
      if (authState && authState.user && authState.user.uid) {
        if (!hasListings) {
          //First time User or User with No Listings
          return (
            <div>
              <section className='content-container flex-row-spacebetween color-text' style={{ alignItems: 'center' }}>
                <div className={'dashboard-header'}>
                  <h3 className='page-header'>
                    {
                      isDraft
                        ?
                        'One step away from earning'
                        :
                        isNewUser
                          ?
                          `Welcome onboard, ${userName || 'Agent'}`
                          :
                          `Welcome back, ${userName || 'Agent'}`
                    }
                  </h3>
                  <p className='paragraph-s'>
                    {
                      isDraft
                        ?
                        'Click below to complete your listing draft'
                        :
                        isNewUser
                          ?
                          'You are the next to earn. Now, let\'s get you started!'
                          :
                          'Are you ready to earn?'
                    }
                  </p>
                </div>
              </section>
              {
                isDraft
                &&
                <div className='draft-icon-root margin-t48' onClick={this._onViewDraft}>
                  <Draft alt='Complete listing draft' className='draft-icon' style={{ fontSize: '5.5rem' }} />
                </div>
              }
              <section className={`content-container width-p100 margin-t48`}>
                {
                  displayInfoNuggets([
                    {
                      label: YOUR_CURRENT_EARNING,
                      theme: 'blue',
                      type: 'payout',
                      value: 'N0.00'
                    },
                    {
                      label: YOUR_NEXT_EARNING,
                      theme: 'coral',
                      type: 'maxpay',
                      value: `N${AVERAGE_EARNING_PER_LISTING}.00`
                    },
                    {
                      label: 'Your Listings',
                      theme: 'purple',
                      type: 'listing',
                      value: 0
                    },
                  ])
                }
              </section>
              <section className={`content-container width-p100 margin-r8 homepage-data-chart`}>
                <figure className={'analytics-two-root flex-column'}>
                  <p className='paragraph-s ta-center'>
                    {
                      isDraft
                        ?
                        'Continue your listing and start earning'
                        :
                        'Create your first listing and start earning'
                    }
                  </p>
                  <div className={`content-container width-p100 flex-center margin-t32`}>
                    <button className='button cta' onClick={this._initAddListing}
                      aria-label='Create First Listing' tabIndex={0}>
                      <Add style={addIconStyle('cta')} />
                      Create Listing
                    </button>
                  </div>
                </figure>
              </section>
            </div>
          )
        }
        else {
          return (
            <div>
              <section className='content-container flex-row-spacebetween' style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className={'dashboard-header'}>
                  <h3 className='page-header'>{`Welcome back, ${userName || 'Agent'}`}</h3>
                  <p className='paragraph-s'>Here's how your listings are performing today</p>
                </div>
                {/* <div className={`addlisting-button primary display-mobile-none`} onClick={this._viewPage.bind(this, 'listings')}
                  role='button' aria-label='View My Listings' tabIndex={0}>
                  My Listings
                </div> */}
              </section>

              <h3 className='paragraph-s margin-r8 fw-600  margin-t48'>Overall Summary</h3>
              <section className={'content-container width-p100'}>
                {
                  displayInfoNuggets([
                    {
                      label: 'Next Earning',
                      onClick: this._handleClickInfoNugget.bind(this, 'earnings'),
                      theme: 'coral',
                      type: 'payout',
                      value: `N${earning.amount}.00`,
                    },
                    {
                      label: `Next Appointment`,
                      onClick: this._handleClickInfoNugget.bind(this, 'earnings'),
                      theme: 'gray',
                      type: 'calendar',
                      value: appointment.date,
                    },
                    {
                      label: 'Best Performing Listing',
                      onClick: this._handleClickInfoNugget.bind(this, 'earnings'),
                      theme: 'green',
                      type: 'listing',
                      value: listing.location,
                    },
                  ], true)
                }
              </section>

              <section className={`flex-column margin-b32  margin-t48`}>
                <h3 className='paragraph-s margin-r8 fw-600'>Listings Overview</h3>
                <div className={'homepage-data-overview'}>
                  <div className={`content-container width-p100`}>
                    {/* <div className='info-nugget'
                      onClick={this._routeToListingPage.bind(this)}>
                      <Listings style={{ fontSize: 20, fill: '#49ACF4', marginRight: 4 }} />
                      <span className='fw-300'>4&nbsp;</span> listings
                    </div> */}
                    <div className='info-nugget'
                      onClick={this._routeToListingPage.bind(this)}>
                      <Likes style={{ fontSize: 20, fill: 'red', marginRight: 4 }} />
                      <span className='fw-300'>{listing.likes}&nbsp;</span> likes
                    </div>
                    <div className='info-nugget'
                      onClick={this._routeToListingPage.bind(this)}>
                      <Viewed style={{ fontSize: 20, fill: '#8CD018', marginRight: 4 }} />
                      <span className='fw-300'>{listing.views}&nbsp;</span> views
                    </div>
                    <div className='info-nugget'
                      onClick={this._routeToMessagesPage.bind(this)}>
                      <Message style={{ fontSize: 20, fill: 'gray', marginRight: 4 }} />
                      <span className='fw-300'>{listing.messages}&nbsp;</span>messages
                    </div>
                  </div>
                </div>
              </section>

              {
                /*<section ref={ref => {
                this.$ref = ref;
              }}
                className={`content-container-sb width-p100 margin-r8`}>
                <WhatsHotView
                  type='Views'
                  title='Listing Views Timeline'
                  lineData={this.state.lineData}
                  pieData={this.state.pieData} />
              </section>
              */
              }
            </div>
          );
        }
      }
      else {
        //Unauthenticated Dashboard display
        return (
          <div>
            <section className='content-container flex-row-spacebetween color-text' style={{ alignItems: 'center' }}>
              <div className='width-p100' style={fromSignUp ? {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexDirection: 'row'
              } : { width: '100%' }}>
                {
                  fromSignUp ?
                    <div className={'dashboard-header'}>
                      <h3 className='page-header'>Be the next to earn.</h3>
                      <p className='paragraph-s'>{`Join ${TOTAL_NUM_AGENTS} agents currently earning daily on this platform.`}</p>
                    </div>
                    :
                    <div className={'dashboard-header'}>
                      <h3 className='margin-r8 fw-600'>
                        Hello Agent, here's what users are searching for
                      </h3>
                      <p className='paragraph-s'>
                        Click on any to see a detailed search summary
                      </p>
                    </div>
                }
              </div>
            </section>

            {
              //fromSignUp ?
              <section className={`content-container width-p100 margin-t48`}>
                {
                  displayInfoNuggets([
                    {
                      label: AVERAGE_EARNING_PER_LISTING_STR,
                      theme: 'coral',
                      type: 'payout',
                      value: 'N6,230'
                    },
                    {
                      label: 'Highest Earning so far',
                      theme: 'green',
                      type: 'maxpay',
                      value: `N105,000`
                    },
                    {
                      label: 'Listings Uploaded per Month',
                      theme: 'blue',
                      type: 'listing',
                      value: `208`
                    },
                  ])
                }
              </section>
              // :
              // <section className={`content-container width-p100 margin-t48`}>
              //   {
              //     displayInfoNuggets([
              //       {
              //         label: 'Most Searched Type',
              //         onClick: this._handleClickInfoNugget,
              //         theme: 'blue',
              //         type: 'listing',
              //         value: '1-Bedroom Apartment'
              //       },
              //       {
              //         label: 'Most Searched Location',
              //         onClick: this._handleClickInfoNugget,
              //         value: 'Aka/Abak Road',
              //         theme: 'coral',
              //         type: 'location'
              //       },
              //       {
              //         label: 'Total Searches',
              //         onClick: this._handleClickInfoNugget,
              //         type: 'search',
              //         theme: 'gray',
              //         value: '420'
              //       },
              //     ], true)
              //   }
              // </section>
            }

            <section className={'content-container width-p100 margin-r8 homepage-data-chart'}>
              <figure className={`flex-column-center width-p100`}>
                <div className='flex-column-center'>
                  <p className='paragraph-s ta-center'>
                    {
                      fromSignUp ? 'Create your first listing and start earning today' :
                        'Login to see how your listings are performing'}
                  </p>
                </div>
                <div className={`content-container width-p100 margin-t16 flex-center`}>
                  {
                    fromSignUp ?
                      <button className='button cta'
                        onClick={this._initAddListing} aria-label='Add Listing' tabIndex={0}>
                        <Add style={addIconStyle('cta')} />
                        Create Listing
                      </button>
                      :
                      <button className='button regular'
                        onClick={this._viewPage.bind(this, 'listings')} aria-label='View My Listings' tabIndex={0}>
                        {
                          windowWidth > 480 ? `Login to Continue` : `Login`
                        }
                      </button>
                  }
                </div>
              </figure>
            </section>
          </div>
        );
      }
    }

    const earningsView = () => {
      if (authState && authState.user && authState.user.uid) {
        return (
          <div>
            <section className='content-container flex-row-spacebetween color-text' style={{ alignItems: 'center' }}>
              {
                <h3 className='margin-r8 fw-700 center-text-mobile'>
                  {`My Earnings`}
                </h3>
              }
            </section>
            <section className={`content-container width-p100 margin-t48`}>
              {
                displayInfoNuggets([
                  {
                    label: 'Next Earning',
                    onClick: self._handleClickInfoNugget.bind(this, 'payout'),
                    theme: 'coral',
                    type: 'payout',
                    value: 'N0.00',
                  },
                  {
                    label: `Date of Next Earning`,
                    onClick: self._handleClickInfoNugget.bind(this, 'payout'),
                    theme: 'gray',
                    type: 'calendar',
                    value: `13/12/2019`,
                  },
                  {
                    label: `Total Payout`,
                    onClick: self._handleClickInfoNugget.bind(this, 'payout'),
                    theme: 'green',
                    type: 'payout',
                    value: `N12,100`,
                  },
                ])
              }
            </section>
          </div>
        )
      }
      else {
        return (
          <div>
            <div className={'dashboard-header'}>
              <h3 className='page-header'>Earn extra N<span className='color-accent'>{earningsCounter}</span> per month.</h3>
              {
                earningsCounter === AVERAGE_MONTHLY_EARNING ?
                  <p className='paragraph-s'>
                    That's the average you could earn by listing with us but you could earn even more!{/*<br /> Here's an earnings summary so far:*/}
                  </p>
                  : <p className='paragraph-s'></p>
              }
            </div>
            <section className={`content-container width-p100 margin-t48`}>
              {
                displayInfoNuggets([
                  {
                    label: MAXIMUM_EARNED_PER_LISTING,
                    theme: 'purple',
                    type: 'maxpay',
                    value: `N105,000`
                  },
                  {
                    label: AVERAGE_EARNING_PER_LISTING_STR,
                    theme: 'earn',
                    type: 'payout',
                    value: 'N6,230'
                  },
                  {
                    label: 'Total Earnings so far',
                    onClick: self._handleClickInfoNugget,
                    theme: 'green',
                    type: 'total',
                    value: `N602,300`
                  },
                ])
              }
            </section>
            <section className={`flex-column-center width-p100`}>
              <div className='flex-column-center'>
                <p className='paragraph-s ta-center'>
                  {
                    fromSignUp ? `Click button below to get started and start earning` :
                      `Click button below to login and view your earnings`}
                </p>
              </div>
              <div className={`content-container width-p100 flex-center margin-t32`}>
                {
                  fromSignUp ?
                    <button className='button cta'
                      onClick={this._initAddListing} aria-label='Add Listing' tabIndex={0}>
                      <Add style={addIconStyle('cta')} />
                      Create Listing
                    </button>
                    :
                    <button className='button regular' onClick={this._viewPage.bind(this, 'listings')}
                      aria-label='View My Listings' tabIndex={0}>
                      {
                        windowWidth > 480 ? `Login to Continue` : `Login`
                      }
                    </button>
                }
              </div>
            </section>
          </div>
        )
      }


    }

    const listView = () => {
      const userName = authState && authState.user && authState.user.name;
      if (authState && authState.user && authState.user.uid) {
        if (!hasListings) {
          //First time User or User with No Listings
          return (
            <div>
              <section className='content-container flex-row-spacebetween color-text' style={{ alignItems: 'center' }}>
                <div className={'dashboard-header'}>
                  <h3 className='page-header'>{`Welcome ${isNewUser ? 'onboard' : 'back'}, ${userName || 'Agent'}`}</h3>
                  <p className='paragraph-s'>
                    {
                      isNewUser
                        ?
                        "You are next to earn. Now, let's get you started!"
                        :
                        'You have no listings yet'
                    }
                  </p>
                </div>
              </section>

              <section className={`content-container width-p100 margin-t32`} style={{ flexDirection: 'column' }}>
                <div className='empty-layout'>
                  <Empty aria-label='No listings state' alt='No listings state' style={{ fontSize: 96, color: 'lightgray' }} />
                  <p className='section-subheader fw-600'>Are you ready to earn today?</p>
                  <p className='ta-center empty-layout-subheader'>Create your first listing today</p>
                  <button className='button cta'
                    style={{ marginTop: '3rem' }}
                    onClick={this._initAddListing} aria-label='Add Listing' tabIndex={0}>
                    <Add style={addIconStyle('cta')} />
                      Create First Listing
                    </button>
                </div>
              </section>
            </div>
          )
        }
        else {
          return (
            <section className={'content-container width-p100'} style={{ flexDirection: 'column' }}>
              <div className={'dashboard-header'}>
                <h3 className='page-header'>My Listings</h3>
                <p className='paragraph-s' />
              </div>
              <section className={'listings-root margin-t8 margin-b32'}>
                {
                  listings.data.map((listing) =>
                    <ListingListItem
                      key={listing._id}
                      listing={listing}
                      appointmentCreatedFor={appointmentCreatedFor}
                      onCalendarClick={this._handleCalendarClick}
                      onDelete={this._onDeleteListing}
                      onEdit={this._onEditListing}
                    />
                  )
                }
              </section>
            </section>
          );
        }
      }
      else {
        return (
          <div className='width-p100 bg-circles flex-column-center padding-tb16'>
            <div className={'dashboard-header'}>
              <h3 className='page-header'>{fromSignUp ? 'Your listings should be here' : 'Hey there, missing your listings?'}</h3>
              <p className='paragraph-s'>{fromSignUp ? 'But you have no listings yet' : 'Login to see how they\'re doing'}</p>
            </div>
            {
              fromSignUp
                ?
                <section className={`content-container width-p100 margin-t32`} style={{ flexDirection: 'column' }}>
                  <div className='empty-layout'>
                    <Empty aria-label='No listings state' alt='No listings state' style={{ fontSize: 96, color: 'lightgray' }} />
                    <p className='section-subheader fw-600'>{`Join ${TOTAL_NUM_AGENTS} agents on the platform`}</p>
                    <p className='ta-center empty-layout-subheader'>Create your first listing today</p>
                    <button className={`button ${fromSignUp ? 'cta' : 'regular'}`}
                      style={{ marginTop: '3rem' }}
                      onClick={this._initAddListing} aria-label='Add Listing' tabIndex={0}>
                      <Add style={addIconStyle(fromSignUp ? 'cta' : 'regular')} />
                      Create First Listing
                    </button>
                  </div>
                </section>
                :
                <AuthForm
                  embed={true}
                  type={fromSignUp ? 'signup' : 'login'}
                />
            }
          </div>
        );
      }
    }

    const editListingView = () => {
      return (
        <ListingUploadForm
          role='agent'
          currentListing={currentListing}
          images={images}
          video={video}
          onAutoSave={() => { }}
          onDiscardDraft={() => { }}
          onUpload={() => { }}
          openVideo={this.state.openVideo}
          openVideoModal={() => { }}
          onVideoFileDialogCancel={() => { }}
        />
      )
    };

    const appointmentsView = () => {
      const hasAppointments = appointments.data && appointments.data.length > 0;
      // if (listings.data && listings.data.length > 0 && appointments.data && appointments.data.length > 0) {
      //   listingWithAppointments = listings.data.filter((listing) => listing._id === appointments.data[0].listingId)
      // }
      const selectedListing = listingById(selectedListingId);
      const appointmentsForListing = getAppointmentsForListing(selectedListingId);
      const mostRecentAppointment = selectedListing ? appointmentsForListing[0] : hasAppointments && appointments.data[0];
      //TODO: Refactor appointmentList to not use map
      const appointmentList = appointmentsForListing.length > 0 ? appointmentsForListing : hasAppointments && appointments.data || [];
      let numOfAttendees;
      if (mostRecentAppointment) {
        numOfAttendees = mostRecentAppointment.attendees || [];
      }

      if (true || fromSignUp) {//if logged in
        return (
          <section className='flex-row-spacebetween color-text' style={{ alignItems: 'center' }}>
            <section className={`width-p100`}>
              <div>
                <h3 className='margin-r8 fw-700 page-header'>
                  {selectedListing ? `Appointments for ${selectedListing.location}` : 'My Appointments'}
                </h3>
              </div>
              {
                mostRecentAppointment
                  ?
                  <section className={`content-container width-p100 margin-t32`} style={{ flexDirection: 'column' }}>
                    <div className='margin-t16'>
                      <h3 className='section-subheader'>Upcoming Appointment</h3>
                    </div>
                    <div className='calendar-container'>
                      <div className='calendar-root'>
                        <div className='month'>{getMonthFromISOString(mostRecentAppointment.isoDateTime)}</div>
                        <div className='day'>{getDayFromISOString(mostRecentAppointment.isoDateTime)}</div>
                      </div>
                      <div className='bg-white flex-column ta-left'>
                        <p className=''><span className='fw-600'>Venue:</span> {mostRecentAppointment.location}</p>
                        <p className=''><span className='fw-600'>Time:</span> {moment(mostRecentAppointment.isoDateTime).format('LT')}</p>
                        {
                          mostRecentAppointment.isVerification
                            ? <p className=''><span className='fw-600'>Type:</span> Verification</p>
                            : <p className=''><span className='fw-600'>Number of Renters:</span> {numOfAttendees.length}</p>
                        }
                      </div>
                    </div>
                    <div className='margin-t48'>
                      <h3 className='section-subheader'>All Appointments</h3>
                    </div>
                    <section className={'listings-root margin-t8 margin-b32'}>
                      {
                        appointmentList.map((appointment) =>
                          <AppointmentListItem
                            key={appointment._id}
                            appointment={appointment}
                            onDelete={this._onDeleteAppointment}
                            onEdit={this._onEditAppointment}
                          />
                        )
                      }
                    </section>
                  </section>
                  :
                  <section className={`content-container width-p100 margin-t32`} style={{ flexDirection: 'column' }}>
                    <div className='empty-layout'>
                      <Empty aria-label='No appointments state' alt='No appointments state' style={{ fontSize: 96, color: 'lightgray' }} />
                      <p className='section-subheader fw-600'>You don't have any upcoming appointments</p>
                      <p className='empty-layout-subheader ta-center'>Go to your listings page to create an appointment</p>
                      <button className='button cta'
                        aria-label='View Listings'
                        style={{ marginTop: '3rem' }}
                        onClick={this._selectView.bind(this, 'listings')}
                        tabIndex={0}>
                        View My Listings
                    </button>
                    </div>
                  </section>
              }
            </section>
          </section >
        );
      }
      else {
        return (
          <div className='width-p100 flex-column-center'>
            <h3 className='margin-r8 fw-600 ta-center'>
              Please login to view your appointments
            </h3>
            <AuthForm
              userType={'agent'}
              embed={true} type={'login'} />
          </div>
        );
      }
    }

    const messageView = () => {
      if (authState && authState.user && authState.user.email) {
        return (
          <div className='width-p100'>
            <h3 className='margin-r8 fw-400'>
              My Messages
            </h3>
            <section className={'listings-root margin-tb32'}>
            </section>
          </div>
        );
      }
      else {
        return (
          <div className='width-p100 flex-column-center'>
            <h3 className='margin-r8 fw-600 ta-center'>
              {
                fromSignUp
                  ? 'Please signup to continue'
                  : 'Please login to view your messages'
              }
            </h3>
            <AuthForm
              userType={'agent'}
              embed={true} type={fromSignUp ? 'signup' : 'login'} />
          </div>
        );
      }
    };

    const settingsView = () => {
      if (authState && authState.user && authState.user.email) {
        return (
          <div className='width-p100'>
            <h3 className='margin-r8 fw-400'>
              Settings and Preferences
            </h3>
            <section className='listings-root margin-tb32'>
              <p>Show data in figures VS in Chart</p>
              <p>TTurn off Email Notifications</p>
              <p>Turn off SMS Notifications</p>
            </section>
          </div>
        );
      }
      else {
        return (
          <div className='width-p100 flex-column-center'>
            <h3 className='margin-r8 fw-600 ta-center'>
              {
                fromSignUp
                  ? 'Please signup to continue'
                  : 'Please login to continue'
              }
            </h3>
            <AuthForm
              userType={'agent'}
              embed={true} type={fromSignUp ? 'signup' : 'login'} />
          </div>
        );
      }
    }

    const whatsHotView = () => {
      if (authState && authState.user && authState.user.email) {
        return (
          <WhatsHotView lineData={this.state.lineData}
            pieData={this.state.pieData} />
        );
      }
      else {
        return (
          <div className='width-p100 flex-column-center'>
            <h3 className='margin-r8 fw-600 ta-center'>
              {
                fromSignUp
                  ? 'Please signup to get a detailed search trend analysis'
                  : 'Please login to get a detailed search trend analysis'
              }
            </h3>
            <AuthForm
              userType={'agent'}
              embed={true} type={fromSignUp ? 'signup' : 'login'} />
          </div>
        );
      }
    }

    const statusModalContent = () => {/** TODO: Refactor and move this to HAModal */
      if (statusModalType === 'loading') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center padding-64 br-4 color-text'>
            <CircularProgress style={{ color: '#8CD018', height: 72, width: 72 }} />
          </div>
        )
      }
      else if (statusModalType === 'appointmentCreateSuccess') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center padding-32 br-4 color-text'>
            <CheckCircle style={{ fontSize: '5rem', fill: '#05CD75' }} />
            <p className='section-subheader ta-center'>Appointment created succesfully.</p>
            <button className='button regular margin-t32 btn-cta-main btn-l'
              style={modalBtnStyle}
              onClick={this._viewPage.bind(this, 'appointments')}
              aria-label='View my apointments' type='button'>
              My Appointments
            </button>
          </div>
        )
      }
      else if (statusModalType === 'listingCreateSuccess') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center padding-32 br-4 color-text'>
            <CheckCircle style={{ fontSize: '5rem', fill: '#05CD75' }} />
            <p className='modal-header margin-b16'>Upload Success!</p>
            {
              (this.state.authState && this.state.authState.user && this.state.authState.user.new)
                ?
                <p className='ta-center'>
                  Hooray! <span className='color-accent fw-500'>A</span>-Agent, your have successfully created your first listing.
                  Now sit back and let us do the work.
              </p>
                :
                <p className='ta-center'>
                  Congrats! Your listing has been uploaded successfully.
              </p>
            }

            <p className='ta-center'>Within 72 hours, we will contact you so we can verify the listing; after which you can smile to the bank.</p>
            <button className='button cta margin-t32'
              style={modalBtnStyle}
              onClick={this._viewPage.bind(this, 'listings')}
              aria-label='View Listings' type='button'>
              View My Listings
            </button>
          </div>
        )
      }
      else if (statusModalType === 'listingCreateFailure') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center padding-32 br-4 color-text'>
            <Error style={{ fontSize: '5rem', fill: '#FFA3A3' }} />
            <p className='modal-header'>Oopsie!</p>
            <p className='ta-center'>
              We're sorry, an error occurred with your upload
            </p>
            <p className='ta-center' style={{ marginTop: -8 }}>
              You can please try again now or later.
            </p>
            <div className='flex-row-spacearound width-p100 margin-t32'>
              <button className='button draft'
                style={modalBtnStyle}
                onClick={this._handleCloseModal}
                aria-label='Cancel' type='cancel'>
                Cancel
              </button>
              <button className='button cta'
                style={modalBtnStyle}
                onClick={this._handleTryUploadAgain}
                aria-label='Try Upload Again' type='button'>
                Try Again
              </button>
            </div>
          </div>
        )
      }
      else if (statusModalType === 'uploadVideoPrompt') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center padding-32 br-4 '>
            <UploadVideo style={{ fontSize: '5rem', fill: '#F7BF23' }} />
            <p className='modal-header'>Earn more with video</p>
            <p className='ta-center'>
              Add a video of this listing to earn extra N5000*
            </p>
            <div className='flex-row-spacearound width-p100 margin-t32'>
              <button className='button draft'
                style={modalBtnStyle}
                onClick={this._cancelVideoUploadSuggestion}
                aria-label='Cancel Video Upload' type='cancel'>
                Later
              </button>
              <button className='button cta'
                style={modalBtnStyle}
                onClick={this.openVideoDialog}
                aria-label='Upload Video' type='button'>
                Yes Now
              </button>
            </div>
          </div>
        )
      }
      else if (statusModalType === 'deleteListingConfirm') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center br-4'>
            <p className='modal-header appointment-modal-header'>Confirm Listing Delete</p>
            <div className='flex-column-center modal-content br-4'>
              <p className='ta-center' style={{ marginTop: '.5rem' }}>
                Are you sure you want to delete this listing?
            </p>
              <p className='ta-center'>
                Deleting this listing deletes all images uploaded, existing appointments and
                any earnings associated this listing.
            </p>
              <p className='ta-center'>
                <b>This action cannot be reversed.</b>
              </p>
              <div className='flex-row-spacearound width-p100'>
                <button className='button draft'
                  style={modalBtnStyle}
                  onClick={this._cancelDeleteListing.bind(this)}
                  aria-label='Cancel' type='cancel'>
                  Cancel
              </button>
                <button className='button cta'
                  style={modalBtnStyle}
                  onClick={this._onConfirmDeleteListing.bind(this)}
                  aria-label='Delete listing confirm' type='button'>
                  Delete
              </button>
              </div>
            </div>
          </div>
        );
      }
      else if (statusModalType === 'pendingDraft') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center br-4'>
            <p className='modal-header appointment-modal-header'>Pending Draft</p>
            <div className='flex-column-center modal-content br-4'>
              <p className='ta-center' style={{ marginTop: '1rem' }}>
                You have a pending listing draft. Do you want to discard it?
            </p>
              <p className='ta-center' style={{ marginTop: -8 }}>
                This action cannot be reversed.
            </p>
              <div className='flex-row-spacearound width-p100 margin-t32'>
                <button className='button draft'
                  style={modalBtnStyle}
                  onClick={this._cancelDiscardDraft}
                  aria-label='Cancel' type='cancel'>
                  Cancel
              </button>
                <button className='button cta'
                  style={modalBtnStyle}
                  onClick={this._onDiscardDraftToAllowEdit}
                  aria-label='Delete listing confirm' type='button'>
                  Discard
              </button>
              </div>
            </div>
          </div>
        );
      }
      else if (statusModalType === 'appointmentManage') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center br-4'>
            <AppointmentScheduler
              editMode={appointmentEditMode}
              existingAppointment={selectedAppointment || getLatestAppointmentForListing(selectedListing)}
              onSubmit={this._handleScheduleAppointment}
              onViewAll={this._handleViewAppointmentsForListing}
            />
          </div>
        );
      }
      else if (statusModalType === 'deleteAppointmentConfirm') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center br-4'>
            <p className='modal-header appointment-modal-header'>Confirm Deletion</p>
            <div className='flex-column-center modal-content br-4'>
              <p className='ta-center' style={{ marginTop: '.5rem' }}>
                Are you sure you want to delete this appointment?
            </p>
              {/* <p className='ta-center'>
              Deleting this appointment deletes all possible earnings from renters.
            </p> */}
              <p className='ta-center'>
                <b>This action cannot be reversed.</b>
              </p>
              <div className='flex-row-spacearound width-p100'>
                <button className='button draft'
                  style={modalBtnStyle}
                  onClick={this._cancelDeleteAppointment}
                  aria-label='Cancel' type='cancel'>
                  Cancel
              </button>
                <button className='button cta'
                  style={modalBtnStyle}
                  onClick={this._onConfirmDeleteAppointment}
                  aria-label='Delete appointment confirm' type='button'>
                  Delete
              </button>
              </div>
            </div>
          </div>
        );
      }
      else if (statusModalType === 'appointmentDeleteFailure') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center br-4'>
            <div className='modal-header appointment-modal-header'>
              <Error style={{ fontSize: '44', fill: 'white' }} />
              <p className='modal-header'>Oopsie!</p>
            </div>
            <div className='modal-content'>
              <p className='ta-center'>
                We're sorry, an error occurred with your upload
            </p>
            </div>
          </div>
        )
      }
      else if (statusModalType === 'appointmentDeleteSuccess' || statusModalType === 'listingDeleteSuccess') {
        return (
          <div style={modalBackgroundStyle} role='alert'
            className='flex-column-center br-4'>
            <div className='modal-header appointment-modal-header'>
              <CheckCircle aria-label='Success' style={{ fontSize: 44, fill: '#white' }} />
              <p className='modal-header'>Delete Success</p>
            </div>
            <div className='flex-column-center modal-content br-4'>
              <p className='ta-center'>{
                statusModalType === 'appointmentDeleteSuccess'
                  ? 'Appointment'
                  : 'Listing'
              } has been deleted succesfully</p>
              <button className='button cta margin-t32'
                style={modalBtnStyle}
                onClick={this._handleCloseModal}
                aria-label='Close modal' type='button'>
                OK
            </button>
            </div>
          </div>
        );
      }
    }

    /** Layouts */
    const leftNav = () =>
      <nav className={'left-nav flex-column-horizontal-center bg-universal'}
        aria-label={'Left Navigation'} >

        <div className='icon-button-root' tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'dashboard')}>
          <Dashboard aria-label='Dashboard' alt='Dashboard'
            className={'icon-button'} style={getIconStyle('dashboard')} />
          <p className='display-mobile-none' style={getLabelStyle('dashboard')}>Home</p>
        </div>

        <div className='icon-button-root' tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'add')}>
          {
            getView === 'edit' ?
              <div>
                <Edit aria-label='Edit Listing' alt='Edit Listing'
                  className={'icon-button'} style={getIconStyle('edit')} />
                <p className='display-mobile-none' style={getLabelStyle('edit')}>Edit Listing</p>
              </div>
              :
              <div>
                <Add aria-label='Add Listing' alt='Add Listing'
                  className={'icon-button'} style={getIconStyle('add')} />
                <p className='display-mobile-none' style={getLabelStyle('add')}>Add Listing</p>
              </div>
          }
        </div>

        <div className='icon-button-root' tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'listings')}>
          <Listings aria-label='Listings' alt='View Listings'
            className={'icon-button'} style={getIconStyle('listings')} />
          <p className='display-mobile-none' style={getLabelStyle('listings')}>My Listings</p>
        </div>

        <div className='icon-button-root' tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'earnings')}>
          <Pay aria-label='View Earnings' alt='View Earnings'
            className={'icon-button icon-pay'} style={getIconStyle('earnings')} />
          <p className='display-mobile-none' style={getLabelStyle('earnings')}>My Earnings</p>
        </div>

        <div className='icon-button-root' tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'appointments')}>
          <Calendar aria-label='Appointments' alt='My Appointments'
            className={'icon-button'} style={getIconStyle('appointments')} />
          <p className='display-mobile-none' style={getLabelStyle('appointments')}>My Appointments</p>
        </div>

        {/*<div className='icon-button-root' tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'messages')}>
          <Message aria-label='Messages' alt='View Messages'
            className={'icon-button'} style={getIconStyle('messages')} />
          <p className='display-mobile-none' style={getLabelStyle('messages')}>My Messages</p>
        </div>

      <div className={`${classes['icon-button-root']}`} tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'whatshot')}>
          <WhatsHot aria-label='Hot Searches' alt='Hot Searches'
            className={`${classes['icon-button']}`} style={getIconStyle('whatshot')} />
        </div>

        <div className='icon-button-root' tabIndex={0} role='button'
          onClick={this._selectView.bind(this, 'settings')} onKeyPress={this._handleKeyPress.bind(this, 'settings')}>
          <Settings aria-label='Settings' alt='Settings'
            className='icon-button' style={getIconStyle('settings')} />
          <p className='display-mobile-none' style={getIconStyle('settings')}>Settings</p>
        </div>*/}
      </nav>;

    const viewControllerMap = {
      'dashboard': dashboardView,
      'listings': listView,
      'earnings': earningsView,
      'messages': messageView,
      'add': editListingView,
      'appointments': appointmentsView,
      'edit': editListingView,
      'settings': appointmentsView,
      'whatshot': whatsHotView
    }

    return (
      <main className='bg-houses width-p100' style={backgroundStyle}>
        <Helmet title={
          authState && authState.user ?
            `Home - ${TITLE[getView]} | Home Attlas` :
            fromSignUp ?
              `Agent Onboarding - ${TITLE[getView]} | Home Attlas` :
              `Login to continue - ${TITLE[getView]} | Home Attlas`
        } />
        <div className={'homepage-root'}>
          <Modal open={openStatusModal}
            closeBtnStyle={statusModalType === 'loading' ? { display: 'none' } : closeModalBtnStyle}
            onClose={this._handleCloseModal}>
            {statusModalContent()}
          </Modal>
          <div className='flex height-p100' style={{ position: 'relative' }}>
            {leftNav()}
            <main className={'homepage-main color-text bg-lightgray'}>
              <noscript>
                <Snackbar>
                  You will need to enable Javascript to enjoy full functionality of this page.
                    Here are the <a href="http://www.enable-javascript.com" target="_blank" rel="noopener noreferrer">
                    instructions on how to enable JavaScript in your browser</a>
                </Snackbar>
              </noscript>
              <div className='mw-1280 margin-auto'>
                {viewControllerMap[getView]()}
              </div>
            </main>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ appointments, authState, aptDraftState, currentListing, dashboard, listings, navBarDisplay, windowWidth }) => {
  return {
    appointments,
    aptDraftState,
    authState,
    currentListing,
    dashboard,
    listings,
    navBarDisplay,
    windowWidth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveApartmentDraft: (aptObj) => dispatch(saveApartmentDraft(aptObj)),
    endApartmentEdit: () => dispatch(endApartmentEdit()),
    createListing: (aptObj) => dispatch(createListing(aptObj)),
    createAppointment: (obj) => dispatch(createAppointment(obj)),
    deleteAppointment: (obj) => dispatch(deleteAppointment(obj)),
    deleteListing: (listingId) => dispatch(deleteListing(listingId)),
    editAppointment: (appointment, appointmentId) => dispatch(editAppointment(appointment, appointmentId)),
    getAppointments: (queryOpts) => dispatch(getAppointments(queryOpts)),
    getDashboardData: (queryOpts) => dispatch(getDashboardData(queryOpts)),
    getListings: (queryOpts) => dispatch(getListings(queryOpts)),
    toggleNavBar: (status) => dispatch(toggleNavBar(status)),
    toggleSignupBtn: (status) => dispatch(toggleSignupBtn(status)),
    updateListing: (options, listingId) => dispatch(editListing(options, listingId)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(AgentHomePage)
);
