import { SET_SEARCH_PREFS } from './actions';


const searchPrefState = (state = {}, { type, payload }) => {
  switch (type) {
  	case SET_SEARCH_PREFS:
      return payload;
    default:
      return state;
  }
};

export default searchPrefState;
