import firebase from 'firebase';
import 'firebase/storage';


//TODO: Add token verification and auth checks
export const deleteAssets = (assets, userId = 'someAgentId') => {
	let deleteRequests = [];
	deleteRequests = assets.map((asset) => {
		return deleteSingleAsset(asset, userId);
	});
	return deleteRequests;
}

const deleteSingleAsset = (asset, userId) => {
	const STORAGEREF = firebase.storage().ref();
	const { name, uploadId } = asset;
	const assetRef = STORAGEREF.child(`${userId}/${uploadId}/${name}`);

	return new Promise((resolve, reject) => {
		assetRef.delete().then(() => {
			resolve('Success')
		}).catch((error) => {
			reject(error);
		});
	});
}

export const uploadAssets = (assets, userId = 'someAgentId', uploadId) => {
	let uploadRequests = [];
	uploadRequests = assets.map((asset) => {
		return uploadSingleAsset(asset, userId, uploadId)
	});
	return uploadRequests;
}

const uploadSingleAsset = (asset, userId, uploadId) => {
	const STORAGEREF = firebase.storage().ref();

	return new Promise((resolve, reject) => {
		const { data, name, updated } = asset;

		const uploadTask = STORAGEREF.child(`${userId}/${uploadId}/${name}`).put(data,
			{
				contentType: asset.type,
				customMetadata: {
					creatorId: userId,
					uploadId,
					title: updated || name
				}
			}
		);

		uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log('Upload is ' + progress + '% done');
				switch (snapshot.state) {
					case firebase.storage.TaskState.PAUSED:
						console.log('Upload is paused');
						break;
					case firebase.storage.TaskState.RUNNING:
						console.log('Upload is running');
						break;
					default:
						break;
				}
			}, (error) => {
				switch (error.code) {
					case 'storage/unauthorized':
						break;

					case 'storage/canceled':
						break;

					case 'storage/unknown':
						// Unknown error occurred, inspect error.serverResponse
						break;
					default:
						break;
				}
				reject(error);
			}, () => {
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					const uploadSnapshot = {
						id: asset.id,
						uploadId,
						url: downloadURL,
						createdAt: new Date()
					}

					if (asset.type) {
						if (asset.type.indexOf('video') > -1) {
							uploadSnapshot.type = 'video'
						}
						if (asset.type.indexOf('image') > -1) {
							uploadSnapshot.name = asset.name;
							uploadSnapshot.updated = asset.updated;
							uploadSnapshot.type = 'image'
						}
					}
					resolve(uploadSnapshot);
				});
			});
	});
}



