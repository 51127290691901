import React, { Component } from 'react';
import { connect } from 'react-redux';

import LineChart from '../../components/Charts/Line';
import classes from './HomePage.scss'


class WhatsHotView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      weekIndex: 0
    };
  }

  _handleSelectLinePoint(index) {
    this.setState({ weekIndex: index })
  }

  render() {
    const { title, type, lineData } = this.props;

    return (
      <div className='width-p100'>
        <h3 className='paragraph-s margin-r8 margin-t48 fw-600'>
          {title || `Trending Renter Searches`}
        </h3>

        <section className={`content-container width-p100 margin-r8 ${classes['homepage-data-chart']}`}>
          {/* <figure className={`${classes['analytics-one-root']} flex-column margin-t16`}>
            <figcaption className='section-subheader'>
            {
              type ?
              `Views By Type - ${lineData[this.state.weekIndex].x}`
              :
              `Searches By Type - ${lineData[this.state.weekIndex].x}`
            }
            </figcaption>
            <div className={`${classes['analytics-one']}`}>
              <PieChart
                type={ type ? type : 'Searches' }
                data={pieData[lineData[this.state.weekIndex].x]}
                total={lineData[this.state.weekIndex].y}/>
            </div>
          </figure> */}
          <figure className={`${classes['analytics-two-root']} flex-column`}>
            {/* <figcaption className='section-subheader'>
              {type ? 'Views Timeline' : 'Search Timeline'}
            </figcaption> */}
            <div className={`${classes['analytics-two']}`}>
              <LineChart data={lineData} type={type ? type : 'Searches'}
                onSelectLinePoint={this._handleSelectLinePoint.bind(this)} />
            </div>
          </figure>
        </section>
      </div>
    );
  }
}


const mapStateToProps = ({ windowWidth }) => {
  return {
    windowWidth
  }
}

export default connect(mapStateToProps, {})(WhatsHotView);
