import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calendar from '@material-ui/icons/Today';
import Delete from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import {
	getDayFromISOString,
	getMonthFromISOString,
} from '../../Utils';
import './styles.scss';


class AppointmentListItem extends Component {
	constructor(props, context) {
		super(props, context);
		this._handleDeleteAppointment = this._handleDeleteAppointment.bind(this);
	}

	_editAppointment = (e) => {
		const { onEdit, appointment } = this.props;
		e.stopPropagation();
		onEdit(appointment);
	}

	_handleDeleteAppointment = (e) => {
		const { onDelete, appointment } = this.props;
		e.stopPropagation();
		onDelete(appointment);
	}

	render() {
		const { _id, appointment, appointmentCreatedFor, status } = this.props;
		const { attendees, isoDateTime, location } = appointment;
		const { isVerification } = appointment;

		const statusButtons = () => (
			<div className='flex-column width-p100 status-btns-root'>
				<div className='flex-row-spacebetween width-p100'>
					<button className='btn-status'
						onClick={this._editAppointment.bind(this)}
						aria-label='Edit Appointment'
						title='Edit Appointment'>
						<Edit />
					</button>
					<button className='btn-status'
						aria-label='Manage Appointment'
						title='Manage Appointment'>
						<Calendar />
					</button>
					<button className={`btn-status ${isVerification ? 'disabled' : ''}`}
						value='delete'
						disabled={isVerification}
						onClick={this._handleDeleteAppointment}
						title={isVerification ? 'A verification appointment can\'t be disabled, only modified' : 'Delete Appointment'}
						aria-label={isVerification ? 'Delete Appointment Disabled' : 'Delete Appointment'}>
						<Delete />
					</button>
				</div>
			</div>
		);

		const appointmentDetails = () => (
			<div className='width-p100 color-text'>
				<div className='flex-row-spacebetween'>
					<div></div>
					{
						!isVerification &&
						<div className='btn-delete'
							role='button'
							onClick={this._handleDeleteAppointment}
							aria-label='Delete Appointment'>
							X</div>
					}

				</div>
				<div className='calendar-container list-item'>
					<div className='calendar-root list-item'>
						<div className='month'>{getMonthFromISOString(isoDateTime)}</div>
						<div className='day'>{getDayFromISOString(isoDateTime)}</div>
					</div>
					<div className='bg-white flex-column ta-left'>
						<p className=''><span className='fw-600'>Venue:</span> {location}</p>
						<p className=''><span className='fw-600'>Time:</span> {moment(isoDateTime).format('LT')}</p>
						{
							isVerification
								? <p className=''><span className='fw-600'>Type:</span> Verification</p>
								: <p className=''><span className='fw-600'>Number of Renters:</span> {attendees.length}</p>
						}
					</div>
				</div>
				{statusButtons()}
			</div>
		);

		return (
			<div className={`listing-root width-p100 ${_id === appointmentCreatedFor && 'fade'}`}>
				<article className='bg-white br-4 padding-lr16'>
					<div className='listing-list-root'>
						{appointmentDetails()}
					</div>
				</article>
			</div>
		);
	}
}


const mapStateToProps = ({ windowWidth }) => {
	return { windowWidth }
}

const mapDispatchToProps = (dispatch) => {
	return {
		// setListing: (listing) => dispatch(setCurrentListing(listing)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)((AppointmentListItem));