import React from 'react'
import ReactDOM from 'react-dom'
import styles from './styles.scss'

class DialButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
  }
  handleMouseDown(e) {
    this.setState({ active: true })
  }
  handleMouseUp(e) {
    if (this.state.active) {
      this.setState({ active: false })
    }
  }
  componentDidMount() {
    const element = ReactDOM.findDOMNode(this.refs.button)
    element.addEventListener('mousedown', this.handleMouseDown)
    window.addEventListener('mouseup', this.handleMouseUp)
    element.addEventListener('touchstart', this.handleMouseDown)
    window.addEventListener('touchend', this.handleMouseUp)
  }
  componentWillUnmount() {
    const element = ReactDOM.findDOMNode(this.refs.button)
    element.removeEventListener('mousedown', this.handleMouseDown)
    window.removeEventListener('mouseup', this.handleMouseUp)
    element.removeEventListener('touchstart', this.handleMouseDown)
    window.removeEventListener('touchend', this.handleMouseUp)
  }
  render() {
    const { symbol, alias, icon, compact, type } = this.props
    const { active } = this.state

    return (
      <button ref='button' style={{
        'borderWidth': '1px',
        'borderStyle': 'solid',
        'borderColor': '#4d4d4d #000 #000 #4d4d4d',
        'color': '#fff',
        'fontSize': '30px',
        'padding': '25px 15px',
        'margin': 0,
        'width': '100%',
        'backgroundColor': active ? (type === 'login' ? '#00caf2' : '#C2E288') : 'transparent'
      }}>
        <strong>
          {icon}
          {(!icon || !compact) && (
            <span>&nbsp;{symbol}</span>
          )}
        </strong>
        {!!alias && !compact && (
          <sup style={{
            'textTransform': 'uppercase',
            'color': '#c1c1c1',
            'fontSize': '60%'
          }}>{alias}</sup>
        )}
      </button>
    )
  }
}

export class DialPad extends React.Component {
  render() {
    const { onClick, compact, type } = this.props
    const buttons = [
      {
        symbol: '1'
      },
      {
        symbol: '2',
        alias: 'abc'
      },
      {
        symbol: '3',
        alias: 'def'
      },
      {
        symbol: '4',
        alias: 'ghi'
      },
      {
        symbol: '5',
        alias: 'jkl'
      },
      {
        symbol: '6',
        alias: 'mno'
      },
      {
        symbol: '7',
        alias: 'pqrs'
      },
      {
        symbol: '8',
        alias: 'tuv'
      },
      {
        symbol: '9',
        alias: 'wxyz'
      },
      {
        symbol: '*'
      },
      {
        symbol: '0'
      },
      {
        symbol: '#'
      },
      /*{
        icon   : (<i className='fa fa-phone' />),
        symbol : 'Call',
        action : 'call'
      },
      {
        symbol : '+'
      },
      {
        icon   : (<i className='fa fa-remove' />),
        symbol : 'Hangup',
        action : 'hangup'
      }*/
    ]
    return (
      <div>
        <div style={{
          'float': 'left',
          'display': 'block',
          'width': '100%',
          'WebkitUserSelect': 'none',
          'KhtmlUserSelect': 'none',
          'MozUserSelect': 'none',
          'MsUserSelect': 'none',
          'userSelect': 'none',
          'background': '#354052',
          'fontFamily': '"Lucida Grande", Tahoma, Arial, Verdana, sans-serif'
        }}>
          <ol style={{
            'margin': 0,
            'padding': 0,
            'listStyle': 'none'
          }}>
            {buttons.map((button, i) => (
              <div onClick={() => onClick(button)}
                className={`${styles['dialpad-button']}`} key={i}>
                <DialButton type={type}
                  {...button} compact={compact} />
              </div>
            ))}
          </ol>
        </div>
      </div>
    )
  }
}

DialPad.defaultProps = {
  onClick: () => { }
}


export default class Dial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      capture: true,
      compact: false
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }
  isCompact() {
    const container = ReactDOM.findDOMNode(this.refs.container)
    return container ? container.getBoundingClientRect().width < 540 : false
  }
  handleResize(e) {
    const { compact } = this.state
    if (this.isCompact()) {
      if (!compact) {
        this.setState({ compact: true })
      }
    } else {
      if (compact) {
        this.setState({ compact: false })
      }
    }
  }
  handleClick(button) {
    const { value } = this.state
    if (!button.action) {
      this.setState({
        value: `${value}${button.symbol}`
      }, () => {
        this.validateInput(this.state.value);
        this.props.onPadPress(this.state.value);
      });
    } else if ('call' === button.action) {
      console.log(`Call number ${value}`)
    } else if ('hangup' === button.action) {
      console.log('Hangup call')
    }
  }
  handleKeyPress(e) {
    const { capture, value } = this.state
    if (!capture) {
      return
    }
    switch (e.charCode) {
      case 48:
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
      case 42:
      case 43:
        this.setState({
          value: value + String.fromCharCode(e.charCode)
        }, () => {
          this.validateInput(this.state.value);
          this.props.onPadPress(this.state.value);
        });
        break;
      default:
        break
    }
  }
  componentDidMount() {
    window.addEventListener('keypress', this.handleKeyPress)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.refs['phoneinput'].focus();
  }
  componentWillUnmount() {
    window.removeEventListener('keypress', this.handleKeyPress)
    window.removeEventListener('resize', this.handleResize)
  }
  beginCapture(e) {
    this.setState({
      capture: true
    })
  }
  endCapture(e) {
    this.setState({
      capture: false
    })
  }
  reset() {
    this.setState({
      value: '',
      error: false
    })
  }
  handleChange(e) {
    const { capture } = this.state
    let value = e.target.value;

    if (!capture) {
      this.setState({
        value: e.target.value
      }, () => {
        this.validateInput(value);
        this.props.onPadPress(this.state.value);
      })
    }
  }
  validateInput(str) {
    try {
      if (RegExp('[a-zA-z*#]').test(str)) {
        this.setState({
          error: true
        })
      }
      else {
        this.setState({
          error: false
        })
      }
    }
    catch (e) {
      this.setState({
        error: true
      })
    }
  }
  render() {
    const { value, compact } = this.state
    return (
      <div ref='container' className='relative margin-t16'>
        {!!value && (
          <div
            onClick={this.reset.bind(this)}
            style={{
              'padding': '5px 14px',
              'fontWeight': 'bold',
              'position': 'absolute',
              'right': '0',
              'textAlign': 'right',
              'top': '0',
              'fontSize': '30px',
              'textDecoration': 'none',
              'color': '#4d4d4d',
              'cursor': 'pointer'
            }}>&times;</div>
        )}
        <input
          ref='phoneinput'
          style={{
            'border': 'none',
            'float': 'left',
            'display': 'block',
            'width': '100%',
            'padding': '0 40px 0 8px',
            'fontSize': compact ? '30px' : '40px',
            'minHeight': '47px',
            'background': 'white',
            'outline': 'none',
            'color': this.state.error ? 'red' : '#4d4d4d'
          }}
          onChange={this.handleChange.bind(this)}
          onFocus={this.endCapture.bind(this)}
          onBlur={this.beginCapture.bind(this)}
          type='text'
          placeholder='Use this format: 0809999999'
          value={value} />
        <DialPad onClick={this.handleClick.bind(this)} compact={compact} type={this.props.type} />
      </div>
    )
  }
}