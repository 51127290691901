import React from 'react';
import ButtonClasses from './Button.scss';
import GoogleIcon from '../../assets/social/google.svg';


const GoogleBtn = props => {
  const googleBtnStyle = {
    backgroundColor: '#4285F4',
    color: '#FFF',
  }
  const googleLogo = <img className={ButtonClasses.google__btn_image} src={GoogleIcon} alt='google-login-logo' />
  return (
    <div style={googleBtnStyle} className='all__btn_container margin-t16' onClick={props.onClick}>
      < div style={{ position: 'absolute' }} className='flex'>
        {googleLogo}
      </div>
      <div className='ta-center width-p100'>
        <p className='all__btn_text'>{props.label}</p>
      </div>
    </div >
  )
}

export default GoogleBtn;