export const IS_EDIT_MODE = 'IS_EDIT_MODE';

export const saveApartmentDraft = (payload) => ({
  type: IS_EDIT_MODE,
  payload
});

export const endApartmentEdit = () => ({
  type: IS_EDIT_MODE,
  payload: false
});

export const ADD_RENTER_TO_APPOINTMENT__FAILURE = 'ADD_RENTER_TO_APPOINTMENT__FAILURE';
export const ADD_RENTER_TO_APPOINTMENT__LOADING = 'ADD_RENTER_TO_APPOINTMENT__LOADING';
export const ADD_RENTER_TO_APPOINTMENT__REQUEST = 'ADD_RENTER_TO_APPOINTMENT__REQUEST';
export const ADD_RENTER_TO_APPOINTMENT__SUCCESS = 'ADD_RENTER_TO_APPOINTMENT__SUCCESS';

export const CREATE_APPOINTMENT__FAILURE = 'CREATE_APPOINTMENT__FAILURE';
export const CREATE_APPOINTMENT__LOADING = 'CREATE_APPOINTMENT__LOADING';
export const CREATE_APPOINTMENT__REQUEST = 'CREATE_APPOINTMENT__REQUEST';
export const CREATE_APPOINTMENT__SUCCESS = 'CREATE_APPOINTMENT__SUCCESS';

export const DELETE_APPOINTMENT__FAILURE = 'DELETE_APPOINTMENT__FAILURE';
export const DELETE_APPOINTMENT__LOADING = 'DELETE_APPOINTMENT__LOADING';
export const DELETE_APPOINTMENT__REQUEST = 'DELETE_APPOINTMENT__REQUEST';
export const DELETE_APPOINTMENT__SUCCESS = 'DELETE_APPOINTMENT__SUCCESS';

export const EDIT_APPOINTMENT__FAILURE = 'EDIT_APPOINTMENT__FAILURE';
export const EDIT_APPOINTMENT__LOADING = 'EDIT_APPOINTMENT__LOADING';
export const EDIT_APPOINTMENT__REQUEST = 'EDIT_APPOINTMENT__REQUEST';
export const EDIT_APPOINTMENT__SUCCESS = 'EDIT_APPOINTMENT__SUCCESS';

export const GET_APPOINTMENTS__FAILURE = 'GET_APPOINTMENTS__FAILURE';
export const GET_APPOINTMENTS__LOADING = 'GET_APPOINTMENTS__LOADING';
export const GET_APPOINTMENTS__REQUEST = 'GET_APPOINTMENTS__REQUEST';
export const GET_APPOINTMENTS__SUCCESS = 'GET_APPOINTMENTS__SUCCESS';

export const GET_DASHBOARD_DATA__FAILURE = 'GET_DASHBOARD_DATA__FAILURE';
export const GET_DASHBOARD_DATA__LOADING = 'GET_DASHBOARD_DATA__LOADING';
export const GET_DASHBOARD_DATA__REQUEST = 'GET_DASHBOARD_DATA__REQUEST';
export const GET_DASHBOARD_DATA__SUCCESS = 'GET_DASHBOARD_DATA__SUCCESS';


//Appointments Actions
export const addRenterToAppointment = (payload) => {
  return {
    type: ADD_RENTER_TO_APPOINTMENT__REQUEST,
    payload
  }
};

export const createAppointment = (payload) => {
  return {
    type: CREATE_APPOINTMENT__REQUEST,
    payload
  }
};

export const deleteAppointment = (payload) => {
  return {
    type: DELETE_APPOINTMENT__REQUEST,
    payload
  }
};

export const editAppointment = (payload, appointmentId) => {
  return {
    type: EDIT_APPOINTMENT__REQUEST,
    payload,
    appointmentId
  }
};

export const getAppointments = (payload) => {
  return {
    type: GET_APPOINTMENTS__REQUEST,
    payload
  }
};

//Dashboard Actions
export const getDashboardData = (payload) => {
  return {
    type: GET_DASHBOARD_DATA__REQUEST,
    payload
  }
}
