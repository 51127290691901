import { SET_CURR_LISTING } from './actions';

const initialState = { /*'completed': false,*/ data: null };

const listingReducer = (previousState = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURR_LISTING:
      return {
        ...previousState,
        data: payload,
        completed: true
      };
    default:
      return previousState;
  }
};

export default listingReducer;
