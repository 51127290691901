
export const AVERAGE_MONTHLY_EARNING = 39500;
export const AVERAGE_EARNING_PER_LISTING = 12300.00;
export const COUNT_PER_PAGE = 2;
export const DEFAULT_MINIMUM_DESC_LENGTH = 300;
export const DEFAULT_MINIMUM_DESC_VALUE = 1000;
export const DEFAULT_IMAGE_VALUE = 1000;
export const DEFAULT_VIDEO_VALUE = 5000;
export const DEFAULT_MINIMUM_NUM_IMAGES = 1;
export const ICON_SIZE = 16;
export const TOTAL_NUM_AGENTS = 1075;

export const AVERAGE_EARNING_PER_LISTING_STR = 'Average Earning per Listing';
export const YOUR_CURRENT_EARNING = 'Your Current Earning';
export const YOUR_NEXT_EARNING = 'What You Could Earn Next';
export const MAXIMUM_EARNED_PER_LISTING = 'Maximum Earned per Listing';

export const TEST_USER = {
  name: 'Test User',
  uid: 'someAgentId'
}

export const APARTMENT_TYPES_BOOL = {
  'self': true, 'one': true, 'two': true, 'threeplus': true
}

export const APARTMENT_TYPES_STR = {
  'self': 'Self-contained',
  'one': '1 Bedroom',
  'two': '2 Bedroom',
  'three': '3 Bedroom',
  'threeplus': '3-bedroom',
  'all': '',
};

export const APARTMENT_ZONES = {
  'ik': 'Ikot-Ekpene Road/Itu',
  'aka': 'Aka/Abak Road',
  'oron': 'Oron Road/Airport',
  'nwa': 'Nwaniba Road/Use Offot',
  'all': 'all'
}

export const LISTING_REGIONS = [
  { value: 'aka', label: 'Abak/Aka Road' },
  { value: 'nwa', label: 'Nwaniba Road/Use Offot' },
  { value: 'ik', label: 'Ikot Ekpene Road/Itu Road' },
  { value: 'oron', label: 'Oron Road/Airport Road' }
]

export const AGENT_LISTING_STATUS = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'DORMANT', label: 'DORMANT' },
  { value: 'RENTED', label: 'RENTED' },
]

export const LOCATIONS_MAP = {
  'ik': 'Ikot Ekpene',
  'uyo': 'Uyo',
  'eket': 'Eket',
  'anywhere': ''
};

export const LOCATIONS = [
  { axis: 'aka', name: 'Aka Etinan Road' },
  { axis: 'aka', name: 'Aka Road' },
  { axis: 'oron', name: 'Oron Road' },
  { axis: 'oron', name: 'Barracks Road' },
  { axis: 'nwa', name: 'Nwaniba Road' },
  { axis: 'ik', name: 'Itu Road' },
  { axis: 'ik', name: 'Ikot Ekpene Road' },
];

const statusStyle = {
  borderRadius: 4,
  padding: '4px 8px',
  color: 'white'
};

export const getStatusStyle = (status) => {
  switch (status) {
    case 'ACTIVE':
    case 'VERIFIED':
      return {
        ...{ backgroundColor: 'green' },
        ...statusStyle
      };
    case 'DISABLED':
    case 'DORMANT':
      return {
        ...{ backgroundColor: 'gray' },
        ...statusStyle
      };
    case 'MODIFIED':
      return {
        ...{ backgroundColor: '#491D4B' },
        ...statusStyle
      };
    case 'RENTED':
      return {
        ...{ backgroundColor: 'orange', color: 'black' },
        ...statusStyle
      };
    case 'FLAGGED':
    case 'UNVERIFIED':
      return {
        ...{ backgroundColor: 'firebrick' },
        ...statusStyle
      };
    default:
      return statusStyle;
  }
}

export const mockChartData = {
  lineData: [
    { x: "09/01", y: 420 },
    { x: "09/08", y: 200 },
    { x: "09/15", y: 830 },
    { x: "09/22", y: 106 },
    { x: "09/29", y: 377 },
    { x: "10/06", y: 261 },
  ],
  pieData: {
    "09/01/18": [
      { x: "Self-con", y: 100, label: "Self-con: 23.8%" },
      { x: "1-Bed", y: 80, label: "1-Bed: 19.0%" },
      { x: "2-Bed", y: 130, label: "2-Bed: 31.0%" },
      { x: "3-Bed", y: 110, label: "3-Bed: 26.2%" },
    ],
    "09/08/18": [
      { x: "Self-con", y: 80, label: "Self-con: 40.0%" },
      { x: "1-Bed", y: 25, label: "1-Bed: 12.5%" },
      { x: "2-Bed", y: 20, label: "2-Bed: 10%" },
      { x: "3-Bed", y: 75, label: "3-Bed: 37.5%" },
    ],
    "09/15/18": [
      { x: "Self-con", y: 401, label: "Self-con: 48.3%" },
      { x: "1-Bed", y: 133, label: "1-Bed: 16.0%" },
      { x: "2-Bed", y: 209, label: "2-Bed: 25.2%" },
      { x: "3-Bed", y: 87, label: "3-Bed: 10.5%" },
    ],
    "09/22/18": [
      { x: "Self-con", y: 32, label: "Self-con: 30.2%" },
      { x: "1-Bed", y: 11, label: "1-Bed: 10.8%" },
      { x: "2-Bed", y: 19, label: "2-Bed: 17.5%" },
      { x: "3-Bed", y: 44, label: "3-Bed: 41.5%" },
    ],
    "09/29/18": [
      { x: "Self-con", y: 53, label: "Self-con: 14.1%" },
      { x: "1-Bed", y: 84, label: "1-Bed: 22.3%" },
      { x: "2-Bed", y: 181, label: "2-Bed: 48.0%" },
      { x: "3-Bed", y: 59, label: "3-Bed: 15.6%" },
    ],
    "10/06/18": [
      { x: "Self-con", y: 43, label: "Self-con: 16.5%" },
      { x: "1-Bed", y: 38, label: "1-Bed: 14.6%" },
      { x: "2-Bed", y: 97, label: "2-Bed: 37.2%" },
      { x: "3-Bed", y: 83, label: "3-Bed: 31.8%" },
    ]
  }
}
