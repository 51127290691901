
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';

let preloadedState = {}
if (typeof window !== 'undefined') {
	preloadedState = window.__PRELOADED_STATE__;
	delete window.__PRELOADED_STATE__;
}

export default (history, sagaMiddleware) => {
	const store = createStore(
		rootReducer(history),
		preloadedState,
		compose(
			applyMiddleware(routerMiddleware(history), sagaMiddleware),
			window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
		),
	);

	return store;
};