import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import AuthForm from '../../containers/AuthForm/';
import { toggleNavBar } from '../../containers/NavBar/actions';


class PasswordReset extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    this.props.toggleNavBar(false);
  }

  render() {
    return (
      <div>
        <Helmet title='Reset Your Password | Home Attlas' />
        <AuthForm type='forgot' embed={false} />
      </div>
    );
  }
}


const mapStateToProps = store => {
  return {
    navBarDisplay: store.navBarDisplay
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavBar: (status) => dispatch(toggleNavBar(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
