import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from '../containers/AuthForm/reducer';
import appointmentReducer from '../pages/home/appointmentReducer';
import dashboardReducer from '../pages/home/dashboardReducer';
import listingReducer from '../pages/listings/listingReducer';
import listingsReducer from '../pages/listings/listingsReducer';
import navBarReducer from '../containers/NavBar/reducer';
import signUpBtnReducer from '../containers/NavBar/signupBtnReducer';
import searchPrefReducer from '../pages/listings/searchPrefReducer';
import windowWidthReducer from '../containers/NavBar/windowWidthReducer';


export const makeRootReducer = (history, asyncReducers) => {
	return combineReducers({
		router: connectRouter(history),
		authState: authReducer,
		appointments: appointmentReducer,
		currentListing: listingReducer,
		dashboard: dashboardReducer,
		listings: listingsReducer,
		navBarDisplay: navBarReducer,
		signupBtnState: signUpBtnReducer,
		searchPrefState: searchPrefReducer,
		windowWidth: windowWidthReducer,
		...asyncReducers
	})
}

export const injectReducer = (store, { key, reducer }) => {
	store.asyncReducers[key] = reducer;
	store.replaceReducer(makeRootReducer(store.asyncReducers));
}

export default makeRootReducer;
