import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-select';

class HADropdown extends Component {
  _handleSelectType(selectedOption) {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);
  }

  render() {
    let self = this;
    const baseStyles = {
      color: '#354052'
    }
    const customStyles = {
      container: (base, state) => ({
        ...base,
        color: '#354052',
        fontWeight: 300,
      }),
      option: (base, { data, _, isFocused, isSelected }) => ({
        ...base,
        ...baseStyles,
        fontWeight: isSelected ? '500' : '300',
        backgroundColor: isFocused ? '#fcebbd' : 'white',
        cursor: 'pointer'
      }),
      control: (base, { data, _, isFocused }) => ({
        ...base,
        ...baseStyles,
        cursor: 'pointer',
        backgroundColor: 'white',
        boxShadow: self.props.error ? 'red' : (isFocused ? '0 0 0 0.5px #F7BF23' : 'none'),
        borderColor: self.props.error ? 'red' : (isFocused ? '#F7BF23' : 'hsl(0,0%,80%)'), //'#49ACF4',
        '&:hover': {
          border: self.props.error ? '1px solid red' : '1px solid #F7BF23',
        },
      }),
      singleValue: (base, state) => ({
        ...base,
        ...baseStyles,
        fontWeight: 300,
      }),
    };

    const { options, placeholder, onMenuOpen, isSearchable, selectedItem } = this.props;

    return (
      <div onClick={e => e.stopPropagation()} style={{ fontSize: '.85rem' }}>
        <Dropdown tabIndex={0}
          options={options}
          aria-label={placeholder || 'Select apartment type'}
          styles={customStyles}
          isSearchable={isSearchable || true}
          placeholder={placeholder || 'Select type'}
          value={selectedItem}
          onMenuOpen={onMenuOpen}
          onChange={this._handleSelectType.bind(this)} />
      </div>
    );
  }
}

HADropdown.propTypes = {
  items: PropTypes.array,
};

HADropdown.defaultProps = {
  options: [
    { value: 'self', label: 'Self-Contained' },
    { value: 'one', label: 'One Bedroom' },
    { value: 'two', label: 'Two Bedroom' },
    { value: 'three', label: 'Three Bedroom' }
  ]
};

export default HADropdown;
