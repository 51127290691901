import React from 'react';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import { getStatusStyle } from '../../Constants';


const AdminListItem = (props) => {
  const { windowWidth, name, onView, onEdit, onDelete, label } = props;
  const btnIconStyle = {
    fontSize: 20
  }

  const actionButtons = () => (
    <div className='relative flex-center'>
      <button className='btn-root' title={'View Agent'} label={'View Agent'}
        onClick={onView}>
        <ViewIcon style={btnIconStyle} className='btn-icon' />
        <span className='btn-label'>&nbsp;View</span>
      </button>
      <button className='btn-root' title={'Edit Agent'} label={'Edit Agent'}
        onClick={onEdit}>
        <EditIcon style={btnIconStyle} className='btn-icon' />
        <span className='btn-label'>&nbsp;Edit</span>
      </button>
      <button className='btn-root' title={'Delete Agent'} label={'Delete Agent'}
        onClick={onDelete}>
        <DeleteIcon style={btnIconStyle} className='btn-icon' />
        <span className='btn-label'>&nbsp;Delete</span>
      </button>
    </div>
  );

  const displayDetails = () => {
    if (props.mode === 'pay') {
      return (
        <div className={`flex-center not-for-mobile`}>{props.nextPayDue || '28/12/2019'}</div>
      );
    }
    else if (props.mode === 'listing') {
      return (
        <div className={`flex-center not-for-mobile`}>
          <span style={getStatusStyle(props.status || 'UNVERIFIED')}>
            {props.status || 'UNVERIFIED'}
          </span>
        </div>
      )
    }
    else {
      return (
        <div className={`flex-center not-for-mobile`}>
          <span style={getStatusStyle('ACTIVE')}>
            {props.status || 'ACTIVE'}
          </span>
        </div>
      )
    }
  }

  return (
    <article className='agent-item-root table' onClick={props.onClick}>
      <div className='flex-row-align-center padding-l8'>
        <p className='agent-name ellipsize'>{name || label}</p>
      </div>
      {windowWidth > 660 && displayDetails()}
      {props.mode !== 'pay'
        ? actionButtons()
        : <div className={`flex-center display-mobile-none`} >{props.nextPayAmount || 'N4,300.00'}</div>
      }
    </article>
  );
}


const mapStateToProps = store => {
  return {
    windowWidth: store.windowWidth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)((AdminListItem));
