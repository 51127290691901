import {
	GET_DASHBOARD_DATA__FAILURE,
	GET_DASHBOARD_DATA__LOADING,
	GET_DASHBOARD_DATA__SUCCESS,
} from './actions';

const initialState = {
	completed: false,
	loading: false,
	data: {
		appointment: {
			date: 'N/A'
		},
		listing: {
			likes: 0,
			views: 0,
			messages: 0,
			location: '🤔'
		},
		earning: {
			amount: 0,
			date: 'N/A'
		}
	}
};

const dashboardReducer = (previousState = initialState, { type, payload }) => {
	switch (type) {
		case GET_DASHBOARD_DATA__LOADING:
			return {
				...previousState,
				loading: true
			};

		case GET_DASHBOARD_DATA__SUCCESS:
			return {
				...previousState,
				completed: true,
				data: payload,
				loading: false,
				success: true,
			};
		case GET_DASHBOARD_DATA__FAILURE:
			return {
				...previousState,
				loading: false,
				completed: true,
				success: false,
			};
		default:
			return previousState;
	}
};

export default dashboardReducer;