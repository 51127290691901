import {
	ADD_RENTER_TO_APPOINTMENT__LOADING,
	CREATE_APPOINTMENT__FAILURE,
	CREATE_APPOINTMENT__LOADING,
	CREATE_APPOINTMENT__SUCCESS,
	DELETE_APPOINTMENT__FAILURE,
	DELETE_APPOINTMENT__SUCCESS,
	GET_APPOINTMENTS__FAILURE,
	GET_APPOINTMENTS__LOADING,
	GET_APPOINTMENTS__SUCCESS,
	EDIT_APPOINTMENT__FAILURE,
	EDIT_APPOINTMENT__LOADING,
	EDIT_APPOINTMENT__SUCCESS,
} from './actions';
import { sortArray } from '../../Utils';

const initialState = { completed: false, loading: false, data: [] };

const appointmentReducer = (previousState = initialState, { type, payload }) => {
	const getFailureState = {
		...previousState,
		loading: false,
		completed: true,
		success: false,
	};

	switch (type) {
		case ADD_RENTER_TO_APPOINTMENT__LOADING:
			return {
				...previousState,
				loading: true,
				source: 'addRenter'
			};

		case CREATE_APPOINTMENT__FAILURE:
			return {
				...getFailureState,
				source: 'appointmentCreate'
			}

		case CREATE_APPOINTMENT__LOADING:
			return {
				...previousState,
				loading: true,
				source: 'appointmentCreate'
			};

		case CREATE_APPOINTMENT__SUCCESS:
			return {
				completed: true,
				success: true,
				loading: false,
				data: sortArray(previousState.data.concat(payload.data), 'isoDateTime'),
				source: 'appointmentCreate',
				listingId: payload.data.listingId
			};

		case DELETE_APPOINTMENT__FAILURE:
			return {
				...getFailureState,
				source: 'appointmentDelete'
			};

		case DELETE_APPOINTMENT__SUCCESS:
			return {
				completed: true,
				success: true,
				loading: false,
				data: previousState.data.filter((appointment) => appointment._id !== payload.data._id),
				source: 'appointmentDelete'
			};

		case EDIT_APPOINTMENT__FAILURE:
			return {
				...getFailureState,
				source: 'appointmentEdit'
			};

		case EDIT_APPOINTMENT__LOADING:
			return {
				...previousState,
				loading: true,
				source: 'appointmentEdit'
			};

		case EDIT_APPOINTMENT__SUCCESS:
			let updatedState = previousState.data.map((appointment) => {
				if (appointment._id === payload.data._id) {
					appointment = payload.data;
				}
				return appointment;
			});
			return {
				completed: true,
				success: true,
				data: sortArray(updatedState, 'isoDateTime'),
				source: 'appointmentEdit'
			};

		case GET_APPOINTMENTS__FAILURE:
			return {
				...getFailureState,
				source: 'appointmentFetch'
			};

		case GET_APPOINTMENTS__LOADING:
			return {
				...previousState,
				//loading: true
			};

		case GET_APPOINTMENTS__SUCCESS:
			return {
				data: sortArray(payload.data, 'isoDateTime'),
				completed: true,
				loading: false,
				success: true
			};
		default:
			return previousState;
	}
};


export default appointmentReducer;
