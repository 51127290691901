import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import QueryString from 'query-string';

import AuthForm from '../../containers/AuthForm/';
import { toggleNavBar } from '../../containers/NavBar/actions';


class LoginPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    this.props.toggleNavBar(false);
  }

  render() {
    const { location } = this.props;
    let headerText = location.state && location.state.headerText;
    const isSaveListing = QueryString.parse(location.search)['save_listing'];

    if (!headerText && isSaveListing) {
      headerText = 'We don\'t want us to miss these, login to save these listings'
    }

    return (
      <div>
        <Helmet>
          <title>List and Earn&trade; | Login to continue</title>
        </Helmet>
        <section className='bg-circles flex-center' style={{ minHeight: 'calc(100vh - 80px)', padding: '0 0.5rem' }}>
          <AuthForm
            headerText={headerText}
            type='login'
            embed={false} />
        </section>
      </div>
    );
  }
}


const mapStateToProps = store => {
  return {
    navBarDisplay: store.navBarDisplay
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavBar: (status) => dispatch(toggleNavBar(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
