export const CREATE_LISTING__LOADING = 'CREATE_LISTING__LOADING';
export const CREATE_LISTING__FAILURE = 'CREATE_LISTING__FAILURE';
export const CREATE_LISTING__REQUEST = 'CREATE_LISTING__REQUEST';
export const CREATE_LISTING__SUCCESS = 'CREATE_LISTING__SUCCESS';

export const DELETE_LISTING__FAILURE = 'DELETE_LISTING__FAILURE';
export const DELETE_LISTING__LOADING = 'DELETE_LISTING__LOADING';
export const DELETE_LISTING__REQUEST = 'DELETE_LISTING__REQUEST';
export const DELETE_LISTING__SUCCESS = 'DELETE_LISTING__SUCCESS';

export const EDIT_LISTING__FAILURE = 'EDIT_LISTING__FAILURE';
export const EDIT_LISTING__LOADING = 'EDIT_LISTING__LOADING';
export const EDIT_LISTING__REQUEST = 'EDIT_LISTING__REQUEST';
export const EDIT_LISTING__SUCCESS = 'EDIT_LISTING__SUCCESS';

export const GET_LISTING__FAILURE = 'GET_LISTING__FAILURE';
export const GET_LISTING__LOADING = 'GET_LISTING__LOADING';
export const GET_LISTING__REQUEST = 'GET_LISTING__REQUEST';
export const GET_LISTING__SUCCESS = 'GET_LISTING__SUCCESS';

export const GET_LISTINGS__FAILURE = 'GET_LISTINGS__FAILURE';
export const GET_LISTINGS__LOADING = 'GET_LISTINGS__LOADING';
export const GET_LISTINGS__REQUEST = 'GET_LISTINGS__REQUEST';
export const GET_LISTINGS__SUCCESS = 'GET_LISTINGS__SUCCESS';

export const GET_UNVERIFIED_LISTINGS__FAILURE = 'GET_UNVERIFIED_LISTINGS__FAILURE';
export const GET_UNVERIFIED_LISTINGS__LOADING = 'GET_UNVERIFIED_LISTINGS__LOADING';
export const GET_UNVERIFIED_LISTINGS__REQUEST = 'GET_UNVERIFIED_LISTINGS__REQUEST';
export const GET_UNVERIFIED_LISTINGS__SUCCESS = 'GET_UNVERIFIED_LISTINGS__SUCCESS';

export const SET_CURR_LISTING = 'SET_CURR_LISTING';
export const SET_SEARCH_PREFS = 'SET_SEARCH_PREFS';

export const VERIFY_LISTING__FAILURE = 'VERIFY_LISTING__FAILURE';
export const VERIFY_LISTING__LOADING = 'VERIFY_LISTING__LOADING';
export const VERIFY_LISTING__REQUEST = 'VERIFY_LISTING__REQUEST';
export const VERIFY_LISTING__SUCCESS = 'VERIFY_LISTING__SUCCESS';

export const createListing = (payload) => {
  return {
    type: CREATE_LISTING__REQUEST,
    payload,
  }
};

export const deleteListing = (payload) => {
  return {
    type: DELETE_LISTING__REQUEST,
    payload,
  }
};

export const editListing = (options, listingId) => {
  return {
    type: EDIT_LISTING__REQUEST,
    payload: { options, listingId }
  }
};

export const getAllListings = (payload) => {
  return {
    type: GET_LISTINGS__REQUEST,
    payload,
  }
};

export const getListing = (payload) => {
  return {
    type: GET_LISTING__REQUEST,
    payload
  }
};

export const getListings = (payload) => {
  return {
    type: GET_LISTINGS__REQUEST,
    payload,
  }
};

export const setCurrentListing = (payload) => {
  return {
    type: SET_CURR_LISTING,
    payload,
  }
};

export const setSearchPrefs = (payload) => {
  return {
    type: SET_SEARCH_PREFS,
    payload,
  }
};

export const verifyListing = (payload) => {
  return {
    type: VERIFY_LISTING__REQUEST,
    payload,
  }
};
