import React, { Component } from 'react';
import QueryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import AuthForm from '../../containers/AuthForm/';
import { toggleNavBar } from '../../containers/NavBar/actions';


class PhoneAuthPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    this.props.toggleNavBar(false);
  }

  render() {
    const { location } = this.props;
    const type = QueryString.parse(location.search)['type'];
    return (
      <div>
        <Helmet title='Home Attlas | Phone Auth' />
        <AuthForm
          authType='phone'
          type={type}
          embed={false}
        />
      </div>
    );
  }
}


const mapStateToProps = store => {
  return {
    navBarDisplay: store.navBarDisplay
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavBar: (status) => dispatch(toggleNavBar(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PhoneAuthPage));
