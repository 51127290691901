import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from '../../components/Dropdown/landing';
import ListAndEarn from '../../assets/list-n-earn.svg';
import PaidForListing from '../../assets/get-paid-listing.svg';
import UploadListing from '../../assets/upload-listing-dark.svg';
import VerifyListing from '../../assets/listing-verification.svg';
import { toggleNavBar } from '../../containers/NavBar/actions';
import { getCTAButtonText, getCTAButtonPath, getCTASubHeader } from '../../Utils';


class HomeB extends Component {
	constructor(props) {
		super(props);
		this.handleCTABtnClick = this.handleCTABtnClick.bind(this);
	}

	componentWillMount = () => {
		this.props.toggleNavBar(true);
	}

	componentDidMount() {
		if (this.$ref && window.location.hash.includes('#faqs')) {
			this.$ref.scrollIntoView({
				behaviour: 'smooth',
				block: 'start',
				inline: 'nearest',
			});
		}
	}

	UNSAFE_componentWillUpdate() {
		this.handleScrollToHowItWorks();
	}

	handleCTABtnClick = () => {
		const CTA_PATH = getCTAButtonPath();
		const { history } = this.props;
		history.push({
			pathname: CTA_PATH,
			search: '?selectedView=earnings',
			state: { version: 'B' },
		});
	}

	handleScrollToHowItWorks = () => {
		if (this.$howDoesItWorkRef && window.location.hash.includes('#howitworks')) {
			this.$howDoesItWorkRef.scrollIntoView({
				behaviour: 'smooth',
				block: 'start',
				inline: 'nearest',
			});
		}
	}

	render() {
		const CTAButtonText = getCTAButtonText();

		return (
			<main>
				<section className='bg-secondary'>
					<header className='main-top bg-circles'>
						<div className='main-top-section section-parent'>
							<div className='section-child top-section-child-left'>
								<h1 className='main-top-header color-white'>Earn more with your listings</h1>
								<div style={{ marginTop: 16 }}>
									<p className='main-top-subheader color-white'>
										Hello Agent, why wait till you find a tenant before you earn?
										What if we told you that you can earn on your listings even before you find a tenant?
									</p>
									<p className='main-top-subheader color-white'>{getCTASubHeader()}</p>
								</div>
								<button className='cta-main-btn btn-l' onClick={this.handleCTABtnClick}>{CTAButtonText.top}</button>
							</div>
							<div className='section-child top-section-child-right'>
								<img className='main-top-section-img' src={ListAndEarn} alt='List and Earn' />
							</div>
						</div>
					</header>
					<div ref={ref => { this.$howDoesItWorkRef = ref; }} style={{ padding: 16, height: 8 }} />
				</section>

				<section className='section-root'>
					<h2 className='section-header ta-center'>How does this work?</h2>
					<div className='section-container'>
						<div className='section-child section-child-left'>
							<p className='section-subheader'>Step I: Upload listing from your phone</p>
							<p className='section-text'>
								Take 5, 8, 14 pictures of your listing; be it a two-bedroom apartment, a self-contained apartment or even a duplex.
								Upload these pictures and other details about the listing directly from your mobile phone.
							</p>
						</div>
						<div className='section-child section-child-right'>
							<img className='main-top-section-img' src={UploadListing} alt='Upload listing from your device' />
						</div>
					</div>
				</section>

				<section className='section-root bg-houses'>
					<div className='section-container'>
						<div className='section-child'>
							<img className='main-top-section-img' src={VerifyListing} alt='Verify the listing' />
						</div>
						<div className='section-child'>
							<p className='section-subheader'>Step 2: We verify your listing</p>
							<p className='section-text'>
								We verify the listing you uploaded, to be sure it's genuine. We will also value the quality of
								the listing upload you based on some criteria including but not limited
								to the quality of images, video and description.
							</p>
						</div>
					</div>
				</section>

				<section className='section-root bg-primarylisght'>
					<div className='section-container'>
						<div className='section-child section-child-left'>
							<p className='section-subheader'>Step 3: Earn on each verified listing</p>
							<p className='section-text'>
								Verification complete! You can now smile to the bank. But that's not all, we will also showcase your verified listings
								to thousands of prospective tenants and help you find a tenant for your listing all for free. Double win!
							</p>
						</div>
						<div className='section-child section-child-right'>
							<img className='main-top-section-img' src={PaidForListing} alt='Get paid for each listing' />
						</div>
					</div>
				</section>

				<section className='bg-circles color-white'>
					<div className='section-container'>
						<div className='bg-secondary section-middle-cta'>
							<p className='section-subheader ta-center'>Stop paying to list. <br />Start earning on your listings.</p>
							<button className='cta-main-btn btn-l' style={{ marginBottom: 24, marginTop: 24 }}
								onClick={this.handleCTABtnClick}>{CTAButtonText.middle}</button>
						</div>
					</div>
				</section>

				<div ref={ref => { this.$ref = ref; }} style={{ padding: 12, height: 4 }} />

				<section className='section-root bg-circles'>
					<h2 className='section-header' style={{ paddingTop: 0 }}>Frequently Asked Questions</h2>
					<ul className='section-container faq-root'>
						<Dropdown header={'How much can I earn for each listing?'}
							body={`You can earn as much as N12,000 per listing depending on the quality of content added when the listing is uploaded. 
							So upload 5 verified listings and you make N60,000 just like that!`}
						/>

						<Dropdown header={'How long does it take to get my earning after uploading a listing?'}
							body={`Once your listing is verified and valued, you will receive a notification that your earnings for that listing is available. 
							Verification can take from 3 days to a maximum of 2 weeks.`
							}
						/>

						<Dropdown header={'How do you value a listing?'}
							body={'We value every listing using some predefined criteria - including but not limited to number and quality of images, the quality of description etc.'} />

						<Dropdown header={'Who owns the listing after it is uploaded?'}
							body={`The listing is yours and will forever be yours. There is no transfer of ownership of the listing when you upload it.`}
						/>

						<Dropdown header={'What happens if someone uploads a similar listing?'}
							body={`During the verification stage, we will notify you if we notice your listing is a duplicate.`}
						/>

						<Dropdown header={`So I've uploaded my listing, what next?`}
							body={
								`Awesome! Once we verify and value your listing, we will work with you to find prospective tenants.
								 But here's the good part - whether we find the tenants or not, your earning is SURE.`}
						/>

						{/*<Dropdown header={'Are you real estate agents?'}
							body={`No we are not. Think of us as a cute apartment listing service, seeking to make life better for us all 😊.`} />*/}
					</ul>
				</section>

				<section className='bg-secondary color-white'>
					<div className='bg-circles section-bottom-cta'>
						<p className='section-subheader flex'>Nothing to lose and all to earn</p>
						<button className='cta-main-btn btn-l' style={{ marginBottom: 24, marginTop: 24 }}
							onClick={this.handleCTABtnClick}>{CTAButtonText.bottom}</button>
					</div>
				</section>
			</main>
		);
	}
}

const mapStateToProps = store => {
	return {
		navBarDisplay: store.navBarDisplay
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toggleNavBar: (status) => dispatch(toggleNavBar(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeB);