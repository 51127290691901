import { put, takeEvery, take, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import firebase from 'firebase';
import 'firebase/auth';
import {
  LOGOUT_USER__FAILURE,
  LOGOUT_USER__REQUEST,
  LOGOUT_USER__SUCCESS,
  SET_AUTH_USER,
  SET_LOGGEDIN__USER,
} from './actions';
import { setCookie, deleteCookie } from '../../Utils';
import { apiClient } from '../../services/ApiClient';
import { HA_USER_TOKEN } from './utils';

const endpoint = process.env.REACT_APP_USER_PROFILE_SERVICE_ENDPOINT;

export function* getAuthStateListener() {
  return new eventChannel((emitter) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          const { providerData } = user;

          if (providerData && providerData[0].providerId === 'password' && user.emailVerified) {
            console.log(`Cookie set with value `, token);
            setCookie(HA_USER_TOKEN, token, 365);
          }

          console.log('Here is the SAGA token', token)
          apiClient(endpoint, null, token).get('/auth/user')
            .then((res) => {
              const userData = res.data.data;
              const { displayName, email, emailVerified, uid, phoneNumber, photoURL } = user;
              const { authProvider, type, status } = userData;
              const userObject = {
                name: displayName,
                authProvider,
                ...(email && { email }),
                emailVerified,
                ...(phoneNumber && { phoneNumber }),
                ...(photoURL && { photoURL }),
                type,
                uid,
                status
              };
              console.log('User object in emitter', userObject)
              emitter({
                type: SET_AUTH_USER,
                payload: userObject
              });
            })
            .catch((error) => {
              console.log('Error getting user details', error)
            });
        });
      }
      else {
        console.log('user is logged out');
        // const user = {
        //   uid: '48494', //null,
        //   name: 'Jay Kay',
        //   userType: 'renter',
        //   new: true
        // }
        emitter({
          type: SET_AUTH_USER,
          payload: {}//user
        });
      }
    });
    return () => { /* This is a handler to unsubscribe. */ }
  })
}

export function* getAuthStateSaga() {
  const channel = yield call(getAuthStateListener);
  let res = yield take(channel);
  let { type, payload } = res;
  if (payload.uid) {
    yield put({
      type: type || SET_AUTH_USER,
      payload
    });
  }
}

export function* setLoggedInUser(action) {
  yield put({
    type: SET_AUTH_USER,
    payload: action.payload
  });
}

export const isLoggedOut = () => new Promise((resolve, reject) => {
  firebase.auth().signOut().then(() => {
    deleteCookie(HA_USER_TOKEN);
    resolve({ success: true })
  }).catch((error) => {
    reject({ success: false });
  });
})

export async function* logOutUser() {
  const res = await isLoggedOut();
  if (res.success) {
    yield put({
      type: LOGOUT_USER__SUCCESS
    });
  }
  else {
    yield put({
      type: LOGOUT_USER__FAILURE
    });
  }
}

export function* setLoggedInUserSaga() {
  yield takeEvery(SET_LOGGEDIN__USER, setLoggedInUser);
}

export function* logOutUserSaga() {
  yield takeEvery(LOGOUT_USER__REQUEST, logOutUser);
}
