export const TOGGLE_NAVBAR = 'TOGGLE_NAVBAR';
export const TOGGLE_SIGNUP_BTN = 'TOGGLE_SIGNUP_BTN';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';


export const toggleNavBar = (status) => ({
  type: TOGGLE_NAVBAR,
  payload: status,
});

export const toggleSignupBtn = (status) => ({
  type: TOGGLE_SIGNUP_BTN,
  payload: status,
});

export const setWindowWidth = (width) => ({
  type: SET_WINDOW_WIDTH,
  payload: width,
});
