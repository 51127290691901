import { call, put, select, takeEvery } from 'redux-saga/effects';
//import { delay } from 'redux-saga';

import {
  createListing,
  deleteListing,
  fetchListing,
  //fetchListings,
  fetchUnverifiedListings,
  updateListing,
  fetchListingsByCreator
} from '../../services/ApiClient';


import {
  SET_SEARCH_PREFS,
  GET_LISTING__LOADING,
  GET_LISTING__REQUEST,
  GET_LISTING__SUCCESS,
  GET_LISTING__FAILURE,
  GET_LISTINGS__LOADING,
  GET_LISTINGS__REQUEST,
  GET_LISTINGS__SUCCESS,
  GET_LISTINGS__FAILURE,
  EDIT_LISTING__REQUEST,
  DELETE_LISTING__REQUEST,
  CREATE_LISTING__LOADING,
  CREATE_LISTING__REQUEST,
  CREATE_LISTING__SUCCESS,
  CREATE_LISTING__FAILURE,
  EDIT_LISTING__LOADING,
  EDIT_LISTING__SUCCESS,
  EDIT_LISTING__FAILURE,
  DELETE_LISTING__LOADING,
  DELETE_LISTING__SUCCESS,
  GET_UNVERIFIED_LISTINGS__REQUEST,
  VERIFY_LISTING__REQUEST,
} from './actions';


export function* addListing(action) {
  yield put({
    type: CREATE_LISTING__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const res = yield call(createListing, action.payload);
    yield put({
      type: CREATE_LISTING__SUCCESS,
      payload: {
        data: res.data,
      }
    });
  } catch (e) {
    console.error('Error creating new listing', e)
    yield put({
      type: CREATE_LISTING__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* editListing(action) {
  yield put({
    type: EDIT_LISTING__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const { payload } = action;
    const res = yield call(updateListing, payload);
    yield put({
      type: EDIT_LISTING__SUCCESS,
      payload: {
        data: res.data
      }
    });
  } catch (e) {
    console.error('Error updating listing', e)
    yield put({
      type: EDIT_LISTING__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* removeListing(action) {
  yield put({
    type: DELETE_LISTING__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const res = yield call(deleteListing, action.payload);
    yield put({
      type: DELETE_LISTING__SUCCESS,
      payload: {
        data: res.data,
      }
    });
  } catch (e) {
    console.error('Error deleting apartment listing', e)
    yield put({
      type: GET_LISTINGS__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* getListing(action) {
  const { payload } = action;
  const { id } = payload;

  let listings = yield select((state) => {
    return state.listings.data;
  });

  if (listings && listings.id) {
    yield put({
      type: GET_LISTING__SUCCESS,
      payload: listings.id
    });
  }
  else {
    try {
      yield put({
        type: GET_LISTING__LOADING,
        payload: {
          loading: true
        }
      });

      const res = yield call(fetchListing, id);
      yield put({
        type: GET_LISTING__SUCCESS,
        payload: res.data
      });
    } catch (e) {
      console.error('Error fetching listing', e)
      yield put({
        type: GET_LISTING__FAILURE,
        payload: {
          loading: false,
          success: false,
          completed: true,
          data: null
        }
      });
    }
  }
}

export function* getListings(action) {
  yield put({
    type: GET_LISTINGS__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const listings = yield call(fetchListingsByCreator, action.payload);
    yield put({
      type: GET_LISTINGS__SUCCESS,
      payload: {
        data: listings.data,
      }
    });
  } catch (e) {
    console.error('Error loading apartment list', e)
    yield put({
      type: GET_LISTINGS__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* getAllUnverifiedListings(action) {
  yield put({
    type: GET_LISTINGS__LOADING,
    payload: {
      loading: true
    }
  });

  try {
    const listings = yield call(fetchUnverifiedListings, action.payload);
    yield put({
      type: GET_LISTINGS__SUCCESS,
      payload: {
        data: listings.data,
      }
    });
  } catch (e) {
    console.error('Error loading apartment list', e)
    yield put({
      type: GET_LISTINGS__FAILURE,
      payload: {
        data: null
      }
    });
  }
}

export function* addListingSaga() {
  yield takeEvery(CREATE_LISTING__REQUEST, addListing);
}

export function* editListingSaga() {
  yield takeEvery(EDIT_LISTING__REQUEST, editListing);
}

export function* deleteListingSaga() {
  yield takeEvery(DELETE_LISTING__REQUEST, removeListing);
}

export function* getListingSaga() {
  yield takeEvery(GET_LISTING__REQUEST, getListing);
}

export function* getListingsSaga() {
  yield takeEvery(GET_LISTINGS__REQUEST, getListings);
}

export function* getUnverifiedListingsSaga() {
  yield takeEvery(GET_UNVERIFIED_LISTINGS__REQUEST, getAllUnverifiedListings);
}

export function* updateSearchPrefSaga() {
  yield takeEvery(SET_SEARCH_PREFS, getListings);
}

export function* verifyListingSaga() {
  yield takeEvery(VERIFY_LISTING__REQUEST, editListing);
}
