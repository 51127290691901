// import mockApartments from '../../mocks/ApartmentData';
import {
  CREATE_LISTING__FAILURE,
  CREATE_LISTING__LOADING,
  CREATE_LISTING__SUCCESS,
  DELETE_LISTING__FAILURE,
  DELETE_LISTING__LOADING,
  DELETE_LISTING__SUCCESS,
  EDIT_LISTING__FAILURE,
  EDIT_LISTING__LOADING,
  EDIT_LISTING__SUCCESS,
  // GET_LISTING__FAILURE,
  // GET_LISTING__LOADING,
  GET_LISTING__SUCCESS,
  GET_LISTINGS__FAILURE,
  GET_LISTINGS__LOADING,
  GET_LISTINGS__SUCCESS,
} from './actions';
// import {
//   APARTMENT_TYPES_STR,
//   APARTMENT_ZONES,
//   APARTMENT_TYPES_BOOL,
// } from '../../Constants';


const apartmentListReducer = (state = { completed: false, loading: false, data: [] }, { type, payload }) => {
  switch (type) {
    case CREATE_LISTING__LOADING || DELETE_LISTING__LOADING ||
      EDIT_LISTING__LOADING || GET_LISTINGS__LOADING:
      return {
        ...state,
        loading: true
      };

    case CREATE_LISTING__FAILURE:
      return {
        ...state,
        loading: false,
        completed: true,
        success: false,
        data: payload,
        source: 'create'
      };

    case CREATE_LISTING__SUCCESS:
      return {
        completed: true,
        loading: false,
        success: true,
        data: state.data.concat(payload.data),
        source: 'create'
      };

    case DELETE_LISTING__SUCCESS:
      return {
        completed: true,
        loading: false,
        success: true,
        deleted: payload,
        data: state.data && state.data.filter((listing) => listing._id !== payload.data._id),
        source: 'delete'
      };

    case EDIT_LISTING__FAILURE || DELETE_LISTING__FAILURE:
      return {
        ...state,
        completed: true,
        loading: false,
        success: false,
        source: 'edit'
      };

    case EDIT_LISTING__SUCCESS:
      let updatedState = state.data && state.data.filter((listing) => listing._id !== payload.data._id);
      return {
        completed: true,
        success: true,
        data: {
          ...updatedState,
          payload
        },
        source: 'edit'
      };

    case GET_LISTING__SUCCESS:
      let currentListings = state.data && state.data.filter((listing) => listing._id !== payload._id);
      const newListings = currentListings.concat(payload);
      return {
        ...state,
        ...{
          data: newListings
        }
      };

    case GET_LISTINGS__SUCCESS:
      return {
        ...state,
        completed: true,
        success: true,
        data: state.data.concat(payload.data)
      };

    case GET_LISTINGS__FAILURE:
      return {
        ...state,
        completed: true,
        success: false,
        data: null
      };

    default:
      return state;

    // case 'GET_MOCK_APT_LIST__SUCCEEDED':
    //   const { prefs, completed, success } = payload;

    //   let data = mockApartments['all'].filter((apartment) => {
    //     if (prefs['type'] && prefs['type'].length === 1) {
    //       if (prefs['type'][0] === 'all') {
    //         return apartment;
    //       }
    //       else if (prefs['type'][0] !== 'all' &&
    //         APARTMENT_TYPES_BOOL[prefs['type'][0]] && prefs['type'][0] === apartment.shortcode) {
    //         return apartment;
    //       }
    //     }
    //     else if (prefs['type'].length > 1) {
    //       if (prefs['type'].indexOf(apartment.shortcode) > -1) {
    //         return apartment;
    //       }
    //     }
    //   })
    //     .filter((apartment) => {
    //       if (!prefs['price']) {
    //         return apartment;
    //       }
    //       else {
    //         if (apartment.price <= prefs['price']) {
    //           return apartment;
    //         }
    //       }
    //     });

    //   let pageData = data.slice(prefs.start, prefs.end)
    //   return { completed, success, data, pageData };
  }
};

export default apartmentListReducer;
