import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HamburgerListItem from './HamburgerListItem';
import { logoutUser } from '../../containers/AuthForm/actions'
import classes from './styles.scss';

const HamburgerMenuList = ({ items, history, logoutUser, showMenu }) => {
	const handleClickItem = (e) => {
		const route = e.currentTarget.getAttribute('data-item-id');
		switch (route) {
			case 'messages':
			case 'appointments':
			case 'listings':
			case 'settings':
			case 'earnings':
				history.push(
					`/home/agent?selectedView=${route}`
				);
				break;
			case 'logout':
				logoutUser();
				break;
			default:
				break;
		}
	}

	const renderListItems = () => items.map((item) => <HamburgerListItem handleClickItem={handleClickItem} key={item.label} {...item} />);
	return (
		<div className={classes['hamburger-list-root']}
			style={showMenu ? { display: 'block' } : { display: 'none' }}>
			{renderListItems()}
		</div>
	)
};

const mapStateToProps = ({ windowWidth }) => {
	return {
		windowWidth
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		logoutUser: () => dispatch(logoutUser())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HamburgerMenuList));