import jwt from 'jsonwebtoken';
import moment from 'moment';
import QueryString from 'query-string';

export const AUTH_ERRORS = {
	CODEEXPIRED: `auth/code-expired`,
	EMAILTAKEN: `auth/email-already-in-use`,
	INVALIDEMAIL: `auth/invalid-email`,
	INVALIDPHONE: `auth/invalid-phone-number`,
	NETWORKUNAVAILABLE: `auth/network-request-failed`,
	TOKENEXPIRED: `auth/id-token-expired`,
	USERNOTFOUND: `auth/user-not-found`,
	WEAKPASSWORD: `auth/weak-password`
}

export const ScriptLoader = (src, cb) => {
	return new Promise(function (resolve, reject) {
		const s = document.createElement('script');
		let r = false;
		s.type = 'text/javascript';
		s.src = src;
		s.async = true;
		s.onerror = function (err) {
			reject(err, s);
		};
		s.onload = s.onreadystatechange = function () {
			if (!r && (!this.readyState || this.readyState === 'complete')) {
				console.log('Async call resolved');
				r = true;
				if (cb) {
					cb();
				}
				resolve();
			}
		};
		const t = document.getElementsByTagName('script')[0];
		t.parentElement.insertBefore(s, t);
	});
}


export const parseAuthErrors = (errorMessage) => {
	switch (errorMessage) {
		case AUTH_ERRORS.EMAILTAKEN:
			return { header: `Oopsie, that's a used email!`, body: `Are you sure you haven't signed up already?` };
		case AUTH_ERRORS.INVALIDEMAIL:
			return { header: `Err, that's an invalid email!`, body: `Please check the email you entered` };
		case AUTH_ERRORS.INVALIDPHONE:
			return { header: `Err, that's an invalid phone number!`, body: `Please check again` };
		case AUTH_ERRORS.NETWORKUNAVAILABLE:
			return { header: `Oh dear, looks like you are offline`, body: `Please check to confirm` };
		default:
			return { header: `Oh dear, an error occurred`, body: `It's not you, it's us` };
	}
}

export const geoCodeAddress = async (address) => {
	/*global google*/
	let geocoder = new google.maps.Geocoder();
	return new Promise((resolve, reject) => {
		console.log('Encoding address', address)
		geocoder.geocode({ 'address': address }, (results, status) => {
			if (status === 'OK') {
				const location = results[0].geometry.location;
				resolve({
					latitude: location.lat(),
					longitude: location.lng()
				});
			} else {
				alert('Geocode was not successful for the following reason: ' + status);
				reject(status);
			}
		});
	});
}

//A/B Testing
export const isTestMode = () => false;

const CTA_EARLYACCESS_BUTTON_TEXT = { top: 'Signup for Early Access', middle: 'Get Early Access Today', bottom: 'Signup today' };
const CTA_LIVE_BUTTON_TEXT = { top: 'Start Earning Today', middle: 'Start Earning today', bottom: 'Get started today' };

export const getCTAButtonText = () => isTestMode() ? CTA_EARLYACCESS_BUTTON_TEXT : CTA_LIVE_BUTTON_TEXT;
export const getCTASubHeader = () => isTestMode() ? 'Signup today for early access.' : 'We are rooting for you.';
export const getCTAButtonPath = () => isTestMode() ? '/early-access' : '/new';

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

export const strToNum = (str) => {
	if (/^\d+$/.test(str)) {
		return parseInt(str);
	}
	return null;
}

export const objectifyImageList = (images) => {
	let imageObj = {};
	images.forEach((image) => {
		imageObj[image.id] = image
	});
	return imageObj;
}

export const getAssetsDiff = (currentAssets, mergedAssets) => {
	const currentAssetsIDs = Object.keys(currentAssets);
	const mergedAssetsIDs = Object.keys(mergedAssets);
	let changedAssets = {
		toAdd: [],
		toDelete: [],
		doNothing: {}
	}

	currentAssetsIDs.forEach((uid) => {
		if (!mergedAssets[uid]) {
			changedAssets.toDelete.push(currentAssets[uid]);
		}
		else {
			changedAssets.doNothing[uid] = currentAssets[uid];
		}
	});

	mergedAssetsIDs.forEach((uid) => {
		if (!changedAssets.doNothing[uid]) {
			changedAssets.toAdd.push(mergedAssets[uid]);
		}
	});

	return changedAssets;
}

export const sortArray = (arr, prop) => {
	const sortedArray = arr.sort((a, b) => {
		if (a[prop] < b[prop]) {
			return -1;
		}
		if (a[prop] > b[prop]) {
			return 1;
		}
		return 0;
	});

	return sortedArray;
}

//Cookie Utils
export const getCookie = (name) => {
	function escape(s) { return s.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1'); };
	var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
	return match ? match[1] : null;
}

export const setCookie = (cname, cvalue, date) => {
	let d = new Date();
	d.setTime(d.getTime() + (date * 24 * 60 * 60 * 1000));
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const deleteCookie = (cname) => {
	document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}


//Date Utils
const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Seo", "Oct", "Nov", "Dec"];
export const getMonthFromISOString = (isoString) => {
	const arr = isoString.split('-');
	return MONTHS[parseInt(arr[1], 10) - 1]
}
export const getDayFromISOString = (isoString) => {
	const arr = isoString.split('-');
	return arr[2].substring(0, 2);
}
export const getYearFromISOString = (isoString) => {
	const arr = isoString.split('-');
	return arr[0];
}
export const getTimeFromISOString = (isoDateTime) => {
	return isoDateTime.split('T')[1].split(':')[0]
}
export const isValidAppointmentTime = (time) => {
	const isoTime = getTimeFromISOString(time);
	return isoTime < 18 && isoTime >= 9;
}

const dateStr = new Date().toISOString().split('T').shift();
const timeStr = '10:00';
//export const getDefaultShowingDate = () => moment(dateStr + ' ' + timeStr).add('7', 'days').format('MMM Do YYYY, h:mm A');
export const getDefaultShowingDate = () => moment(dateStr + ' ' + timeStr).add('7', 'days').format();



//Encoding Utils
export const constructAddressEncoded = (location) => {
	const stringTokens = location.split(/[ ,]+/).filter(Boolean);
	if (stringTokens.length > 3) {
		return `${stringTokens[0]}-${stringTokens[1]}-${stringTokens[2]}`;
	}
	else return `${stringTokens[0]}-${stringTokens[1] || 'uyo'}-${stringTokens[2] || 'aks'}`;
}

export const constructTypeEncoded = (type) => {
	if (type === 'self') {
		return `${type}-contained-apartment`;
	}
	else return `${type}-bedroom-apartment`;
}

//Location Utils
export const getSelectedView = (location) => getLocationQueryParamValue(location, 'selectedView');

export const getLocationQueryParamValue = (location, param) => QueryString.parse(location.search)[param];


//Token Utils
export const generateAccessToken = () => {
	return jwt.sign(
		{ origin: 'ha-web-server' },
		'k@y!$Qu33N', //TODO: Move this to env variable
		{ expiresIn: '1h' }
	);
}