import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Calendar from '@material-ui/icons/Today';
import Edit from '@material-ui/icons/Edit';
import People from '@material-ui/icons/PeopleAlt';
import {
	getDayFromISOString,
	getMonthFromISOString
} from '../../Utils';
import './styles.scss';

class AppointmentScheduler extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editMode: props.editMode,
			selectedDate: '',
			selectedTime: ''
		}
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangeTime = this.handleChangeTime.bind(this);
		this.handleModify = this.handleModify.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleViewAppointmentsForListing = this.handleViewAppointmentsForListing.bind(this);
	}

	clearErrors = () => {
		this.setState({
			error: false,
			errorType: '',
			errorText: ''
		});
	}

	handleChangeDate = (e) => {
		const { error } = this.state;
		if (error) { this.clearErrors(); }
		this.setState({ selectedDate: e.target.value });
	}

	handleChangeTime = (e) => {
		const { error } = this.state;
		if (error) { this.clearErrors(); }
		const index = e.nativeEvent.target.selectedIndex;
		console.log(index, e.nativeEvent.target[index].value);
		this.setState({ selectedTime: e.nativeEvent.target[index].value });
	}

	handleModify = () => {
		this.setState({ editMode: true });
	}

	handleSubmit = () => {
		const TODAYS_DATE = new Date().toISOString();
		const { existingAppointment, onSubmit } = this.props;
		const { selectedDate, selectedTime } = this.state;

		if (!selectedDate) {
			this.setState({
				error: true,
				errorType: 'date',
				errorText: 'Please select a date'
			});
		}
		else if (new Date(selectedDate).toISOString() <= TODAYS_DATE) {
			this.setState({
				error: true,
				errorType: 'date',
				errorText: 'Please select a date after today'
			});
		}
		else if (!selectedTime) {
			this.setState({
				error: true,
				errorType: 'time',
				errorText: 'Please select a time'
			});
		}
		else {
			onSubmit(selectedDate, selectedTime, existingAppointment);
		}
	}

	handleViewAppointmentsForListing = () => {
		const { existingAppointment, history, onViewAll } = this.props;
		onViewAll();
		history.push(
			`/home?selectedView=appointments&listingId=${existingAppointment.listingId}`
		);
	}

	render() {
		const { editMode, error, errorType, errorText } = this.state;
		const { existingAppointment, windowWidth } = this.props;
		let isVerificationAppointment, listingStatus, numOfAttendees;

		if (existingAppointment) {
			isVerificationAppointment = existingAppointment.isVerification;
			listingStatus = existingAppointment.listingStatus;
			numOfAttendees = existingAppointment.attendees ? existingAppointment.attendees.length : 0
		}

		const renderModalText = (listingStatus) => {
			if (listingStatus === 'VERIFIED') {
				return <p className='ta-center margin-t16'>You have an upcoming <b><u>showing</u></b> appointment for this listing at <b>{moment(existingAppointment.isoDateTime).format('LT')}</b> on</p>
			}
			return <p className='ta-center margin-t16'>You have an upcoming <b><u>verification</u></b> appointment for this listing at <b>{moment(existingAppointment.isoDateTime).format('LT')}</b> on</p>
		}

		const renderButtons = () => {
			if (listingStatus === 'VERIFIED') {
				return (
					<div className='flex-center width-p100'>
						<button className={`button draft ta-center`}
							style={{ marginTop: 32 }}
							disabled={error}
							onClick={this.handleViewAppointmentsForListing}
							aria-label='Close modal' type='button'>
							View All {windowWidth > 560 ? 'Appointments' : ''}
						</button>
						<button className={`button cta ta-center`}
							style={{ marginTop: 32 }}
							disabled={error}
							onClick={this.handleModify}
							aria-label='Close modal' type='button'>
							Add New {windowWidth > 560 ? 'Appointment' : ''}
						</button>
					</div>
				);
			}
			else { //TBD: DO YOU WANT PEOPLE TO BE ABLE TO MODIFY A VERIFICATION APPOINTMENT??
				return (
					<div className='flex-center width-p100'>
						{/* <button className={`button cta ta-center`}
							style={{ marginTop: 32 }}
							disabled={error}
							onClick={this.handleModify}
							aria-label='Close modal' type='button'>
							Modify {windowWidth > 560 ? 'Appointment' : ''}
						</button> */}
					</div>
				);

			}
		}

		const renderContent = () => {
			if (editMode || !existingAppointment) {
				return (
					<div className='width-p100'>
						<div className='modal-header appointment-modal-header'>
							<Calendar aria-label='Appointments' alt='Schedule Appointment Header' style={{ fontSize: 44, color: 'white' }} />
							<p className='modal-header ta-center'>{editMode ? 'Edit Appointment' : 'Appointment Manager'}</p>
							{/* <p className='modal-header ta-center'>Appointment Manager</p> */}
						</div>
						<div className='modal-content'>
							<p className='ta-center fw-700'>
								{isVerificationAppointment
									? 'This listing has not been verified yet.'
									: existingAppointment
										? numOfAttendees
											? `${numOfAttendees} renter(s) have already signed up for this appointment.` : 'No renters have signed up yet.'
										: 'You have no appointment for this listing at the moment. '}
							</p>
							<p className='ta-center'>Please select a {existingAppointment && 'new'} date and time when {isVerificationAppointment ? 'we can come to verify this listing' : 'renters can come see this listing'}</p>
							<div className='flex-column-center width-p100 margin-t32 padding-16'>
								<div className='form-field-root'>
									<label className='fw-600 margin-r8'>{windowWidth > 480 ? 'Choose' : ''} Date: </label>
									<input type='date' className='form-field'
										onChange={this.handleChangeDate}
										min={moment().format(moment.HTML5_FMT.DATE)}
										max={moment().add(14, 'day').format(moment.HTML5_FMT.DATE)}
										style={errorType === 'date' ? { border: '1px solid red ' } : {}}
									/>
								</div>
								<div className='form-field-root'>
									<label className='fw-600 margin-r8'>{windowWidth > 480 ? 'Choose' : ''} Time: </label>
									<select onChange={this.handleChangeTime} className='form-field'
										style={errorType === 'time' ? { border: '1px solid red ' } : {}}>
										<option>--Choose time---</option>
										<option value='9:00'>9.00 am</option>
										<option value='9:30'>9.30 am</option>
										<option value='10:00'>10.00 am</option>
										<option value='10:30'>10.30 am</option>
										<option value='11:00'>11.00 am</option>
										<option value='11:30'>11.30 am</option>
										<option value='12:00'>12.00 noon</option>
										<option value='12:30'>12.30 pm</option>
										<option value='13:00'>1.00 pm</option>
										<option value='13:30'>1.30 pm</option>
										<option value='14:00'>2.00 pm</option>
										<option value='14:30'>2.30 pm</option>
										<option value='15:00'>3.00 pm</option>
										<option value='15:30'>3.30 pm</option>
										<option value='16:00'>4.00 pm</option>
										<option value='16:30'>4.30 pm</option>
										<option value='17:00'>5.00 pm</option>
									</select>
								</div>
								<span className='margin-t16' role='alert' style={{ color: 'red' }}>{errorText}</span>
								<div className='flex-center width-p100'>
									<button className={`button primary btn-cta ta-center`}
										style={{ fontSize: 18, height: 50, marginTop: 32 }}
										disabled={error}
										onClick={this.handleSubmit}
										aria-label='Close modal' type='button'>
										Schedule
								</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
			else {
				return (
					<div className='width-p100'>
						<div className='modal-header appointment-modal-header'>
							<Calendar aria-label='Appointments' alt='Schedule Appointment Header' style={{ fontSize: 48, marginRight: '1rem', color: 'white' }} />
							<p className='modal-header ta-center'>Appointment Manager</p>
						</div>
						<div className='flex-column-center modal-content width-p100'>
							{renderModalText(listingStatus)}
							<div className='calendar-root'>
								<div className='month'>{getMonthFromISOString(existingAppointment.isoDateTime)}</div>
								<div className='day'>{getDayFromISOString(existingAppointment.isoDateTime)}</div>
								<button
									className='appointment-edit-btn'
									title='Edit Appointment'
									onClick={this.handleModify}
								>
									<Edit />
								</button>
							</div>

							<div className='people-count' title='Number of attendees'>
								<People />  {numOfAttendees}
							</div>

							<span className='margin-t16' role='alert' style={{ color: 'red' }}>{errorText}</span>
							{renderButtons()}
						</div>
					</div>
				);
			}
		}

		return renderContent();
	}
}


const mapStateToProps = store => {
	return {
		windowWidth: store.windowWidth
	}
};

export default connect(mapStateToProps, {})((withRouter(AppointmentScheduler)));

