import QueryString from 'query-string';

/** Constants */
export const HA_USER_TOKEN = `_ha-user-token_`;

export const AUTH_ERRORS = {
  ACTIONCODEEXPIRED: 'auth/expired-action-code',
  CODEEXPIRED: 'auth/code-expired',
  EMAILTAKEN: 'auth/email-already-in-use',
  INVALIDACTIONCODE: 'auth/invalid-action-code',
  INVALIDEMAIL: 'auth/invalid-email',
  INVALIDPHONE: 'auth/invalid-phone-number',
  NETWORKUNAVAILABLE: 'auth/network-request-failed',
  TOKENEXPIRED: 'auth/id-token-expired',
  USERNOTFOUND: 'auth/user-not-found',
  WEAKPASSWORD: 'auth/weak-password',
  NOACCESSTOKEN: 'Unauthorized access. No access token found'
}

export const parseAuthErrors = (errorMessage) => {
  switch (errorMessage) {
    case AUTH_ERRORS.ACTIONCODEEXPIRED:
      return 'The link you provided is expired. Please enter your email address to request a new password reset link'
    case AUTH_ERRORS.CODEEXPIRED:
      return 'How sad, the code you provided has expired. Please go back and try logging in again'
    case AUTH_ERRORS.INVALIDACTIONCODE:
      return 'The link you provided is invalid. Please enter your email address to request a new password reset link'
    case AUTH_ERRORS.EMAILTAKEN:
      return `Oopsie! This email address is already in use. If it's yours, click login below to login`
    case AUTH_ERRORS.INVALIDEMAIL:
      return 'You provided an invalid email address. Please check the email you entered	';
    case AUTH_ERRORS.INVALIDPHONE:
      return 'You provided an invalid phone number. Please check again'
    case AUTH_ERRORS.NETWORKUNAVAILABLE:
      return `Oh dear, a network error occurred. Please check if you're offline.`
    case AUTH_ERRORS.USERNOTFOUND:
      return 'The email you provided is not linked to any account, please check again'
    case AUTH_ERRORS.WEAKPASSWORD:
      return 'Password should be at least six characters. We like long secrets :)'
    default:
      return `Oopsie! An error just occurred - it's not you, it's us. Please try again`;
  }
}

export const constructAuthURL = (authType, location, authProvider, typeOfUser) => {
  let agentEmail = QueryString.parse(location.search)['agent_email'];
  let isSaveListing = QueryString.parse(location.search)['save_listing'];
  let redirectUrl = QueryString.parse(location.search)['redirect_url'];
  let userType = typeOfUser || QueryString.parse(location.search)['user_type'];
  let authURL = `/${authType}`;

  if (authProvider === 'phone') {
    authURL = `/auth/phone?type=${authType}`;
  }
  else if (authProvider === 'phone-verify') {
    authURL = `/auth/phone-verify?type=${authType}`;
  }
  else {
    authURL = `/${authType}?type=${authType}`;
  }
  if (agentEmail) {
    authURL = `${authURL}&agent_email=${agentEmail}`;
  }
  if (isSaveListing) {
    authURL = `${authURL}&save_listing=${isSaveListing}`;
  }
  if (userType) {
    authURL = `${authURL}&user_type=${userType}`;
  }
  if (redirectUrl) {
    authURL = `${authURL}&redirect_url=${redirectUrl}`;
  }
  return authURL;
}