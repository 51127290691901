import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Add from '@material-ui/icons/AddCircle';
import Earn from '@material-ui/icons/AttachMoney';
import LELogo from '../../assets/brand/LELogo.png';
import LELogoMobile from '../../assets/brand/LEIcon.png';
import { setWindowWidth } from './actions';
import './styles.scss';


class LoggedOutNav extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hamburgerMenu: false,
      toggleClassName: this.props.navBarDisplay
    };
    this.toggleHamburger = this._toggleHamburgerMenu.bind(this)
  }

  _initAddListing() {
    console.log('Fired');
  }

  _toggleHamburgerMenu() {
    this.setState({
      hamburgerMenu: !this.state.hamburgerMenu
    })
  }

  render() {
    const { history, windowWidth } = this.props;
    const { hamburgerMenu } = this.state;
    let pathname = history.location.pathname;
    const addIconStyle = (type) => {
      let plusIconStyle = { fontSize: 28, fontWeight: 700, marginRight: -8 };
      if (type === 'cta') {
        plusIconStyle = {
          ...plusIconStyle,
          fill: 'black'
        }
      }
      else if (type === 'add') {
        plusIconStyle = {
          ...plusIconStyle,
          fontSize: 16,
          marginRight: 8,
          fill: 'black'
        }
      }
      else if (type === 'ctaLarge') {
        plusIconStyle = {
          ...plusIconStyle,
          fontSize: 32,
          cursor: 'pointer',
          marginRight: 8,
          color: 'black',
          fill: '#F7BF23'
        }
      }
      else {
        plusIconStyle = { ...plusIconStyle, fill: 'white' }
      }
      return plusIconStyle;
    }

    let displayCTABtn = () => {
      if (pathname.indexOf('/new') !== -1) {
        if (windowWidth >= 480) {
          return (
            <li>
              <Link className="padding-r32 btn-m cta-nav-btn br-3 color-white margin-r4"
                to='/new?selectedView=earnings'>
                <Earn style={addIconStyle('cta')} />tart Earning
              </Link>
            </li>
          );
        }
        else {
          return (
            <li>
            </li>
          );
        }
      }
      else if (pathname.indexOf('/home') !== -1) {
        return (
          <li>
            <button className='button cta'
              onClick={this._initAddListing} aria-label='Add Listing' tabIndex={0}>
              <Add style={addIconStyle('add')} />
              Create Listing
              </button>
          </li>
        );
      }
      else {
        return (
          <li>
            <Link className="padding-r32 btn-m cta-nav-btn br-3 color-white margin-r4"
              to='/new?selectedView=earnings'>
              <Earn style={addIconStyle('cta')} />tart Earning
            </Link>
          </li>
        );
      }
    }

    return (
      <div className='nav-header height-60 flex-row space-between align-center width-p100 mw-1280 margin-auto'>
        <Link className='flex-row-align-center' to="/">
          {
            windowWidth > 660 ?
              <img src={LELogo} height={40} alt='list-and-earn-logo' aria-label='List and Earn Logo' />
              :
              <img src={LELogoMobile} height={44} alt='list-and-earn-logo' aria-label='List and Earn Logo' />
          }
        </Link>
        <nav className='flex'>
          <ul className='flex-row-align-center'>
            <li>
              <Link className={'navbar-link display-mobile-none'}
                to="/#howitworks">
                How It Works
              </Link>
            </li>
            {/* <li>
              <Link className={'navbar-link display-mobile-none'}
                to="/onboarding/agent">
                Contact Us
              </Link>
            </li> */}
            {/* <li>
              <Link className={'navbar-link display-mobile-none'}
                to="/login">
                Login
                </Link>
            </li> */}
            {displayCTABtn()}
          </ul>
          <div className={`hamburger-menu ${hamburgerMenu ? 'selected' : ''}`}
            role='button' onClick={this.toggleHamburger}>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    //pathname: store.routing.location.pathname,
    authState: store.authState,
    signupBtnAsLink: store.signupBtnState,
    windowWidth: store.windowWidth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWindowWidth: (width) => dispatch(setWindowWidth(width)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedOutNav));
//export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoggedOutNav));
