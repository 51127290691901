import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classes from './styles.scss';

const HamburgerListItem = ({ label, itemId, handleClickItem, notificationCount }) => {
	return (
		<div className={`${classes['hamburger-list-item']}`}
			data-item-id={itemId}
			role='button'
			onClick={handleClickItem}
			tabIndex={0}
		>
			<div>{label}</div>
			{
				notificationCount > 0 &&
				<div className={`${classes['item-notification']}`}>{notificationCount}</div>
			}
		</div>
	)
};

const mapStateToProps = ({ windowWidth }) => {
	return {
		windowWidth
	}
}

export default connect(mapStateToProps, {})(withRouter(HamburgerListItem));