import {
  LOGOUT_USER__SUCCESS,
  SET_AUTH_USER
} from './actions';

const initialAuthState = {
  loading: false,
  user: null
}

const authReducer = (initialState = initialAuthState, { type, payload }) => {
  switch (type) {
    case SET_AUTH_USER:
      return {
        loading: false,
        user: payload
      }
    case LOGOUT_USER__SUCCESS:
      return { ...initialState, ...{ type: payload.type } }
    default:
      return initialState;
  }
};

export default authReducer;
