import React, { Component } from 'react';
import PropTypes from 'prop-types';


class HASnackbar extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: true,
    }
  }

  componentDidMount() {
    let self = this;
    if (this.props.duration !== 'indefinite') {
      this.timeOutId = setTimeout(() => {
        self.setState({ show: false });
      }, 5000);
    }
  }

  componentWillUnmount() {
    if (this.timeOutId) {
      this.setState({ show: false });
      clearTimeout(this.timeOutId);
    }
  }

  _handleCloseButton() {
    this.setState({ show: false });
  }

  render() {
    const { buttonText, handleBtnClick, message, type, withButton } = this.props;
    const rootStyle = {
      background: '#FCEBBD',
      display: this.state.show ? 'block' : 'none',
      fontSize: 16,
      maxWidth: 1280,
      margin: '8px auto',
      padding: 4,
      position: 'relative',
      borderRadius: 4,
      textAlign: 'center',
      width: '100%',
      color: type === 'error' ? 'red' : 'inherit'
    }
    const closeBtnStyle = {
      cursor: 'pointer',
      position: 'absolute',
      right: 8,
      color: '#354052',
      fontSize: 16
    }
    const messageRootStyle = {
      padding: '12px 16px'
    }

    return (
      <div style={rootStyle}>
        <div style={closeBtnStyle} onClick={this._handleCloseButton.bind(this)}>x</div>
        <div style={messageRootStyle}>
          {message || this.props.children}
        </div>
        {
          withButton &&
          <button className='btn-link'
            onClick={handleBtnClick}
          >
            {buttonText}
          </button>
        }
      </div>
    );
  }
}

HASnackbar.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  withButton: PropTypes.bool
};

HASnackbar.defaultProps = {
  type: 'accent',
  withButton: false
};

export default HASnackbar;
