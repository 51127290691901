import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Datetime from 'react-datetime';
import { v4 as uuid } from 'uuid';
import Help from '@material-ui/icons/Help';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepIcon } from '../../components/Stepper/StepIcon';
import StepConnector from '@material-ui/core/StepConnector';
import QueryString from 'query-string';

import AssetUploader from '../../components/AssetUploader';
import Dropdown from '../../components/Dropdown/main';
import Snackbar from '../../components/Snackbar';
import { createListing } from '../../pages/listings/actions';
import {
	DEFAULT_IMAGE_VALUE,
	DEFAULT_MINIMUM_DESC_LENGTH,
	DEFAULT_MINIMUM_DESC_VALUE,
	DEFAULT_MINIMUM_NUM_IMAGES,
	DEFAULT_VIDEO_VALUE,
	LISTING_REGIONS,
	TEST_USER
} from '../../Constants';
import {
	constructAddressEncoded,
	constructTypeEncoded,
	geoCodeAddress,
	getDefaultShowingDate,
	isValidAppointmentTime,
} from '../../Utils';
import './styles.scss';


const StepConnectorLine = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		},
	},
	completed: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);

const BUTTON_LABELS = ['Next (Description)', 'Next (Rent)'];

const getSteps = (mode) => {
	return [
		{
			desktop: `${mode === 'add' ? 'Add' : 'Edit'} images and video of listing`,
			mobile: `${mode === 'add' ? 'Add' : 'Edit'} Media`
		},
		{
			desktop: `${mode === 'add' ? 'Include' : 'Edit'} details about listing`,
			mobile: 'Listing Details'
		},
		{
			desktop: `${mode === 'add' ? 'Add' : 'Edit'} rent and payment information`,
			mobile: 'Rent & Fees'
		},
	];
}



class UploadForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			activeStep: 0,
			description: '',
			dateOfShowing: null,
			images: {},
			hasAccessRoad: false,
			hasLight: false,
			hasWater: false,
			location: '',
			fee: '',
			listingId: '',
			rent: '',
			video: [],
		};
		this._createListing = this._createListing.bind(this);
		this._deleteVideo = this._deleteVideo.bind(this);
		this._handleDeleteImage = this._handleDeleteImage.bind(this);
		this._handleDrop = this._handleDrop.bind(this);
		this._handleLocationBlur = this._handleLocationBlur.bind(this);
		this._handleSelectAgent = this._handleSelectAgent.bind(this);
		this._handleSelectAxis = this._handleSelectAxis.bind(this);
		this._handleSelectType = this._handleSelectType.bind(this);
		this._handleSelectDateModeChange = this._handleSelectDateModeChange.bind(this);
		this._handleSelectShowingDate = this._handleSelectShowingDate.bind(this);
		this._handleVideoFileDialogCancel = this._handleVideoFileDialogCancel.bind(this);
		this._setImageTitle = this._setImageTitle.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.handleNext = this.handleNext.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		const { currentListing } = props;
		const { listingId } = state;

		if (currentListing && currentListing._id !== listingId) {
			const {
				_id,
				dateOfShowing,
				description,
				fee,
				hasAccessRoad,
				hasLight,
				hasWater,
				images,
				location,
				rent,
				video,
			} = currentListing;

			return {
				..._id && { listingId: _id },
				...dateOfShowing && { dateOfShowing },
				...description && { description },
				...fee && { fee },
				...hasAccessRoad && { hasAccessRoad },
				...hasLight && { hasLight },
				...hasWater && { hasWater },
				...images && { images },
				...location && { location },
				...rent && { rent },
				...video && { video },
			}
		}
		return null;
	}

	componentDidMount() {
		const { currentListing } = this.props;
		const { listingId } = this.state;
		if (listingId !== currentListing._id) {
			this.setState({ listingId: currentListing._id });
		}
		this.scrollToTop();
	}

	componentWillUnmount = () => {
		const { onAutoSave } = this.props;
		const {
			dateOfShowing,
			description,
			fee,
			images,
			hasAccessRoad,
			hasLight,
			hasWater,
			listingId,
			location,
			rent,
			video
		} = this.state;

		onAutoSave({
			_id: listingId,
			dateOfShowing,
			description,
			fee,
			hasAccessRoad,
			hasLight,
			hasWater,
			images,
			location,
			rent,
			video
		});
	}

	async _geoCodeListingAddress(address) {
		const latlng = await geoCodeAddress(`${address}, Uyo`);
		this.setState({ latlng });
	}

	_clearAllFormFieldErrors(cb) {
		this.setState({
			typeError: false,
			typeFieldErrorText: '',
			locationError: false,
			locationFieldErrorText: '',
			descriptionError: false,
			descriptionFieldErrorText: '',
			dateError: false,
			dateFieldErrorText: '',
			feeError: false,
			feeFieldErrorText: '',
			amountError: false,
			amountFieldErrorText: '',
		}, () => {
			if (cb) {
				cb();
			}
		});
	}

	_clearFieldError(type) {
		let field = `${type}Error`;

		if (this.state[field]) {
			let fieldErrorText = `${type}FieldErrorText`;
			this.setState({
				[field]: false,
				[fieldErrorText]: ''
			});
		}
	}

	_createListing(e) {
		e.preventDefault();
		this.validateListingForm();
	}

	_deleteVideo() {
		this.setState({ video: [] });
	}

	handleBack = () => {
		const { activeStep } = this.state;
		this.updateActiveStep(activeStep - 1);
	};

	_handleDeleteImage(uid) {
		const { images } = this.state;
		let state = { ...images };
		delete state[uid];
		this.setState({ images: state });
	}

	handleDiscardDraft = () => {
		const { onDiscardDraft } = this.props;
		this._resetForm();
		onDiscardDraft();
	}

	_handleDrop(type, files) {
		this._clearFieldError(type);

		let fieldError = `${type}Error`;
		let fieldErrorText = `${type}FieldErrorText`;

		if (files.length === 0) {
			this.setState({
				[fieldError]: true,
				[fieldErrorText]: 'Oopsie! Looks like you attached invalid file(s)'
			});
		}
		else {
			let fileState = {};
			files.forEach((file) => {
				const { name, preview } = file;
				const id = uuid();
				fileState = {
					...fileState,
					...{
						[id]: {
							id,
							data: file,
							url: preview,
							name,
							type
						}
					}
				}
			});
			this.setState({
				[type]: { ...this.state[type], ...fileState }
			});
		}
	}

	handleNext = () => {
		const { activeStep } = this.state;
		this.handleValidateData(activeStep, activeStep + 1, true);
	};

	handleValidateData = (activeStep, nextStep, scrollToTop) => {
		const VALIDATION_METHODS = [
			'validateAssets',
			'validateDescription',
		]

		if (nextStep < activeStep) {
			this.updateActiveStep(nextStep);
			if (scrollToTop) {
				this.scrollToTop();
			}
		}
		else if (nextStep > activeStep) {
			if (this[VALIDATION_METHODS[activeStep]]()) {
				this.updateActiveStep(activeStep + 1);
				if (scrollToTop) {
					this.scrollToTop();
				}
			}
		}
	}

	_handleFieldChange = (type, e) => {
		this._clearFieldError(type);
		this.setState({ [type]: e.target.value });
	}

	_handleLocationBlur = (e) => {
		const { location, latlng } = this.state;
		if ((location === e.target.value && !latlng)
			|| location !== e.target.value) {
			//this._geoCodeListingAddress(e.target.value);
		}
	}

	_handleMenuOpen(type, e) {
		this._clearFieldError(type);
	}

	_handleSelectAgent(agent) {
		this.setState({ agent: agent.value });
	}

	_handleSelectAxis(axis) {
		this.setState({ axis });
	}

	_handleSelectDateModeChange = (args) => {
		console.log(args);
	}

	_handleSelectShowingDate = (dateOfShowing) => {
		const formattedDateTime = moment(dateOfShowing).format();

		if (!isValidAppointmentTime(formattedDateTime)) {
			this.setState({
				dateError: true,
				dateFieldErrorText: 'Please select a time between 9.00am and 6.00pm',
			});
		}
		else {
			const { dateError } = this.state;
			if (dateError) {
				this.setState({
					dateError: false,
					dateFieldErrorText: ''
				});
			}
			this.setState({ dateOfShowing: formattedDateTime });
		}
	}

	_handleSelectType(type) {
		this.setState({ type });
	}

	_handleVideoFileDialogCancel() {
		this.props.onVideoFileDialogCancel();
	}

	_handleInputKeyPress(_, e) {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	}

	_handleCheckFacility(type) {
		let attr = `has${type}`;
		this.setState({
			[attr]: !this.state[attr]
		});
	}

	_resetForm() {
		this._clearAllFormFieldErrors();
		this.setState({
			amount: 0,
			date: getDefaultShowingDate(),
			fee: 0,
			description: '',
			hasAccessRoad: false,
			hasLight: false,
			hasWater: false,
			images: {},
			location: '',
			video: {},
		});

		const locationFieldEl = document.getElementById('apartment-location');
		if (locationFieldEl)
			locationFieldEl.value = '';

		const feeFieldEl = document.getElementById('agent-fee');
		if (feeFieldEl)
			feeFieldEl.value = '';

		const rentFieldEl = document.getElementById('apartment-rent');
		if (rentFieldEl)
			rentFieldEl.value = '';

		const descFieldEl = document.getElementById('apartment-desc');
		if (descFieldEl)
			descFieldEl.value = '';
	}

	scrollToTop() {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}, 500);
	}

	_setImageTitle(uid, _, e) {
		const { images } = this.state;
		let name = e.target.value;
		let updatedImages = { ...images };
		updatedImages[uid].updated = name;
		this.setState({ images: updatedImages });
	}

	selectStep = (nextStep) => {
		const { activeStep } = this.state;
		this.handleValidateData(activeStep, nextStep, false);
	}

	updateActiveStep = (activeStep) => {
		this.setState({ activeStep })
	}

	validateAssets = () => {
		const { images } = this.state;
		if (Object.keys(images).length < DEFAULT_MINIMUM_NUM_IMAGES) {
			this.setState({
				imagesError: true,
				imagesFieldErrorText: 'Please upload at least 5 images',
			});
			return false;
		}
		return true;
	}

	validateDescription = () => {
		const { axis, description, location, type } = this.state;
		let isValid = true;

		if (!axis || !description || !location || !type) {
			if (!type) {
				this.setState({
					typeError: true,
					typeFieldErrorText: 'Please select a listing type',
				});
				isValid = false;
			}
			if (!location) {
				this.setState({
					locationError: true,
					locationFieldErrorText: 'Oopsie! You forgot to give the listing a location',
				});
				isValid = false;
			}
			if (!axis) {
				this.setState({
					axisError: true,
					axisFieldErrorText: 'Please indicate what axis the listing is',
				});
				isValid = false;
			}
			if (!description) {
				this.setState({
					descriptionError: true,
					descriptionFieldErrorText: `Sure you don't want to put in a description`,
				});
				isValid = false;
			}
		}
		return isValid;
	}

	validateListingForm = () => {
		const { _id, agent, axis, dateOfShowing, description, fee, images, hasAccessRoad, hasLight, hasWater, latlng, location, rent, type, video } = this.state;
		const { role } = this.props;

		if (!rent || !dateOfShowing || !fee) {
			if (!rent) {
				this.setState({
					rentError: true,
					rentFieldErrorText: 'Thanks for your good heart but, we both know this isn\'t free',
				});
			}
			if (!dateOfShowing) {
				this.setState({
					dateError: true,
					dateFieldErrorText: 'Please set a date when we can come for verification',
				});
			}
			if (!fee) {
				this.setState({
					feeError: true,
					feeFieldErrorText: 'Please enter your agent fee',
				});
			}
		}
		else if (!isValidAppointmentTime(dateOfShowing)) {
			this.setState({
				dateError: true,
				dateFieldErrorText: 'Please select a time between 9.00am and 6.00pm',
			});
		}
		else if (role === 'admin' && !agent) {
			this.setState({
				agentError: true,
				agentFieldErrorText: 'Please select an agent first',
			});
		}
		else {
			const { authState = { user: TEST_USER }, onUpload, openVideoModal } = this.props;
			let latitude, longitude;
			if (latlng) {
				latitude = latlng.latitude;
				longitude = latlng.longitude;
			}

			let listing = {
				...(_id && { _id }),
				...(authState && authState.user && {
					agent: authState.user.name,
					agentId: authState.user.uid, //TODO: Refactor this for admin
					creatorId: authState.user.uid
				}),
				addressEncoded: constructAddressEncoded(location),
				city: 'uyo', //TODO: Update this
				dateOfShowing,
				description,
				fee,
				hasAccessRoad,
				hasLight,
				hasWater,
				images,
				...(latitude && { latitude }),
				likes: 0,
				...(longitude && { longitude }),
				location,
				posterImage: images[Object.keys(images)[0]],
				rent,
				status: 'UNVERIFIED',
				shortcode: type.value,
				type: type.label,
				typeEncoded: constructTypeEncoded(type.value),
				verified: false,
				video,
				views: 0,
				zone: axis
			};

			if (video.length === 0) {
				openVideoModal(listing, agent);
			}
			else {
				onUpload(listing, agent);
			}
		}
	}

	render() {
		const { location, openVideo, role, windowWidth } = this.props;
		const {
			activeStep,
			agentFieldErrorText,
			axis,
			axisError,
			axisFieldErrorText,
			dateError,
			dateFieldErrorText,
			dateOfShowing,
			description,
			descriptionError,
			descriptionFieldErrorText,
			fee,
			feeError,
			feeFieldErrorText,
			hasAccessRoad,
			hasLight,
			hasWater,
			images,
			imagesError,
			imagesFieldErrorText,
			location: locationText,
			locationError,
			locationFieldErrorText,
			rent,
			rentError,
			rentFieldErrorText,
			typeFieldErrorText,
			type,
			video,
			videoError,
		} = this.state;

		const fromSignUp = QueryString.parse(location.search)['signup'] === 'true';
		const errorStyle = { border: '1px solid red' };
		const hasJustMinReqImages = images && Object.keys(images).length === DEFAULT_MINIMUM_NUM_IMAGES;
		const hasVideoContent = video && Object.keys(video).length > 0;
		const selectedView = QueryString.parse(location.search)['selectedView'];
		const steps = getSteps(selectedView);

		const getDescriptionLength = () => description.length; //description ? description.split(' ').length : 0;

		const getListingValue = () => {
			let listingValue = Object.keys(images).length * DEFAULT_IMAGE_VALUE;
			if (hasVideoContent) {
				listingValue = listingValue + DEFAULT_VIDEO_VALUE;
			}
			if (getDescriptionLength() > DEFAULT_MINIMUM_DESC_LENGTH) {
				listingValue = listingValue + DEFAULT_MINIMUM_DESC_VALUE;
			}
			return listingValue;
		}

		const getStepContent = (step) => {
			switch (step) {
				case 0:
					return (
						<section className='apartment-details'>
							<div className='flex-column'>
								<AssetUploader
									images={images}
									video={video}
									openVideo={openVideo}
									imageError={imagesError}
									imageErrorText={imagesFieldErrorText}
									videoError={videoError}
									onDrop={this._handleDrop}
									deleteImage={this._handleDeleteImage}
									deleteVideo={this._deleteVideo}
									setImageTitle={this._setImageTitle}
									onVideoFileDialogCancel={this._handleVideoFileDialogCancel}
								/>
							</div>
						</section>
					);
				case 1:
					return (
						<section className='apartment-details'>
							<p className='form-subheader ta-center margin-b16 fw-600'>Listing Details</p>
							<div className='bg-bluegray br-8 padding-b16'>
								<div className='apartment-metadata'>
									<div className='apartment-details-child margin-tb16'>
										<label className='paragraph-xs fw-600'>Listing Type</label>
										<Dropdown error={typeFieldErrorText}
											placeholder='Select type'
											selectedItem={type}
											onMenuOpen={this._handleMenuOpen.bind(this, 'type')}
											onChange={this._handleSelectType.bind(this)} />
										<span id='error-type-field' role='alert' className='error'>
											{typeFieldErrorText}
										</span>
									</div>

									<div className='apartment-details-child margin-tb16'>
										<label htmlFor='apartment-location' className='paragraph-xs margin-b8 fw-600'>Location</label>
										<input className={`ha-input`} type='text'
											id='apartment-location'
											placeholder={`Enter listing address`}
											onBlur={this._handleLocationBlur}
											onChange={this._handleFieldChange.bind(this, 'location')}
											value={locationText}
											style={locationError ? errorStyle : {}}
											aria-describedby='error-location-field'
											aria-required='true' />
										<span id='error-location-field' role='alert' className='error'>
											{locationFieldErrorText}
										</span>
									</div>

									<div className='apartment-details-child margin-tb16'>
										<label className='paragraph-xs fw-600'>Axis</label>
										<Dropdown error={axisError}
											placeholder='Select listing axis'
											options={LISTING_REGIONS}
											selectedItem={axis}
											onMenuOpen={this._handleMenuOpen.bind(this, 'axis')}
											onChange={this._handleSelectAxis} />
										<span id='error-axis-field' role='alert' className='error'>
											{axisFieldErrorText}
										</span>
									</div>
								</div>

								<div className='padding-16 relative'>
									<label htmlFor='apartment-desc' className='paragraph-xs margin-b8 fw-600'>Description</label>
									<textarea className='apartment-desc br-4 fw-300 color-gray-d'
										aria-required='true'
										value={description}
										placeholder='Earn more with a good description. More words, more earnings'
										onChange={this._handleFieldChange.bind(this, 'description')}
										style={descriptionError ? errorStyle : {}}
										id='apartment-desc' aria-describedby='error-desc-field'>
									</textarea>
									<div className='apartment-desc-count'>
										{getDescriptionLength()}
									</div>
									<span id='error-desc-field' role='alert' className='error'>
										{descriptionFieldErrorText}
									</span>
								</div>

								<div className='apartment-metadata'>
									<div>
										<div className='listing-facilities-check'>
											<input type='checkbox' id='light-check' checked={hasLight}
												onChange={this._handleCheckFacility.bind(this, 'Light')}
											/>
											<label htmlFor='light-check' className='padding-lr8 paragraph-xs fw-400 ta-left'>Is electric power available?</label>
										</div>
									</div>

									<div>
										<div className='listing-facilities-check'>
											<input type='checkbox' id='water-check' checked={hasWater}
												onChange={this._handleCheckFacility.bind(this, 'Water')}
											/>
											<label htmlFor='water-check' className='padding-lr8 paragraph-xs fw-400 ta-left'>Is water supply available?</label>
										</div>
									</div>

									<div>
										<div className='listing-facilities-check'>
											<input type='checkbox' checked={hasAccessRoad} id='road-check'
												onChange={this._handleCheckFacility.bind(this, 'AccessRoad')}
											/>
											<label htmlFor='road-check' className='padding-lr8 paragraph-xs fw-400 ta-left'>Are there good access roads?</label>
										</div>
									</div>
								</div>
							</div>
						</section>
					);
				case 2:
					return (
						<div className='apartment-details'>
							<p className='section-subheader ta-center margin-tb16 fw-600' style={{ marginTop: 24 }}>Earning Details</p>
							<div className='bg-bluegray content-container br-8'>
								<div className='rent-details-child margin-tb16 relative'>
									<label htmlFor='date-showing' className='paragraph-xs fw-600 relative'>
										Date/Time of Showing
											<span className='apartment-showing-root'>
											<Help className='apartment-showing' />
											<div className='apartment-showing-details'>
												When can we come to verify this listing? Please pick a date no earlier than <b>three business days from today</b>
											</div>
										</span>
									</label >
									<div className='br-8' style={dateError ? errorStyle : {}}>
										<Datetime
											dateFormat={'MMM Do YYYY'}
											defaultValue={moment(dateOfShowing).format()}
											onChange={this._handleSelectShowingDate}
											timeConstraints={{
												hours: { min: 9, max: 18 },
												minutes: { step: 30 },
											}}
											input={false}
											isValidDate={(current) => {
												return current.isAfter(moment().add(3, 'day')) && current.day() !== 0;
											}}
										/>
									</div>
									<span id='error-date-field' role='alert' className='error'>
										{dateFieldErrorText}
									</span>
								</div >
								<div className='rent-details-child margin-tb16 relative'>
									<label htmlFor='agent-fee' className='paragraph-xs fw-600'>Agent Fee (%)</label>
									<input className='ha-input' type='number' id='agent-fee'
										style={feeError ? errorStyle : {}}
										min={0}
										max={30}
										value={fee}
										placeholder={'e.g. 10'}
										onChange={this._handleFieldChange.bind(this, 'fee')}
										aria-required='true' />
									<span id='error-fee-field' role='alert' className='error'>
										{feeFieldErrorText}
									</span>
								</div>
								<div className='rent-details-child margin-tb16 relative'>
									<label htmlFor='apartment-rent' className='paragraph-xs margin-b8 fw-600'>Annual Rent (N)</label>
									<input className='ha-input' type='number'
										min='1' id='apartment-rent'
										placeholder={`Enter rent`}
										value={rent}
										onChange={this._handleFieldChange.bind(this, 'rent')}
										style={rentError ? errorStyle : {}}
										aria-required='true' />
									<span id='error-rent-field' role='alert' className='error'>
										{rentFieldErrorText}
									</span>
								</div>
							</div>
						</div>
					)
				default:
					return 'Unknown step';
			}
		}

		const getSnackbarContent = () => {
			const approxListingValue = getListingValue();

			if (activeStep === 0) {
				if (hasJustMinReqImages) {
					if (hasVideoContent) {
						return {
							header: selectedView === 'edit' ? `Current approximate valued of listing is N${approxListingValue}*` : `You earn N${approxListingValue}* on this listing`,
							subheader: 'Add up to 10 images to earn more than N5000* on this listing'
						}
					}
					else {
						return {
							header: `Why earn only N${approxListingValue}*?`,
							subheader: 'Earn more than N5000* by adding up to 10 images and a video'
						}
					}
				}
				else {
					//Added more than minimum number of images
					if (hasVideoContent) {
						return {
							header: `You are killing it!`,
							subheader: ' Continue to add next page to add description of listing'
						}
					}
					else {
						return {
							header: selectedView === 'edit' ? `Current approximate valued of listing is N${approxListingValue}*` : `You earn N${approxListingValue}* on this listing`,
							subheader: 'But you could earn more! Add a video to earn up to N10000*'
						}
					}
				}
			}
			else if (activeStep === 1) {
				if (hasVideoContent) {
					return {
						header: 'You are doing well!',
						subheader: 'Add detailed description to earn up to N10000* on this listing'
					}
				}
				else {
					return {
						header: 'Great progress!',
						subheader: 'Add detailed description to earn up to N10000* on this listing'
					}
				}
			}
			else if (activeStep === 2) {
				if (hasVideoContent) {
					return {
						header: 'You are doing well!',
						subheader: 'Enter rent details, then upload listing to earn up to N10000*'
					}
				}
				else {
					return {
						header: 'You are all set!',
						subheader: 'Enter rent details, then upload listing to earn up to N10000*'
					}
				}
			}
		}

		const getButtonText = () => {
			if (selectedView === 'edit') {
				return windowWidth < 480 ? 'Save' : 'Save Changes'
			}
			else {
				return windowWidth < 480 ? 'Upload' : 'Upload Listing'
			}
		}

		const getBackButton = () => {
			if (selectedView === 'edit' && activeStep === 0) {
				return (
					<button onClick={this.handleDiscardDraft} className={'button draft'}>
						Discard Edit
					</button>
				);
			}
			else {
				return activeStep > 0 &&
					<button disabled={activeStep === 0} onClick={this.handleBack} className={'button draft'}>
						Back
				</button>
			}
		}

		return (
			<div>
				<div>
					<h3 className='page-header'>
						{selectedView === 'edit' ? 'Edit Listing' : (fromSignUp ? 'Create Your First Listing' : 'Add New Listing')}
					</h3>

					<div className='margin-b16 bg-white margin-t48 br-8'>
						{
							role === 'admin' &&
							<div>
								<div className='agent-list-dropdown margin-b16'>
									<label htmlFor='agent-list-drodpdown width-p100' className='section-subheader fw-400'>Select Agent</label>
									<div className='width-p100'>
										<Dropdown
											error={agentFieldErrorText}
											id='agent-list-dropdown'
											placeholder={'Type agent name'}
											isSearchable={true}
											onMenuOpen={this._handleMenuOpen.bind(this, 'agent')}
											onChange={this._handleSelectAgent} />
									</div>
								</div>
								<div id='error-type-field' role='alert' className='error'>
									{agentFieldErrorText}
								</div>
							</div>
						}
						<Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorLine />} style={{ margin: '.5rem' }}>
							{steps.map((label, index) => (
								<Step key={label} onClick={this.selectStep.bind(null, index)}>
									<StepLabel StepIconComponent={StepIcon}>{windowWidth > 660 ? label.desktop : label.mobile}</StepLabel>
								</Step>
							))}
						</Stepper>
						{
							images && Object.keys(images).length > 0 && role !== 'admin' &&
							<div className='margin-16' style={{ margin: '0 auto', maxWidth: '640px' }}>
								<Snackbar
									duration={'indefinite'}>
									<div className='width-p100'>
										<p style={{ fontSize: '.9rem' }} className='ta-center fw-600'>{getSnackbarContent()['header']}</p>
										<p className='ta-center' style={{ fontSize: '0.85rem' }}>{getSnackbarContent()['subheader']}</p>
									</div>
								</Snackbar>
							</div>
						}

						<div className='margin-t8'>
							{getStepContent(activeStep)}
						</div>

						<div className='flex-row-spacearound width-p100 form-btn-root'>
							{getBackButton()}
							{
								activeStep < steps.length - 1
									?
									<button onClick={this.handleNext} className={'button cta'}>
										{`${BUTTON_LABELS[activeStep]}`}
									</button>
									:
									<button className={`button cta ${fromSignUp ? 'btn-cta-main' : 'primary'} `}
										onClick={this._createListing}
										aria-label='Upload Listing'>
										{getButtonText()}
									</button>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}


const mapStateToProps = ({ authState, navBarDisplay, windowWidth }) => {
	return {
		authState,
		navBarDisplay,
		windowWidth
	}
}

const mapDispatchToProps = (dispatch) => {
	return { createListing: (aptObj) => dispatch(createListing(aptObj)) };
};


export default connect(mapStateToProps, mapDispatchToProps)(
	withRouter(UploadForm)
);
