import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  VictoryChart,
  VictoryGroup,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip
} from 'victory'



class LineChart extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeIndex: 0
    };
  }

  _showSignUpModal(data) {
    console.log(data);
  }

  render() {
    const self = this;

    return (
      <VictoryChart height={600} width={800}
        style={{
          data: { fill: "tomato", opacity: 0.7 },
          labels: { fontSize: 12, color: 'white' },
          parent: {/*border: "1px solid #ccc", background: '#354052'*/ }
        }}
        containerComponent={<VictoryVoronoiContainer style={{ width: 'unset' }} />} >
        <VictoryGroup
          color="#8CD018"
          labels={(d) => `${this.props.type}: ${d.y}`}
          labelComponent={
            <VictoryTooltip
              style={{ fontSize: 10 }}
            />
          }
          data={this.props.data}
        >
          <VictoryLine
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 }
            }}
          />
          <VictoryScatter
            style={{ color: 'green' }}
            events={[{
              target: "data",
              eventHandlers: {
                onClick: () => {
                  return [
                    {
                      target: "data",
                      mutation: (props) => {
                        self.props.onSelectLinePoint && self.props.onSelectLinePoint(props.index);
                      }
                    }
                  ];
                }
              }
            }]}
            size={(d, a) => { return a ? 8 : 3; }}
          />
        </VictoryGroup>
      </VictoryChart>
    );
  }
}

LineChart.defaultProps = {
  data: [
    { x: "Feb", y: 124 },
    { x: "Mar", y: 55 },
    { x: "Apr", y: 39 },
    { x: "May", y: 210 },
    { x: "Jun", y: 102 },
    { x: "Jul", y: 82 },
    { x: "Aug", y: 47 }
  ],
  type: 'Views'
};



const mapStateToProps = ({ windowWidth }) => {
  return {
    windowWidth
  }
}

export default connect(mapStateToProps, {})(LineChart);
