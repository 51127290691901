import { SET_WINDOW_WIDTH } from './actions';

const windowWidthReducer = (state = 960, { type, payload }) => {
  switch (type) {
  	case SET_WINDOW_WIDTH:
      return payload;
    default:
      return state;
  }
};

export default windowWidthReducer;