import { all } from 'redux-saga/effects';
import {
	getAuthStateSaga,
	logOutUserSaga,
	setLoggedInUserSaga
} from '../containers/AuthForm/saga';
import {
	addListingSaga,
	deleteListingSaga,
	editListingSaga,
	getListingSaga,
	getListingsSaga,
	verifyListingSaga
} from '../pages/listings/saga';
import {
	addRenterToAppointmentSaga,
	createAppointmentSaga,
	deleteAppointmentSaga,
	getAppointmentsSaga,
	modifyAppointmentSaga,
	getDashboardDataSaga
} from '../pages/home/saga';


export default function* rootSaga() {
	try {
		yield all([
			addListingSaga(),
			addRenterToAppointmentSaga(),
			createAppointmentSaga(),
			getAppointmentsSaga(),
			getDashboardDataSaga(),
			deleteListingSaga(),
			deleteAppointmentSaga(),
			editListingSaga(),
			getListingsSaga(),
			getListingSaga(),
			getAuthStateSaga(),
			logOutUserSaga(),
			modifyAppointmentSaga(),
			setLoggedInUserSaga(),
			//updateSearchPrefSaga()
			verifyListingSaga()
		]);
	}
	catch (e) {
		console.log(e);
	}
}
