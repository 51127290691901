import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import EmailValidator from 'email-validator';
import { parsePhoneNumber } from 'libphonenumber-js';
import TextField from '@material-ui/core/TextField';
import CheckCircle from '@material-ui/icons/CheckCircle';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import Loader from '../Loader';
import Dial from '../DialPad/index';
import { FIRESTORE_DB } from '../../App';
import { parseAuthErrors } from '../../Utils';


const styles = () => ({
	root: {
		color: '#354052',
	},
	underlineLogin: {
		'&:hover:not($disabled):not($focused):not($error):before': {
			borderBottom: '1px solid #923B96 !important',
		},
		'&:after': {
			borderBottom: '2px solid #923B96',
		},
	},
	underlineSignup: {
		'&:hover:not($disabled):not($focused):not($error):before': {
			borderBottom: '1px solid #F7BF23 !important',
		},
		'&:after': {
			borderBottom: '2px solid #F7BF23',
		},
	},
	disabled: {},
	focused: {},
	error: {
		color: 'red'
	},
});


class WaitlistForm extends Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.version && nextProps.version !== prevState.version) {
			return {
				version: nextProps.version
			}
		}
		return {};
	}

	constructor(props, context) {
		super(props, context);
		this.state = {
			btnDisabled: false,
			checked: false,
			loading: false,
			emailPhoneFieldText: '',
			emailPhoneFieldError: false,
			emailPhoneFieldErrorText: '',
			isNewUser: true,
			nameFieldText: '',
			nameFieldError: false,
			nameFieldErrorText: '',
			signupSuccess: false,
			signupFailure: false,
			version: props.version || localStorage.getItem('ha__waitlist_bkt')
		};
		this._signupUser = this._signupUser.bind(this);
		this._handleOnChangePhoneVerify = this._handleOnChangePhoneVerify.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
	}

	_handleCheckAgent() {
		this.setState({ checked: !this.state.checked })
	}

	_handleFieldChange(field, e) {
		let error = `${field}FieldError`;
		let errorLabel = `${error}Text`;
		let fieldText = `${field}FieldText`;

		if (this.state[error]) {
			this.setState({
				[error]: false,
				[errorLabel]: ''
			});
		}
		this.setState({
			[fieldText]: e.target.value
		});
	}

	_handleWaitlistClick() {
		if (this.state.phoneVerify) {
			this._handlePhoneVerifyClick();
		}
		else {
			this._validateUserInput(this._signupUser);
		}
	}

	_handleOnChangePhoneVerify(value) {
		this.phoneVerifyCode = value;
	}

	_handlePhoneCodeVerify() {
		this.setState({
			btnDisabled: false,
			loading: false,
			phoneVerify: true
		});
	}

	_handlePhoneVerifyClick() {
		if (this.phoneVerifyCode) {
			this.setState({
				btnDisabled: true,
				loading: true
			})
			this._verifyPhoneCode(this.phoneVerifyCode);
		}
		else {
			this.setState({
				signupFailure: true,
				failureMessage: { header: `Oh dear, that email doesn't exist.`, body: 'Please enter the code sent to your phone' }
			});
		}
	}

	_saveUserToDB(userObject, isNewUser) {
		FIRESTORE_DB.collection("users").doc(userObject.uid).set(
			userObject
		).then((docRef) => {
			console.log('Document written with ref ', docRef);
			this.setState({
				signupSuccess: true,
				isNewUser,
				uid: userObject.uid
			}, () => {
				window.scrollTo(0, 0);
			});
		}).catch((error) => {
			console.error("Error adding document: ", error);
		});
	}

	_showErrorState(errorObject) {
		this.setState({
			btnDisabled: false,
			loading: false,
			signupFailure: true,
			failureMessage: errorObject
		});
	}

	_signupUser() {
		if (this.state.authProvider === 'EMAIL') {
			this._verifyEmailAndSignUpUser(this.state.emailPhoneFieldText);
		}
		else if (this.state.authProvider === 'PHONE') {
			this._signupUserWithPhone();
		}
	}

	_signupUserWithEmail() {
		const { nameFieldText, emailPhoneFieldText, checked, version } = this.state;
		firebase.auth().createUserWithEmailAndPassword(emailPhoneFieldText, `${new Date()}`)
			.then((result) => {
				const uid = result.user.uid;
				const emailOrPhone = result.user.providerData[0].email;
				const isNewUser = result.additionalUserInfo.isNewUser;
				console.log(nameFieldText, emailPhoneFieldText, checked, version);
				if (isNewUser) {
					this._saveUserToDB({
						name: nameFieldText,
						authProvider: 'EMAIL',
						emailOrPhone,
						created: new Date(),
						isAgent: checked,
						uid,
						...(version && { bucket: version })
					}, isNewUser);
				}
				else {
					this.setState({
						signupSuccess: true,
					});
				}
			})
			.catch((error) => {
				const { code, message } = error;
				console.log('Error signing user up', message);
				this._showErrorState(parseAuthErrors(code));
			});
	}

	_signupUserWithPhone() {
		let appVerifier = window.recaptchaVerifier;
		const { emailPhoneFieldText } = this.state;
		const phoneNumber = `+234${emailPhoneFieldText.substring(1)}`;

		firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
			.then((confirmationResult) => {
				window.confirmationResult = confirmationResult;
				this._handlePhoneCodeVerify();
			}).catch((error) => {
				this._showErrorState(parseAuthErrors(error.code));
				console.log(`Some error,`, error)
			});
	}

	_validateUserInput(authUserCB) {
		let isValidEmail = EmailValidator.validate(this.state.emailPhoneFieldText);

		if (this.state.nameFieldText.length === 0) {
			this.setState({
				nameFieldError: true,
				nameFieldErrorText: 'Anonymous? C\'mon, we know you have a fine name',
			});
		}
		else if (this.state.emailPhoneFieldText.length === 0) {
			this.setState({
				emailPhoneFieldError: true,
				emailPhoneFieldErrorText: 'Please enter an email or phone number',
			});
		}
		else if (!isValidEmail) {
			//Check if it's a valid phone number
			try {
				let isValidPhone = parsePhoneNumber(this.state.emailPhoneFieldText, 'NG').isValid();
				console.log('Phone valid', isValidPhone);

				if (!isValidPhone) {
					this.setState({
						emailPhoneFieldError: true,
						emailPhoneFieldErrorText: `Err.. that's not a valid email or phone number`,
					});
				}
				else {
					//Valid phone number
					this.setState({ authProvider: 'PHONE' });
					this._clearAllFormFieldErrors(authUserCB);
				}
			}
			catch (e) {
				console.log('Error parsing', e.toString());
				this.setState({
					emailPhoneFieldError: true,
					emailPhoneFieldErrorText: `Err.. that's not a valid email or phone number`,
				})
			}
		}
		else {
			//Valid email
			this.setState({ authProvider: 'EMAIL' });
			this._clearAllFormFieldErrors(authUserCB);
		}
	}

	_verifyEmailAndSignUpUser(email) {
		if (window._nb) {
			window._nb.api.getValidatePublic(email,
				(payload) => {
					console.log(payload, payload.response.result);
					if (payload.response && payload.response.result === 'invalid') {
						this._showErrorState({ header: `Oh dear, that email doesn't exist.`, body: 'Please check again' });
					}
					else {
						this._signupUserWithEmail();
					}
				},
				(err) => {
					if (err) {
						this._showErrorState({ header: `Oh dear, we can't verify that email exists.`, body: 'Please try again' });
					}
					console.log(err);
				}
			);
		}
		else {
			this._signupUserWithEmail();
		}
	}

	_verifyPhoneCode(code) {
		if (window.confirmationResult) {
			const confirmationResult = window.confirmationResult;
			const { nameFieldText, emailPhoneFieldText, checked, version } = this.state;

			confirmationResult.confirm(code).then((result) => {
				const uid = result.user.uid;
				const emailOrPhone = result.user.providerData[0].phone;
				const isNewUser = result.additionalUserInfo.isNewUser;
				if (isNewUser) {
					this._saveUserToDB({
						name: nameFieldText,
						authProvider: 'PHONE',
						emailOrPhone: emailOrPhone || `+234${emailPhoneFieldText.substring(1)}`,
						created: new Date(),
						isAgent: checked,
						uid,
						...(version && { bucket: version })
					}, isNewUser);
				}
				else {
					this.setState({
						signupSuccess: true,
						isNewUser: false,
						uid
					}, () => {
						window.scrollTo(0, 0);
					});
				}
			}).catch((error) => {
				console.log(error);
				this.setState({
					failureMessage: { header: `Trust me, we tried`, body: `But we cannot verify the code you provided` }
				});
			});
		}
	}

	_clearAllFormFieldErrors(authUserCB) {
		this.setState({
			btnDisabled: true,
			loading: true,
			nameFieldError: false,
			nameFieldErrorText: '',
			emailPhoneFieldError: false,
			emailPhoneFieldErrorText: '',
			signupFailure: false
		}, () => {
			authUserCB();
		});
	}

	_resetForm() {
		this.setState({
			btnDisabled: true,
			loading: true,
			nameFieldError: false,
			nameFieldErrorText: '',
			emailPhoneFieldError: false,
			emailPhoneFieldErrorText: '',
			signupFailure: false
		});
	}

	render() {
		const { classes } = this.props;
		const buttonStyle = {
			height: 48,
			marginTop: 24,
			fontWeight: 500
		}
		const { uid } = this.state;

		const renderSuccessStatus = () => {
			return (
				<div className='waitlist-form-root bg-white flex-column-center' style={{ backgroundPosition: '40% 70%' }}>
					<CheckCircle style={{ fontSize: '5rem', fill: '#05CD75' }} />
					<h2 className='section-header'>
						{this.state.isNewUser ? 'Signup Success!' : `You're already signed up!`}
					</h2>
					{
						this.state.isNewUser
							?
							<div className='success-message'>
								<p style={{ marginBottom: 24 }}>
									Welcome onboard and thank you for being one of the first users of this service.
								</p>
								<p style={{ marginBottom: 24 }}>
									If you signed up using the email option, you should receive an email confirmation
									of your early access and its benefits.
								</p>
								<p style={{ marginBottom: 24 }}>
									<b>Now to some goodies!</b> We are running a survey where <u>stand a chance to win N1000</u> just by completing the survey.
									Please click <b><Link to={`/post-signup/${uid}`} target="_blank" rel="noopener noreferrer">here</Link></b> to complete the survey.
									The link is also in th email confirmation we sent to you.
								</p>
								<p style={{ marginBottom: 24 }}>
									Got questions? Check out our <Link to={`/#faqs`} target="_blank" rel="noopener noreferrer">FAQs</Link>, send us
									an email at <a href="mailto:faqs@homeattlas.com">faqs@homeattlas.com</a> or reach us at
									&nbsp;<b>@homeattlas</b> on our social media accounts.
								</p>
								<p style={{ marginBottom: 40 }}>Thank you once again for being one of the first users of this service.</p>
							</div>
							:
							<div className='success-message'>
								<p style={{ marginBottom: 24 }}>You are already part of 105 other agents to become one of the first users of this service.</p>
								<p style={{ marginBottom: 24 }}>
									In case you missed this, we are currently running a survey where you <u>stand the chance to win N1000.</u>
									&nbsp;If you want to be a part of it, click on this <b><Link to={`/post-signup/${uid}`} target="_blank" rel="noopener noreferrer">link</Link></b>
									&nbsp; to complete the survey.
								</p>
								<p style={{ marginBottom: 24 }}>
									Got questions? Check out our <Link to={`/#faqs`} target="_blank" rel="noopener noreferrer">FAQs</Link>, send us
									an email at <a href="mailto:faqs@homeattlas.com">faqs@homeattlas.com</a> or reach us at
									&nbsp;<b>@homeattlas</b> on our social media accounts.
								</p>
								<p style={{ marginBottom: 32 }}>Thank you once again for being one of the first users of this service.</p>
							</div>
					}

					<Link to={{ pathname: '/' }}>
						<button type='button' className={`cta-main-btn btn-l waitlist-btn`}
							style={{ background: '#cfecfd', color: '#354052', height: 48, marginBottom: 8 }}>
							Return Home
						</button>
					</Link>
				</div>
			)
		}

		const renderWaitlistForm = () => {
			return (
				<form className='waitlist-form-root bg-white flex-column-center' style={{ backgroundPosition: '40% 70%' }}>
					<h2 className='form-header'>
						{
							this.state.phoneVerify
								? 'One last step, we promise'
								: `You've made a great choice`
						}

					</h2>
					{
						this.state.signupFailure
							?
							<p className='error' style={{ marginBottom: 24, fontSize: '1rem', marginTop: 16 }}>
								<b>{this.state.failureMessage.header || 'Oops! An error occurred.'} </b>
								<br />{this.state.failureMessage.body || `It's not you, it's us, please try signing up again`}
							</p>
							:
							this.state.phoneVerify
								?
								<p style={{ marginBottom: 32, marginTop: -8 }}>Please enter the verification code sent to your phone</p>
								:
								<p style={{ marginBottom: 32, marginTop: -8 }}>Please fill the form to get started</p>
					}

					{
						this.state.phoneVerify
							?
							<div style={{ display: 'flex' }}>
								<Dial
									onPadPress={this._handleOnChangePhoneVerify}
									type={'signup'}
									error={this.state.sig}
								/>
							</div>
							:
							<div className='width-p100'>
								<TextField
									id='your-name'
									label='Name'
									type='text'
									placeholder='We know you got a fine name'
									style={{ marginBottom: 24 }}
									error={this.state.nameFieldError}
									value={this.state.nameFieldText}
									aria-describedby='error-email-field'
									onChange={this._handleFieldChange.bind(this, 'name')}
									fullWidth
									required
									InputProps={{
										classes: {
											root: classes.root,
											input: classes.underlineSignup,
											underline: classes.underlineSignup,
										},
									}}
								/>
								<span id='error-name-field' role='alert' className='error'>{this.state.nameFieldErrorText}</span>

								<TextField
									id='your-uniqueness'
									label='Email Address or Phone Number'
									type='text'
									placeholder='Email address or Phone number'
									style={{ marginBottom: 12 }}
									error={this.state.emailPhoneFieldError}
									value={this.state.emailPhoneFieldText}
									aria-describedby='error-email-field'
									onChange={this._handleFieldChange.bind(this, 'emailPhone')}
									fullWidth
									required
									InputProps={{
										classes: {
											root: classes.root,
											input: classes.underlineSignup,
											error: classes.error,
											underline: classes.underlineSignup,
										},
									}}
								/>
								<span id='error-email-field' role='alert' className='error'>{this.state.emailPhoneFieldErrorText}</span>

								<div className='flex-row' style={{ marginTop: 16 }}>
									<input type='checkbox' checked={this.state.checked}
										onChange={this._handleCheckAgent.bind(this)} className='agent-check'></input>
									<p style={{ fontSize: '1rem' }}>&nbsp;Select this if you are an agent</p>
								</div>
							</div>
					}

					<div className='recaptcha' id='recaptcha-container' style={this.state.phoneVerify ? { display: 'none' } : {}}>
					</div>

					<div className='flex-center'>
						<button type='button' className={`cta-main-btn btn-l waitlist-btn`} id='waitlist-btn'
							style={this.state.loading ? { ...{ background: '#e8f5d0' }, ...buttonStyle } : buttonStyle}
							onClick={this._handleWaitlistClick.bind(this)}
							disabled={this.state.btnDisabled}>
							{
								this.state.loading
									?
									<Loader />
									:
									<span>
										{
											this.state.phoneVerify ? 'Complete signup' : 'Get Early Access'
										}
									</span>
							}
						</button>
					</div>

					{<div className='flex-column-center form-container' style={{ marginTop: 12 }}>
						<p className='footnote'>
							By signing up, you are agreeing to our Terms of Service
					</p>
					</div>
					}

				</form>
			)
		}

		const renderContent = () => {
			if (this.state.signupSuccess) {
				return (
					renderSuccessStatus()
				);
			}
			else {
				return renderWaitlistForm();
			}
		}

		return (
			renderContent()
		)
	}

}


export default withStyles(styles)(WaitlistForm);