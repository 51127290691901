import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HamburgerMenuList from '../HamburgerMenuList/index';
import Avatar from '../../assets/imgs/agent_avatar.png';
import './styles.scss';

const UserMenu = ({ handleClick, notificationCount, showMenu, userType }) => {
	const items = userType === 'renter' ?
		[
			{
				label: 'Messages',
				notificationCount: 5
			},
			{
				label: 'Logout',
				itemId: 'logout'
			}
		]
		:
		[
			{
				label: 'My Earnings',
				itemId: 'earnings',
				notificationCount: 1
			},
			{
				label: 'My Listings ',
				itemId: 'listings',
				notificationCount: 17
			},
			{
				label: 'My Appointments ',
				itemId: 'appointments',
				notificationCount: 2
			},
			{
				label: 'Messages',
				itemId: 'messages',
				notificationCount: 5
			},
			{
				label: 'Settings',
				itemId: 'settings',
			},
			{
				label: 'Logout',
				itemId: 'logout'
			},
		]

	return (
		<div className={`btn relative`} role='button' tabIndex={0}
			onClick={handleClick}>
			<div className='relative'>
				<div>
					<img src={Avatar} className={`user-avatar ${showMenu ? 'color-border' : ''}`}
						alt={'user-profile-img'} />
				</div>
				<div className={`user-menu-list ${showMenu ? 'show' : ''}`}>
					<HamburgerMenuList items={items} showMenu={showMenu} />
				</div>
			</div>
			{
				notificationCount > 0 &&
				<div className={'notification'}>
					{notificationCount}
				</div>
			}
		</div >
	)
};

const mapStateToProps = ({ windowWidth }) => {
	return {
		windowWidth
	}
}

export default connect(mapStateToProps, {})(
	withRouter(UserMenu)
);

