import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import QueryString from 'query-string';

import AuthForm from '../../containers/AuthForm/';
import { toggleNavBar } from '../../containers/NavBar/actions';



class SignupPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    this.props.toggleNavBar(false);
  }

  render() {
    const { location } = this.props;
    let headerText;
    if (location.state)
      headerText = location.state && location.state.headerText;

    let userType = QueryString.parse(location.search)['user_type'];
    const isSaveListing = QueryString.parse(location.search)['save_listing'];

    if (!headerText && isSaveListing) {
      headerText = 'Please create an account to save these listings';
    }

    if (!userType) userType = 'renter';
    return (
      <div>
        <Helmet title='Signup for Free | Home Attlas' />
        <AuthForm type='signup'
          headerText={headerText}
          userType={userType}
          embed={false} />
      </div>
    );
  }
}


const mapStateToProps = store => {
  return {
    navBarDisplay: store.navBarDisplay
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavBar: (status) => dispatch(toggleNavBar(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(SignupPage));
