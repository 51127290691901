import { TOGGLE_NAVBAR } from './actions';


const navBarDisplayReducer = (state = true, { type, payload }) => {
  switch (type) {
    case TOGGLE_NAVBAR:
      return payload;
    default:
      return state;
  }
};

export default navBarDisplayReducer;